import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
// import "./auth.scss";
import { useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "../../redux-toolkit/services/authService";
import { useDispatch } from "react-redux";
import { setAuthState } from "../../redux-toolkit/slices/authSlice";
import { alertMessage } from "../../utils/AlertService";
// import "./style.css";
import "../../assets/icons/mission.png";
import "../../assets/icons/vision.png";
import "../../assets/logo-blue.png";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useGetAllUserRoleMutation } from "../../redux-toolkit/services/userRoleService";
import { getAllUserRoleDetails, setAllUserRole } from "../../redux-toolkit/slices/allUserRoleSlice";
import { useAppSelector } from "../../redux-toolkit/hooks";
import * as Yup from "yup";
import { Typography } from "antd";
import SignUp from "./SignUp";
import ForgotResetPassword from "./ForgotResetPassword";
import { PAGE_TITLE } from "../../constant/AppConstant";
import TestYourProject from "./TestYourProject";
import { useSaveAuditLogMutation } from "../../redux-toolkit/services/auditLogService";
import Loading from "../../component/Loading";

type Props = {};
const { Link } = Typography;

const Login = () => {
  //validation
  const LoginSchema = Yup.object().shape({
    // userrole: Yup.string().required("Please select Role!"),
    username: Yup.string().required("Please enter Username!"),
    password: Yup.string().required("Please enter Password!"),

  });

  //Mutation
  const [loginUser, loginResponse] = useLoginUserMutation();
  const [getAllUserRole, allUserRoleResponse] = useGetAllUserRoleMutation();
  const [saveAuditLog, saveAuditLogResponse] = useSaveAuditLogMutation();

  //selector
  const allUserRole: any = useAppSelector(getAllUserRoleDetails);

  const data: any = localStorage.getItem("eventData");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [forgotPasswordShow, setForgotPasswordShow] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);

  const handleShow = () => setShow(true);
  const handleHomeClick = () => {
    localStorage.setItem("routeTo", "home");
    navigate("/home")
  };

  const handleAbout = () => {
    navigate("/about");
  }
  
  const handleForgotPasswordShow = () => setForgotPasswordShow(true);

  const handleLoginSubmit = async (values: any) => {
    const payload = {
      password: "user@spotfinder",
      userRole: "Admin",
      username: "admin@capital.ai",
    };
    await loginUser({
      username: values.username,
      password: values.password,
      userRole: values.userrole,
    });
  };

  //get all userrole
  // useEffect(() => {
  //   getAllUserRole({});
  // }, []);

  useEffect(() => {
    if (allUserRoleResponse.isSuccess) {
      dispatch(setAllUserRole(allUserRoleResponse));

    } else if (allUserRoleResponse.isError) {
      let response: any = allUserRoleResponse?.error;
      alertMessage("Error while fetching User role", "error");
    }
  }, [allUserRoleResponse.isSuccess, allUserRoleResponse.isError]);


  useEffect(() => {
    if (loginResponse.isSuccess) {
      alertMessage("Login successful", "success");
      dispatch(setAuthState(loginResponse.data));
      const localUserObj = {
        token: loginResponse.data.jwtToken,
        userType: loginResponse.data.userRoleName,
        id: loginResponse.data.id,
        username: loginResponse.data.username,
        userRoleStatus: loginResponse.data.userRoleStatus,
        permissions: loginResponse.data.permissions,
      };

      localStorage.setItem("localUser", JSON.stringify(localUserObj));
      localStorage.setItem("name", JSON.stringify(loginResponse.data.username));
      const startTime = new Date();
      saveAuditLog({
        payload: {
          startTime: startTime.toISOString(),
          // endTime: startTime.toISOString(),
          // request: {},
          // response: {},
          serviceName: "login",
          eventName: "login",
          url: "/login",
          userId: loginResponse?.data?.id
        }
      })
    }
  }, [loginResponse.isSuccess]);

  useEffect(() => {
    if (loginResponse.isError) {
      alertMessage("Invalid email or password", "error");
      navigate("/login", { replace: true });
    }
  }, [loginResponse.isError]);

  useEffect(() => {
    if (saveAuditLogResponse.isSuccess) {
      // console.log(saveAuditLogResponse, 'saveAuditLogResponse')
      // dispatch(setAuditLog(saveAuditLogResponse))

    } else if (saveAuditLogResponse.isError) {
      console.log("error while saving data");
      // alertMessage("error while saving data", "error");
    }
  }, [saveAuditLogResponse.isSuccess, saveAuditLogResponse.isError]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isShown, setIsSHown] = useState(false);

  const togglePassword = () => {
    setIsSHown((isShown) => !isShown);
  };

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      {/* <nav className="navbar">
        <div className="navbar-container container">
          <input type="checkbox" name="" id=""/>
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
            <ul className="menu-items">
              <li><a href="#">Home</a></li>
              <li><a href="#">About</a></li>
              <li><a href="#">Category</a></li>
            </ul>
            <img src={require("../../assets/logo-blue.png")} width="30" height="24" />

        </div>
      </nav> */}
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
          <div className="container-fluid">
            <a className="navbar-brand" onClick={()=> navigate("/home")}>
              <img
                src={require("../../assets/logo-blue.png")}
                alt="Bootstrap"
                width="100"
                height="40"
              />
            </a>

            <button
              type="button"
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto">
                <a
                  // href="#"
                  className="nav-item nav-link menu-font text-secondadry-darker px-4 cursor-pointer"
                  onClick={handleAbout}
                >
                  About us
                </a>
                {/* <a
                  href="#"
                  className="nav-item nav-link menu-font text-secondadry-darker px-4"
                >
                  Value Proposition
                </a> */}
                <li className="nav-item dropdown  text-secondadry-darker px-4">
                  <a className="nav-link dropdown-toggle menu-font" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ReguVista
                  </a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item cursor-pointer" onClick={handleHomeClick}>Home</a></li>
                    <li><a className="dropdown-item cursor-pointer">Login</a></li>
                    <li><a className="dropdown-item cursor-pointer" onClick={handleShow}>Sign Up</a></li>
                  </ul>
                </li>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className="container">
        <div className="row pb-5 mt-4 align-items-center">
          {/* <div className="oveflow-x-hidden d-flex flex-column align-items-center justify-content-center "> */}
          {/* mainDiv  */}
          {/* <div className="overflow-hidden"> */}
          <div className="flex-lg-row flex-column-reverse main align-items-center d-flex w-100">
            <div className="bg-cm-light col-lg-8 pr-lg-5 mb-5 mb-md-0">
              {/* <div className="about">
                <h1 className="text-cm-primary1">
                  KEY PLATFORM FEATURES
                </h1>

              </div> */}

              <div className="vision-mission bg-color-#E7F0FF-30 py-2 px-4">
                <h1 className="text-cm-primary1 text-center">
                  KEY PLATFORM FEATURES
                </h1>
                <div className="d-flex align-items-start">
                  <div className="vision-mission-c w-50 d-flex flex-column justify-content-center align-items-start text-center">
                    <div className="d-flex justify-content-center w-100">
                      <img
                        src={require("../../assets/capitolAsset/legInsight.png")}
                        width={90}
                        className="text-cm-primary1"
                      />
                    </div>
                    <div className="content p-3">
                      <h4 className="text-cm-dark-grey" style={{ fontWeight: 600 }}>LEGISLATIVE INSIGHTS</h4>
                      <p className="fs-6  text-cm-dark-blue">
                        Analytical insights and comparison of the
                        latest AI regulations and legislation initiatives worldwide.
                      </p>
                      <p className="fs-6  text-cm-dark-blue">
                        ReguVista provides real-time analysis of
                        legislative changes and compliance requirements to help organizations adapt quickly.
                      </p>
                    </div>
                  </div>
                  <div className="vision-mission-c w-50 bg-color-#E7F0FF-30 d-flex flex-column justify-content-center align-items-start text-center">
                    <div className="d-flex justify-content-center w-100">
                      <img src={require("../../assets/capitolAsset/policyContract.png")} width={90} />
                    </div>
                    <div className="content p-3">
                      <h4 className="text-cm-dark-grey" style={{ fontWeight: 600 }}>POLICY + CONTRACT ANALYSIS</h4>
                      <p className="fs-6 text-cm-dark-blue">
                        Quickly evaluate compliance risks of legal contracts and policies.
                      </p>
                      <p className="fs-6 text-cm-dark-blue">
                        Upload policy and legal documents into
                        ReguVista to quickly analyze and assess
                        how well applications adhere to current and pending regulations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start">
                  <div className="vision-mission-c w-50 d-flex flex-column justify-content-center align-items-start text-center">
                    <div className="d-flex justify-content-center w-100">
                      <img
                        src={require("../../assets/capitolAsset/dataSetComp.png")}
                        width={90}
                        className="text-cm-primary1"
                      />
                    </div>
                    <div className="content p-3">
                      <h4 className="text-cm-dark-grey" style={{ fontWeight: 600 }}>DATASET COMPLIANCE</h4>
                      <p className="fs-6  text-cm-dark-blue">
                        Comprehensive compliance analysis of bias and fairness.
                      </p>
                      <p className="fs-6  text-cm-dark-blue">
                        Upload dataset files into ReguVista
                        to quickly identify, analyze,
                        and understand data bias and sensitive data elements.
                      </p>
                    </div>
                  </div>
                  <div className="vision-mission-c w-50 bg-color-#E7F0FF-30 d-flex flex-column justify-content-center align-items-start text-center">
                    <div className="d-flex justify-content-center w-100">
                      <img src={require("../../assets/capitolAsset/aiModelCode.png")} width={90} />
                    </div>
                    <div className="content p-3">
                      <h4 className="text-cm-dark-grey" style={{ fontWeight: 600 }}>AI MODEL + CODE ANALYSIS</h4>
                      <p className="fs-6 text-cm-dark-blue">
                        Compliance Analysis of bias in software products and applications.
                      </p>
                      <p className="fs-6 text-cm-dark-blue">
                        Upload AI models and code files to identify
                        and understand cyber security vulnerabilities,
                        bias, and  compliance of hardcoded data elements .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="login  col-lg-4  ml-auto">
              <Formik
                initialValues={{
                  userrole: "",
                  username: "",
                  password: "",
                }}
                validationSchema={LoginSchema}
                onSubmit={handleLoginSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* <h3
                      className="text-align-center text-cm-primary1 fw-bold"
                      style={{ marginBottom: "100px" }}
                      onClick={() => {
                        setShowDataModal(true);
                      }}
                    >
                      <a href="#">
                        Test your project for Data Privacy, Data and Algorithm
                        Bias
                      </a>
                    </h3> */}
                    <div className="d-flex flex-column rounded-3 overflow-hidden p-md-4 p-3">
                      <h2 className="text-align-center text-cm-primary1 fw-bolder fs-1">Login</h2>

                      {/* <div className="mb-3">
                        <select
                          className={`form-select border-0 bg-cm-light-primary1 ${errors.userrole ? 'is-invalid' : ''}`}
                          aria-label="selectColumn"
                          onChange={handleChange}
                          placeholder="Subscriber"
                          name="userrole"
                          value={values.userrole}
                        >
                          <option >Select Subscriber</option>
                          {allUserRole && allUserRole.map((role: any, index: any) => {
                            return (
                              <option key={index} value={role.id}>
                                {role.name}
                              </option>
                            );
                          })}
                        </select>
                      </div> */}

                      <div className="mb-3">
                        <div className="text-danger">*</div>
                        <input
                          type="text"
                          value={values.username}
                          name="username"
                          onChange={handleChange}
                          className={`form-control border-0 bg-cm-light-primary1 ${errors.username ? 'is-invalid' : ''}`}
                          id="username"
                          aria-describedby="username"
                          placeholder="Username"
                        />
                        <div className="invalid-feedback">{errors.username}</div>

                      </div>
                      <div className="d-flex flex-column mb-3">
                        <p className="text-end m-1">
                          <Link onClick={handleForgotPasswordShow}>
                            Forgot password?
                          </Link>
                        </p>
                        <div className="text-danger">*</div>
                        <div className="input-group">
                          {/* <input type={isShown ? "text" : "password"} name="password" value={values.password} id="password" className="form-control bg-secondary-light border-secondary-light" placeholder="password" aria-label="password" /> */}
                          <input
                            type={isShown ? "text" : "password"}
                            value={values.password}
                            name="password"
                            onChange={handleChange}
                            className={`form-control bg-cm-light-primary1 border-0 ${errors.password ? 'is-invalid' : ''}`}
                            placeholder="Password"
                          />
                          <span className="input-group-text bg-cm-light-primary1 border-0">
                            {" "}
                            {isShown ? (
                              <BsEyeSlash
                                className="bg-cm-light-primary1 border-0"
                                onClick={togglePassword}
                              />
                            ) : (
                              <BsEye
                                className="bg-cm-light-primary1 border-0"
                                onClick={togglePassword}
                              />
                            )}
                          </span>
                          <div className="invalid-feedback">{errors.password}</div>

                        </div>
                      </div>

                      <button type="submit" className="login-but btn-primary">
                        Login
                      </button>
                      <p className="mt-3 text-center">
                        Don't have an account?{" "}
                        <Link onClick={handleShow}>
                          Click here to Sign Up
                        </Link>
                      </p>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="bg-cm-light-grey d-flex">
            <p className="fs-2 fw-bold text-secondadry-darker">ReguVista</p>
            <p className="ps-4 pt-2">
              Our cutting-edge software platform where the power of artificial intelligence
              converges with legislative excellence. ReguVista connects subscribers to easy-to-
              understand regulatory information to drive transformative change in global
              regulatory insights and compliance solutions, empowering organizations and
              individuals with real-time, data-driven insights.
            </p>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
      <footer className="text-align-center ">
        <p className="text-cm-dark fs-6">
          Capitol Tunnels.ai &#169; 2023
        </p>
      </footer>
      {show && (
        <SignUp
          show={show}
          setShow={setShow}
        />
      )}
      {forgotPasswordShow && (
        <ForgotResetPassword
          forgotResetShow={forgotPasswordShow}
          setForgotResetShow={setForgotPasswordShow}
          title={PAGE_TITLE.FORGOT_PASSWORD}
        />
      )}
      {showDataModal && (
        <TestYourProject
          showDataModal={showDataModal}
          setShowDataModal={setShowDataModal}
        />
      )}
      {loginResponse.isLoading ? (
        <Loading />
      ) : (
        ""
      )}
    </>
  );
};
export default Login;
