import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface ResponsibilityState {
  data: [] | null | any;
  centerData: [] | null | any;
}
const initialState: ResponsibilityState = {
  data: null,
  centerData: null,
};
export const responsibilitySlice = createSlice({
  name: 'responsibility',
  initialState,
  reducers: {
    setResponsibilityGroup: (state, action) => {
      state.data = action.payload.data;
    },
    setResponsibilityCenter: (state, action) => {
      state.centerData = action.payload.data;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setResponsibilityGroup, setResponsibilityCenter, reset } = responsibilitySlice.actions;
export const getAllResponsibilityGroupDetails = (state: RootState) => state.responsibility.data;
export const getAllResponsibilityCenterDetails = (state: RootState) => state.responsibility.centerData;
export default responsibilitySlice.reducer;