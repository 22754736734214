import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface FastApiState {
  dataSetBiasTest: [] | null | any;
  fairnessMetrics: [] | null | any;
  employmentBias: [] | null | any;
  regulationPrediction:  null | any;
}
const initialState: FastApiState = {
  dataSetBiasTest: null,
  fairnessMetrics: null,
  employmentBias: null,
  regulationPrediction: null
};
export const   fastApiSlice = createSlice({
  name: 'fastApi',
  initialState,
  reducers: {
    setDataSetBiasTest: (state, action) => {
      state.dataSetBiasTest = action.payload.data;
      // state.fileName = action.payload.data.data[0][0].fileName;
    },
    setFairnessMetrics: (state, action) => {
      state.fairnessMetrics = action.payload.data;
      // state.fileName = action.payload.data.data[0][0].fileName;
    },
    setEmploymentBias: (state, action) => {
      state.employmentBias = action.payload.data;
      // state.fileName = action.payload.data.data[0][0].fileName;
    },
    setRegulationPrediction: (state, action) => {
      state.regulationPrediction = action.payload;
      // state.fileName = action.payload.data.data[0][0].fileName;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setDataSetBiasTest, setFairnessMetrics, setEmploymentBias, setRegulationPrediction,reset } = fastApiSlice.actions;
export const getDataSetBiasTestDetails = (state: RootState) => state.fastApi.dataSetBiasTest;
export const getFairnessMetricsDetails = (state: RootState) => state.fastApi.fairnessMetrics;
export const getEmploymentBiasDetails = (state: RootState) => state.fastApi.employmentBias;
export const getRegulationPredictionDetails = (state: RootState) => state.fastApi.regulationPrediction;
export default fastApiSlice.reducer;