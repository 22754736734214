import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Select, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import {
  ACTION_TYPE, REPORT_NAME,
} from "../constant/reportType";
import { DATA_PRIVACY_PENALTY_PREDICTION_COLUMN, DATA_SET_BIAS_TEST_COLUMN, EMPLOYMENT_BIAS_COLUMN, FAIRNESS_METRICS_FOR_MACHINE_LEARNING_COLUMN, PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_COLUMN, PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_DATSOURCE, SENSITIVE_BIAS_DATA_IDENTIFICATION_COLUMN } from "../constant/AppConstant";
import {
  getAllStatesTotalRecords,
} from "../redux-toolkit/slices/statesSlice";
import { getLocalStorageData } from "../utils/LocalStorageService";
import { getDataSetBiasTestDetails, getFairnessMetricsDetails, getRegulationPredictionDetails } from "../redux-toolkit/slices/fastApiSlice";
import merge from "lodash/merge";
import PredictionModelsData from "../pages/reports/PredictionModelsData";
import { getDataPrivacyPenaltyPredictionDetails } from "../redux-toolkit/slices/gdprSlice";
import { Excel } from "antd-table-saveas-excel";

const { Option } = Select;
type Props = {};

const PredictionModels = (props: Props) => {
  const params = useParams();
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // States
  const [show, setShow] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [reportName, setReportName]: any = useState();
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [fileName, setFileName]: any = useState();
  const [imageUrl, setImageUrl]: any = useState();


  // datasource
  const [dataPrivacyPenaltyPredDatasource, setDataPrivacyPenaltyPredDatasource]: any = useState([]);
  const [regulatiobPredictionDatasource, setRegulatiobPredictionDatasource]: any = useState([]);

  //columns
  const [dataPrivacyPenaltyPredColumn, setDataPrivacyPenaltyPredColumn]: any = useState(DATA_PRIVACY_PENALTY_PREDICTION_COLUMN);
  const [regulatiobPredictionColumn, setRegulatiobPredictionColumn]: any = useState();

  //selector
  const allStatesTotalRecords: any = useAppSelector(getAllStatesTotalRecords);
  const getDataPrivacyPenaltyPrediction: any = useAppSelector(getDataPrivacyPenaltyPredictionDetails);
  const getRegulationPrediction: any = useAppSelector(getRegulationPredictionDetails);

  useEffect(() => {
    switch (params.reportType) {
      case REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION:
        setReportName(REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION)
        break;
      case REPORT_NAME.REGULATION_PREDICTION:
        setReportName(REPORT_NAME.REGULATION_PREDICTION)
        break;
      default:
    }
  }, []);
  ;

  const paginationChange = (page: number, offset: number) => {
    if (reportName === "state") {

    }
    setCurrentPage(page);
    setOffset(offset);
  };

  const handleShow = () => setShow(true);

  // navigate............
  const handleBack = () => {
    navigate("/complianceReports");
  };

  useEffect(() => {
    if (getDataPrivacyPenaltyPrediction) {
      setDataPrivacyPenaltyPredDatasource(getDataPrivacyPenaltyPrediction);
    }
  }, [getDataPrivacyPenaltyPrediction]);

  useEffect(() => {
    if (getRegulationPrediction) {
      setImageUrl(getRegulationPrediction)
    }
  }, [getRegulationPrediction]);

  const handleExportClick = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION) {
      fileName = REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION;
      columns = dataPrivacyPenaltyPredColumn;
      dataSource = dataPrivacyPenaltyPredDatasource;
    } 
    
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true
      })
      .saveAs(`${fileName}.xlsx`);
  };

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div onClick={handleShow} className="d-flex px-2 align-items-center mt-1">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0 text-nowrap"
              onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
            >
              {/* <BiPlus /> */}
              New Request
            </Button>
          </div>
          {show && (
            <PredictionModelsData
              show={show}
              setShow={setShow}
              reportName={params.reportType}
              reportNameCode={reportName}
              setDataPrivacyPenaltyPredDatasource={setDataPrivacyPenaltyPredDatasource}
              setFileName={setFileName}
            />
          )}
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
          {reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION && (
            <div className="primary mt-2 mt-md-0 d-flex justify-content-end w-100 mx-2">
              <Button
                variant="primary"
                className="d-flex align-items-center me-0"
                onClick={handleExportClick}
              >
                Export
              </Button>
            </div>
          )}
        </div>
        {fileName && (
          <p className="mt-2 mx-2 fw-bold">{fileName}</p>
        )
        }
      </div>
      {reportName === REPORT_NAME.REGULATION_PREDICTION ? (
        <div className="mt-3 overflow-y-auto">
            <>
              <img src={imageUrl} alt="No graph to display please make a new request"/>
            </>
        </div>
      ) : (
        <div className="my-2 w-100 overflow-x-auto rounded-3">
          <Table
            bordered={true}
            rowKey={"id"}
            pagination={
              reportName === "state"
                ? {
                  total: allStatesTotalRecords && allStatesTotalRecords,
                  responsive: true,
                  current: currentpage,
                  showSizeChanger: true,
                  pageSizeOptions: [15, 20, 30],
                  onChange: (page, offset) => {
                    paginationChange(page, offset);
                  },
                  pageSize: offset,
                }
                : {
                  responsive: true,
                }
            }
            columns={
              reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION
                ? dataPrivacyPenaltyPredColumn
                : reportName === REPORT_NAME.REGULATION_PREDICTION
                  ? regulatiobPredictionColumn
                  : ""
            }
            dataSource={
              reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION
                ? dataPrivacyPenaltyPredDatasource
                : reportName === REPORT_NAME.REGULATION_PREDICTION
                  ? regulatiobPredictionDatasource
                  : ""
            }
          />
        </div>
      )}
    </div>
  );
};
export default PredictionModels;
