import { useState } from "react";
import "./dashboard.scss";
import { Checkbox, Select, Tabs } from "antd";

import DashboardCorporateUser from "./CorporateUserDashboard/DashboardCorporateUser";
import { USER_ROLE } from "../../constant/AppConstant";
import { checkPermissions } from "../../component/utils";
import { PERMISSION, PERMISSION_CODE } from "../../constant/userPermission";
import { getLocalStorageData } from "../../utils/LocalStorageService";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;

type Props = {};

const Dashboard = (props: Props) => {

  const [title, setTitle] = useState(USER_ROLE.CORPORATE);

  const localUserObj = getLocalStorageData("localUser");

  const items = [
    {
      key: USER_ROLE.CORPORATE,
      label: "Corporate User",
      children: <DashboardCorporateUser title={title} />
    },
    {
      key: USER_ROLE.GOVERNMENT,
      label: "Government User",
      children: <DashboardCorporateUser title={title} />
    },
    {
      key: USER_ROLE.INNOVATOR,
      label: "Innovator User",
      children: <DashboardCorporateUser title={title} />
    },
    {
      key: USER_ROLE.NGO,
      label: "NGO User",
      children: <DashboardCorporateUser title={title} />
    }
  ]

  const handleTabClick = (event: any) => {
    setTitle(event);
  }

  return (
    <>
      {localUserObj?.permissions && checkPermissions(PERMISSION_CODE.DASHBOARD, localUserObj?.permissions) === true ? (
        <>
          <div className="">
            {/* Inlet */}
            {/* <div className="d-flex justify-content-between flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row">
              <h4 className="title-d">Dashboard</h4>
              <div className="d-flex align-items-center justify-content-start justify-content-md-end">
                <div className="col-xs-2">
                </div>
                <div className="dropdown mx-2 cursor-pointer">

                </div>
              </div>
            </div> */}
            {/* Content */}
          </div>
          <div className="overflow-y-auto">
            {/* Inlet */}
            <div className="Navs w-400" style={{ fontWeight: "700" }}>
              <Tabs defaultActiveKey="1" items={items} onTabClick={handleTabClick}>
              </Tabs>
            </div>
          </div>
        </>
      ) : (
        <div>If you want to access this please contact info@capitoltunnels.ai</div>
      )}
    </>
  );
};

export default Dashboard;
