import { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  BiChevronRight,
  BiChevronDown,
} from "react-icons/bi";
import "./search.scss";
import { useAppSelector } from "../redux-toolkit/hooks";
import { getAuthData } from "../redux-toolkit/slices/authSlice";
import { BsFileEarmarkText, BsFileText, BsGrid1X2, BsSnow3 } from "react-icons/bs";
import { PAGE_TITLE, USER_ROLE } from "../constant/AppConstant";
import ForgotResetPassword from "../pages/auth/ForgotResetPassword";
import { REPORT_TYPE } from "../constant/reportType";
import { useSaveAuditLogMutation } from "../redux-toolkit/services/auditLogService";
import { getLocalStorageData } from "../utils/LocalStorageService";

type Props = {};

const Layout = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userBasedRoute = localStorage.getItem("userBasedRoute");
  const reportType = localStorage.getItem("selectedReport");
  const tab: any = localStorage.getItem("tab");

  const [isExpanded, setIsExpanded] = useState(false);
  const [navigation, setNavigation]: any = useState([]);
  const [resetPasswordShow, setResetPasswordShow] = useState(false);
  const [title, setTitle]: any = useState();

  const currentUrl = window.location.href;
  const startTime = new Date();
  const [saveAuditLog, saveAuditLogResponse] = useSaveAuditLogMutation();
  const localUserObj = getLocalStorageData("localUser");

  useEffect(() => {
    const urlString = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
    if (urlString === "dashboard") {
      setTitle(PAGE_TITLE.DASHBOARD);
      localStorage.setItem("tab", PAGE_TITLE.DASHBOARD);
      saveAuditLog({
        payload: {
          startTime: startTime.toISOString(),
          // endTime: startTime.toISOString(),
          // request: {},
          // response: {},
          // serviceName: "login",
          eventName: "dashboard",
          url: "/dashboard",
          userId: localUserObj?.id
        }
      })
    } else if (urlString === "reports") {
      setTitle(PAGE_TITLE.MASTER);
      localStorage.setItem("tab", PAGE_TITLE.MASTER);
      saveAuditLog({
        payload: {
          startTime: startTime.toISOString(),
          // endTime: startTime.toISOString(),
          // request: {},
          // response: {},
          // serviceName: "login",
          eventName: "reports",
          url: "/reports",
          userId: localUserObj?.id
        }
      })
    } else if (urlString === "complianceReports") {
      setTitle(PAGE_TITLE.COMPLIANCE_TEST);
      localStorage.setItem("tab", PAGE_TITLE.COMPLIANCE_TEST);
      saveAuditLog({
        payload: {
          startTime: startTime.toISOString(),
          // endTime: startTime.toISOString(),
          // request: {},
          // response: {},
          // serviceName: "login",
          eventName: "complianceReports",
          url: "/complianceReports",
          userId: localUserObj?.id
        }
      })
    } else if (urlString === "aiGovernanceReports") {
      setTitle(PAGE_TITLE.AI_GOVERNANCE_TITLE);
      localStorage.setItem("tab", PAGE_TITLE.AI_GOVERNANCE_TITLE);
      saveAuditLog({
        payload: {
          startTime: startTime.toISOString(),
          // endTime: startTime.toISOString(),
          // request: {},
          // response: {},
          // serviceName: "login",
          eventName: "aiGovernanceReports",
          url: "/aiGovernanceReports",
          userId: localUserObj?.id
        }
      })
    } else if (urlString === "corporate-user-dashboard") {
      setTitle(PAGE_TITLE.DASHBOARD);
      saveAuditLog({
        payload: {
          startTime: startTime.toISOString(),
          // endTime: startTime.toISOString(),
          // request: {},
          // response: {},
          // serviceName: "login",
          eventName: "corporate-user-dashboard",
          url: "/corporate-user-dashboard",
          userId: localUserObj?.id
        }
      })
    } else if (
      reportType === REPORT_TYPE.DATA_SOURCE_MANAGEMENT
      || reportType === REPORT_TYPE.USER_MASTER
      || reportType === REPORT_TYPE.COMMON) {
      setTitle(PAGE_TITLE.MASTER);
    } else if (
      reportType === REPORT_TYPE.PRIVACYGUARD_PRO
      || reportType === REPORT_TYPE.CYBERSECAI_SHIELD
      || reportType === REPORT_TYPE.BIASMITIGATE_PRO
      || reportType === REPORT_TYPE.PREDICTION_MODELS
    ) {
      setTitle(PAGE_TITLE.COMPLIANCE_TEST)
    } else if (
      reportType === REPORT_TYPE.DATA_GOVERNANCE
    ) {
      setTitle(PAGE_TITLE.AI_GOVERNANCE_TITLE)
    } else {
      setTitle("");
    }
  }, [currentUrl])

  useEffect(() => {
    if (saveAuditLogResponse.isSuccess) {
      // dispatch(setAuditLog(saveAuditLogResponse))

    } else if (saveAuditLogResponse.isError) {
      console.log("error while saving data");
      // alertMessage("error while saving data", "error");
    }
  }, [saveAuditLogResponse.isSuccess, saveAuditLogResponse.isError]);

  useEffect(() => {
    if (userBasedRoute === USER_ROLE.ADMIN || userBasedRoute === USER_ROLE.BETA_TEST_USER) {
      setNavigation([
        {
          id: 0,
          name: "Dashboard",
          route: "/dashboard",
          isActive: true,
          defaultIcon: <BsGrid1X2 />,
          largeIcon: <BsGrid1X2 size={24} />,
          code: ['CAI_DASH']
        },
        {
          id: 1,
          name: "Master",
          route: "/reports",
          isActive: false,
          defaultIcon: <BsFileText />,
          largeIcon: <BsFileText size={24} />,
          innerNav: []
        },
        {
          id: 2,
          name: "Compliance Test",
          route: "/complianceReports",
          isActive: false,
          defaultIcon: <BsFileEarmarkText />,
          largeIcon: <BsFileEarmarkText size={24} />,
          innerNav: []
        },
        {
          id: 3,
          name: "AI Governance",
          route: "/aiGovernanceReports",
          isActive: false,
          defaultIcon: <BsSnow3 />,
          largeIcon: <BsSnow3 size={24} />
        }
      ])

    } else if (userBasedRoute === USER_ROLE.CORPORATE
      || userBasedRoute === USER_ROLE.NGO
      || userBasedRoute === USER_ROLE.GOVERNMENT
      || userBasedRoute === USER_ROLE.INNOVATOR) {
      setNavigation([{
        id: 0,
        name: "Dashboard",
        route: "corporate-user-dashboard",
        isActive: true,
        defaultIcon: <BsGrid1X2 />,
        largeIcon: <BsGrid1X2 size={24} />
      },])
    }
  }, [userBasedRoute])

  const currentUserData = useAppSelector(getAuthData);
  const currentUserName: any = localStorage.getItem("name");

  const handleMenuToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleLogOut = () => {
    navigate("/logout");
  };

  const handleResetClick = () => setResetPasswordShow(true);

  const handleLogoClick = () => {
    navigate("/home")
    // if (userBasedRoute === USER_ROLE.ADMIN) {
    //   navigate("/dashboard");
    // } else {
    //   navigate("/corporate-user-dashboard");
    // }
  }

  const handleLinkClick = (name: any) => {
    if (name === PAGE_TITLE.MASTER) {
      localStorage.setItem("selectedReport", REPORT_TYPE.DATA_SOURCE_MANAGEMENT);
    } else if (name === PAGE_TITLE.COMPLIANCE_TEST) {
      localStorage.setItem("selectedReport", REPORT_TYPE.PRIVACYGUARD_PRO)
    } else if (name === PAGE_TITLE.AI_GOVERNANCE_TITLE) {
      localStorage.setItem("selectedReport", REPORT_TYPE.DATA_GOVERNANCE)
    } else {
      localStorage.setItem("selectedReport", REPORT_TYPE.DATA_SOURCE_MANAGEMENT);
    }
  }

  useEffect(() => {
    if (tab && tab !== PAGE_TITLE.COMPLIANCE_TEST) {
      localStorage.removeItem("selectedReportName");
    }
  }, [tab])

  return (
    <div className="cm-sidebar-layout d-flex vh-100">
      <div
        className="cm-sidebar h-100 bg-cm-light-primary1 shadow-sm sticky-top"
        style={{
          minWidth: isExpanded ? "300px" : "120px",
          maxWidth: isExpanded ? "300px" : "120px",
          // zIndex: 1055
        }}
      >
        {/* Logo */}
        <div className="position-relative d-flex align-items-center justify-content-center p-3" onClick={handleLogoClick}>
          <img
            src={require("../assets/logo-blue.png")}
            className="logo-width"
            height={37}
            // width={80}
            alt="logo"
          />
        </div>
        {/* Navigation */}
        <div className="p-3 cm-navigation">
          {navigation.map((navItem: any, i: any) => {
            if (location.pathname === navItem.route) {
              navItem.isActive = true;
            } else {
              navItem.isActive = false;
            }

            return (
              <NavLink
                className={`btn cm-btn border-0 w-100 ${isExpanded ? "border-top rounded-0 py-3" : ""
                  } ${isExpanded && i === navigation?.length - 1
                    ? "border-bottom"
                    : ""
                  }`}
                key={i}
                to={navItem.route} 
                // onClick={handleLinkClick}
                onClick={() => handleLinkClick(navItem.name)}
              >
                <div
                  className={`d-flex ${isExpanded
                    ? "flex-row align-items-center"
                    : "flex-column justify-content-center align-items-center"
                    }`}
                >
                  <div
                    className={`cm-nav-icon 
                     ${isExpanded ? "cm-nav-icon-sm" : ""}`}
                  >
                    {isExpanded ? navItem.defaultIcon : navItem.largeIcon}
                  </div>
                  <div className="d-flex align-items-center justify-content-between flex-fill">
                    <h6
                      className={`text-center cm-nav-text fw-normal mb-0 ${isExpanded ? "ms-3" : "mt-2"
                        }`}
                    >
                      {navItem.name}
                    </h6>
                    {isExpanded && navItem.innerNav && (
                      <BiChevronRight
                        className={
                          navItem.isActive ? "text-cm-primary" : "text-black"
                        }
                      />
                    )}
                  </div>
                </div>
              </NavLink>
            );
          })}
        </div>
        <div className="dropdown d-flex align-items-end h-50 justify-content-center d-block d-sm-none">
          <div
            className="d-flex align-items-center ms-3 text-cm-light-text cursor-pointer "
            role="button"
            data-bs-toggle="dropdown"
          >
            <img
              src={require("../assets/avatar.png")}
              height={35}
              width={35}
              alt=""
            />
            {/* <h6 className="mb-0 ms-2">
                {JSON.parse(currentUserName)}
              </h6> */}
            <BiChevronRight size={24} />
          </div>
          <ul
            className="dropdown-menu dropdown-menu-light"
            aria-labelledby="navbarDropdownMenuLink"
          >
            {/* <hr className="bg-danger border-2 border-top border-secondary" /> */}
            <li>
              <a className="dropdown-item">
                {JSON.parse(currentUserName)}
              </a>
            </li>
            <hr />
            <li onClick={handleResetClick}>
              <a className="dropdown-item cursor-pointer">
                Reset passoword
              </a>
            </li>
            <li onClick={handleLogOut}>
              <a className="dropdown-item cursor-pointer">
                Sign out
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className="flex-fill  bg-cm-light-primary cm-wrapper vh-100 d-flex flex-column"
        // className="flex-fill p-3 ps-2 pe-5 bg-cm-light-primary cm-wrapper vh-100 d-flex flex-column"
        style={{ width: "80%" }}
      >
        <div className="cm-header sticky-top bg-cm-light-primary1 d-flex align-items-center justify-content-between p-3">
          {/* Current user */}
          <div className="d-flex align-items-center flex-fill justify-content-between">
            <h4 className="title-d mb-0">{userBasedRoute !== USER_ROLE.ADMIN ? `${userBasedRoute}  ${title}` : title}</h4>
            <div className="dropdown d-none d-sm-block">
              <div
                className="d-flex align-items-center ms-3 text-cm-light-text cursor-pointer "
                role="button"
                data-bs-toggle="dropdown"
              >
                <img
                  src={require("../assets/avatar.png")}
                  height={35}
                  width={35}
                  alt=""
                />
                <h6 className="mb-0 ms-2">
                  {/* {currentUserData && currentUserData?.name} */}
                  {JSON.parse(currentUserName)}
                </h6>
                <BiChevronDown size={24} />
              </div>
              <ul
                className="dropdown-menu dropdown-menu-light"
                aria-labelledby="navbarDropdownMenuLink"
              >
                {/* <hr className="bg-danger border-2 border-top border-secondary" /> */}
                <li onClick={handleResetClick}>
                  <a className="dropdown-item cursor-pointer">
                    Reset password
                  </a>
                </li>
                <li onClick={handleLogOut}>
                  <a className="dropdown-item cursor-pointer">
                    Sign out
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="cm-content-wrapper p-3 px-md-4 px-3 h-100 flex-fill" style={{overflowX: "hidden"}}>
          {/* <div className="cm-content-wrapper overflow-y-auto h-100 flex-fill"> */}
          {/* All the pages will be rendered here using Outlet component. */}
          <Outlet />
        </div>
      </div>
      {resetPasswordShow && (
        <ForgotResetPassword
          forgotResetShow={resetPasswordShow}
          setForgotResetShow={setResetPasswordShow}
          title={PAGE_TITLE.RESET_PASSWORD} />
      )}
    </div>
  );
};

export default Layout;
