import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { fastApiBaseUrl, fastApi } from '../endpoints';
// Define a service using a base URL and expected endpoints
export const fastApiService = createApi({
    reducerPath: 'fastApiService',
    baseQuery: fetchBaseQuery({ baseUrl: fastApiBaseUrl }),
    tagTypes: ['FastApi'],
    endpoints: (builder) => ({
        dataSetBiasTest: builder.mutation({
            query(body) {
                return {
                    url: `${fastApi.dataSetBiasTest}`,
                    method: 'POST',
                    // headers: { 'Content-type': "application/json"},
                    body: body.file,
                }
            }
        }),
        fairnessMetricsForMachineLearPred: builder.mutation({
            query(body) {
                return {
                    url: `${fastApi.calculateFairnessMetrics}`,
                    method: 'POST',
                    // headers: { 'Content-type': "application/json"},
                    body: body.file,
                }
            }
        }),
        dataPrivacyPenaltyPrediction: builder.mutation({
            query(body) {
                return {
                    url: `${fastApi.calculatePenalty}`,
                    method: 'POST',
                    // headers: { 'Content-type': "application/json"},
                    body: body.payload,
                }
            }
        }),
        sensitiveAndBiasDataIdentification: builder.mutation({
            query(body) {
                return {
                    url: `${fastApi.identifySensitiveColumns}`,
                    method: 'POST',
                    // headers: { 'Content-type': "application/json"},
                    body: body.file,
                }
            }
        }),
        aiAlgAccReliabilityTest: builder.mutation({
            query(body) {
                return {
                    url: `${fastApi.algorithmAccuracy}`,
                    method: 'POST',
                    // headers: { 'Content-type': "application/json"},
                    body: body.payload,
                }
            }
        }),
        featureDominance: builder.mutation({
            query(body) {
                return {
                    url: `${fastApi.featureDominance}`,
                    method: 'POST',
                    // headers: { 'Content-type': "application/json"},
                    body: body.file,
                }
            }
        }),
        employmentBias: builder.mutation({
            query(body) {
                return {
                    url: `${fastApi.employmentbias}`,
                    method: 'POST',
                    // headers: { 'Content-type': "application/json"},
                    body: body.file,
                }
            }
        }),
        regulationPrediction: builder.mutation({
            query(body) {
                return {
                    url: `${fastApi.generate_graph}?population=${body.population}&infected=${body.infected}&beta=${body.beta}&gamma=${body.gamma}&days=${body.days}&description=${body.description}`,
                    method: 'GET',
                    // headers: { 'Content-type': "image/jpeg"},
                    responseType: 'arraybuffer',
                    // body: body.payload,
                }
            }
        }),
        unstructuredDataBiasTest: builder.mutation({
            query(body) {
                return {
                    url: `${fastApi.unstructuredDataBiasTest}?folder_path=${body.folder_path}&keywords_file_path=${body.keywords_file_path}`,
                    // http://127.0.0.1:8000/process-files?
                    // folder_path=D%3A%2FCompliance%20Test%201%2FDatasets%2FLLM%20Bias&
                    // keywords_file_path=D%3A%2FCompliance%20Test%201%2FDatasets%2FKeywords.csv

                    // ?folder_path=${}&keywords_file_path=${}
                    method: 'POST',
                    // headers: { 'Content-type': "application/json"},
                    body: body.file,
                }
            }
        }),
        generatePdf: builder.mutation({
            query(body) {
                return {
                    url: `${fastApi.generatePdf}`,
                    method: 'POST',
                    // headers: { 'Content-type': "image/jpeg"},
                    responseType: 'arraybuffer',
                    body: body,
                }
            }
        }),
    })
});
export const {
    useDataSetBiasTestMutation,
    useDataPrivacyPenaltyPredictionMutation,
    useSensitiveAndBiasDataIdentificationMutation,
    useAiAlgAccReliabilityTestMutation,
    useFairnessMetricsForMachineLearPredMutation,
    useFeatureDominanceMutation,
    useEmploymentBiasMutation,
    useRegulationPredictionMutation,
    useUnstructuredDataBiasTestMutation,
    useGeneratePdfMutation,
} = fastApiService;