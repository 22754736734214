import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataServiceBaseUrl, dataService } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const responsibilityService = createApi({
  reducerPath: "responsibilityService",
  baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
  tagTypes: ["Responsibility"],
  endpoints: (builder) => ({
    getAllResponsibilityGroup: builder.mutation({
      query(body) {
        return {
          url: `${dataService.responsibilityGroupController.getAllResponsibilityGroup}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
    getAllResponsibilityCenter: builder.mutation({
      query(body) {
        return {
          url: `${dataService.responsibilityCenterController.getAllResponsibilityCenter}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
  }),
});
export const {
  useGetAllResponsibilityCenterMutation,
  useGetAllResponsibilityGroupMutation
} = responsibilityService;
