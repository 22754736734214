export const corporateBigObject = [
    {
        id: 1,
        title: "Global AI Legislation, Data Privacy, Cyber Security Latest Updates",
        link: "https://lookerstudio.google.com/reporting/e7a4e097-9e3a-42e8-890b-11cbf62dcf64/page/jgwaD",
        image: "GlobalAILegislationPrivacy",
    },
    {
        id: 2,
        title: "Global Legislative AI, Data Privacy and Cyber Security PDF Data Library",
        link: "https://lookerstudio.google.com/reporting/84c91894-8623-4137-9deb-de76249cb717/page/vIwaD",
        image: "GlobalLegislativePDFDataLibrary",
    },
    {
        id: 3,
        title: "USA - AI, Cyber Security and Data Privacy Legislative Insights",
        link: "https://lookerstudio.google.com/reporting/65a85092-ab87-408a-9149-5f0c8a3a3a2b/page/3QyZD",
        image: "USAAICyberSecurityDataPrivacy",
    },
    {
        id: 4,
        title: "GDPR Penalty Dashboard",
        link: "https://lookerstudio.google.com/reporting/e0d78101-9b3b-4f09-88c6-aa9858de321a/page/tnmZD",
        image: "GDPRPenalty",
    },
    {
        id: 5,
        title: "Global AI Legislation Focus Area",
        link: "https://lookerstudio.google.com/u/0/reporting/53f75a39-1379-4dc9-b643-6034ddf8c8b0/page/qxObD",
        image: "globalAILFoucusAreas"
    },
    {
        id: 6,
        title: "A.I. Legislation Bill Status Updates",
        link: "https://lookerstudio.google.com/reporting/5d4b1a7d-9300-42e0-939b-aee7829f6ad9/page/JCTbD",
        image: "aiLegislationBillStatusUpdates"
    },
]

export const govBigObject = [
    {
        id: 1,
        title: "Global AI Legislation, Data Privacy, Cyber Security Latest Updates",
        link: "https://lookerstudio.google.com/reporting/e7a4e097-9e3a-42e8-890b-11cbf62dcf64/page/jgwaD",
        image: "GlobalAILegislationPrivacy",
    },
    {
        id: 2,
        title: "Global Legislative AI, Data Privacy and Cyber Security PDF Data Library",
        link: "https://lookerstudio.google.com/reporting/84c91894-8623-4137-9deb-de76249cb717/page/vIwaD",
        image: "GlobalLegislativePDFDataLibrary",
    },
]

export const corporateSmallObject = [
    {
        id: 1,
        title: "Web Scrap Data Analysis",
        link: "https://lookerstudio.google.com/reporting/98f4d7e9-d907-4116-adbe-0d4ee53b5468/page/EGXaD",
        image: "webScrap",
    },
    {
        id: 2,
        title: "Committees Insights",
        link: "https://lookerstudio.google.com/reporting/4141930e-19a7-4099-b1b4-ef26261dc415/page/VZCaD",
        image: "committes",
    },
    {
        id: 3,
        title: "Congress",
        link: "https://lookerstudio.google.com/reporting/9ab6dc96-1580-400b-b4ef-aa072defd348/page/C2AaD",
        image: "congress",
    },
    {
        id: 4,
        title: "EU Legislative Title Insights",
        link: "https://lookerstudio.google.com/reporting/e01056aa-db2b-4bf5-ab24-cf405fb6d145/page/EoJaD",
        image: "EuLegislative"
    },
    {
        id: 5,
        title: "EU Legislative Dashboard",
        link: "https://lookerstudio.google.com/reporting/34f0b3f6-d351-44ae-a0de-72fe5a2c8d34/page/suJaD",
        image: "EuLegislativeDashboard"
    },
    {
        id: 6,
        title: "Top 10 Sponsors Dashboard",
        link: "https://lookerstudio.google.com/reporting/a223b9aa-2d62-4b04-9941-4ca74e056ed8/page/nDWaD",
        image: "Top10Sponsors"
    },
    {
        id: 7,
        title: "USA Legislative Data Wordcloud Analytics",
        link: "https://lookerstudio.google.com/reporting/27b60a49-e59c-4f35-9fcd-ae074de2f0fd/page/sHhaD",
        image: "USADataWordcloud"
    },

]

export const govSmallObject = [
    {
        id: 1,
        title: "Web Scrap Data Analysis",
        link: "https://lookerstudio.google.com/reporting/98f4d7e9-d907-4116-adbe-0d4ee53b5468/page/EGXaD",
        image: "webScrap",
    },
    {
        id: 2,
        title: "Committees Insights",
        link: "https://lookerstudio.google.com/reporting/4141930e-19a7-4099-b1b4-ef26261dc415/page/VZCaD",
        image: "committes",
    },
    {
        id: 3,
        title: "Congress",
        link: "https://lookerstudio.google.com/reporting/9ab6dc96-1580-400b-b4ef-aa072defd348/page/C2AaD",
        image: "congress",
    },
    {
        id: 4,
        title: "EU Legislative Title Insights",
        link: "https://lookerstudio.google.com/reporting/e01056aa-db2b-4bf5-ab24-cf405fb6d145/page/EoJaD",
        image: "EuLegislative"
    },
    {
        id: 5,
        title: "EU Legislative Dashboard",
        link: "https://lookerstudio.google.com/reporting/34f0b3f6-d351-44ae-a0de-72fe5a2c8d34/page/suJaD",
        image: "EuLegislativeDashboard"
    },
]


export const MenuItemsData = [
    {
        label: 'Organization',
        key: 'Organization',
        children: [
            {
                label: 'Group Details',
                key: 'GroupDetails',
                children: [
                    {
                        label: 'Parameter',
                        key: 'parameter',
                        onClick: (e: any) => {
                            console.log(e, 'e')
                            // Do something when the Parameter item is clicked.
                        },
                    },
                    {
                        label: 'Evidence governance control',
                        key: 'evidence',
                    },
                    {
                        label: 'Risk governance control',
                        key: 'risk',
                    },
                    {
                        label: 'Governance Control',
                        key: 'governanceControl',
                    },
                    {
                        label: 'Assessment',
                        key: 'assessment',
                    },
                    {
                        label: 'Audit Plan',
                        key: 'auditPlan',
                    },
                    {
                        label: 'Audit',
                        key: 'audit',
                    },
                ],
            },
            {
                label: 'Group Members',
                key: 'GroupMembers',
            },
            {
                label: 'Group Policies',
                key: 'GroupPolicies'
            },
            {
                label: 'Group Projects',
                key: 'GroupProjects'
            },
        ],
    },
    {
        label: 'Alphabet Group',
        key: 'AlphabetGroup',
        children: [
            {
                label: 'Group Details',
                key: 'GroupDetails'
            },
            {
                label: 'Group Membership',
                key: 'GroupMembership'
            },
        ],
    },
    {
        label: 'Responsibility Group',
        key: 'ResponsibilityGroup',
        children: [
            {
                label: 'Group Details',
                key: 'GroupDetails'
            },
            {
                label: 'Group Members',
                key: 'GroupMembers'
            },
            {
                label: 'Group Policies',
                key: 'GroupPolicies'
            },
            {
                label: 'Group Projects',
                key: 'GroupProjects'
            },
            {
                label: 'Center Details',
                key: 'CenterDetails'
            },
            {
                label: 'CenterPolicies',
                key: 'CenterPolicies'
            },
            {
                label: 'Center Projects',
                key: 'CenterProjects'
            },
        ],
    },
    {
        label: 'GDPR Compliance',
        key: 'GDPRCompliance',
        children: [
            {
                label: 'Compliance Details',
                key: 'ComplianceDetails'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Compliance Projects',
                key: 'ComplianceProjects'
            },
            {
                label: 'Data Destruction',
                key: 'Data Destruction'
            }
        ],
    },


    {
        label: 'Data Collection',
        key: 'DataCollection',
        children: [
            {
                label: 'Collection Details',
                key: 'CollectionDetails'
            },
            {
                label: 'Data Collectors',
                key: 'DataCollectors'
            },
            {
                label: 'Collection Policies',
                key: 'CollectionPolicies'
            },
            {
                label: 'Data Sources',
                key: 'DataSources'
            },
        ],
    },
    {
        label: 'Data Sources',
        key: 'DataSources',
        children: [
            {
                label: 'Source Details',
                key: 'SourceDetails'
            },
            {
                label: 'Source Contact Informaion',
                key: 'SourceContactInformaion'
            },
            {
                label: 'Data Types',
                key: 'DataTypes'
            },
        ],
    },
    {
        label: 'Data Types',
        key: 'DataTypes',
        children: [
            {
                label: 'Type Details',
                key: 'TypeDetails'
            },
            {
                label: 'Data Consent',
                key: 'DataConsent'
            },
        ],
    },
    {
        label: 'Data Processing',
        key: 'DataProcessing',
        children: [
            {
                label: 'Processing Activity',
                key: 'ProcessingActivity'
            },
            {
                label: 'Data Categories',
                key: 'DataCategories'
            },
            {
                label: 'Data Retention',
                key: 'DataRetention'
            },
            {
                label: 'Data Storage',
                key: 'DataStorage'
            },
            {
                label: 'Processing Activity',
                key: 'ProcessingActivity'
            },
            {
                label: 'Data Categories',
                key: 'DataCategories'
            },
            {
                label: 'Data Encryption',
                key: 'DataEncryption'
            },
            {
                label: 'Encryption Key',
                key: 'EncryptionKey'
            },
            {
                label: 'Encryption Controls',
                key: 'EncryptionControls'
            },
            {
                label: 'Encryption Method',
                key: 'EncryptionMethod'
            },
            {
                label: 'Access Controls',
                key: 'AccessControls'
            },
            {
                label: 'Data Anonymization',
                key: 'DataAnonymization'
            },
        ],
    },
    {
        label: 'Data Access',
        key: 'DataAccess',
        children: [
            {
                label: 'Access Control Type',
                key: 'AccessControlType'
            },
            {
                label: 'Access Control Policy',
                key: 'AccessControlPolicy'
            },
            {
                label: 'Authorization Model',
                key: 'AuthorizationModel'
            },
            {
                label: 'Permissions',
                key: 'Permissions'
            },
            {
                label: 'Role-Based Access',
                key: 'RoleBasedAccess'
            },
            {
                label: 'User Access',
                key: 'UserAccess'
            },
            {
                label: 'Access Control Type',
                key: 'AccessControlType'
            },
            {
                label: 'Authorization Model',
                key: 'AuthorizationModel'
            },
            {
                label: 'Data Requests',
                key: 'DataRequests'
            },
            {
                label: 'Request Status',
                key: 'RequestStatus'
            },
            {
                label: 'Requester Information',
                key: 'RequesterInformation'
            },
        ],
    },
    {
        label: 'Data Requests',
        key: 'DataRequests',
        children: [
            {
                label: 'Request Type',
                key: 'RequestType'
            },
            {
                label: 'Request Status',
                key: 'RequestStatus'
            },
            {
                label: 'Requester Informationl',
                key: 'RequesterInformation'
            },
            {
                label: 'Data Subject Info',
                key: 'DataSubjectInfo'
            },
            {
                label: 'Request Details',
                key: 'RequestDetails'
            },
            {
                label: 'User Consent',
                key: 'UserConsent'
            },
            {
                label: 'Consent Status',
                key: 'ConsentStatus'
            },
            {
                label: 'Consent Records',
                key: 'ConsentRecords'
            },
        ],
    },
    {
        label: 'Data Retention',
        key: 'DataRetention',
        children: [
            {
                label: 'Request Type',
                key: 'RequestType'
            },
            {
                label: 'Request Status',
                key: 'RequestStatus'
            },
            {
                label: 'Requester Informationl',
                key: 'RequesterInformation'
            },
            {
                label: 'Data Subject Info',
                key: 'DataSubjectInfo'
            },
            {
                label: 'Request Details',
                key: 'RequestDetails'
            },
            {
                label: 'User Consent',
                key: 'UserConsent'
            },
            {
                label: 'Consent Status',
                key: 'ConsentStatus'
            },
            {
                label: 'Consent Records',
                key: 'ConsentRecords'
            },
            {
                label: 'Retention Period',
                key: 'RetentionPeriod'
            },
            {
                label: 'Data Destruction',
                key: 'DataDestruction'
            },
            {
                label: 'Data Transfer',
                key: 'DataTransfer'
            },
            {
                label: 'Transfer Recipients',
                key: 'TransferRecipients'
            },
            {
                label: 'Data Encryption',
                key: 'DataEncryption'
            },
            {
                label: 'Data Export',
                key: 'DataExport'
            },
            {
                label: 'Export Recipients',
                key: 'ExportRecipients'
            },
        ],
    },
    {
        label: 'Data Deletion',
        key: 'Data Deletion',
        children: [
            {
                label: 'Deletion Requests',
                key: 'DeletionRequests'
            },
            {
                label: 'Request Status',
                key: 'RequestStatus'
            },
            {
                label: 'Requester Information',
                key: 'RequesterInformation'
            },
            {
                label: 'Data Subject Info',
                key: 'DataSubjectInfo'
            },
            {
                label: 'Data Deletion',
                key: 'DataDeletion'
            },
            {
                label: 'Archiving Requirements',
                key: 'ArchivingRequirements'
            },
            {
                label: 'Archiving Strategy',
                key: 'ArchivingStrategy'
            },
            {
                label: 'Data Retrieval',
                key: 'DataRetrieval'
            },
            {
                label: 'Retention Period',
                key: 'RetentionPeriod'
            },
            {
                label: 'Data Destruction',
                key: 'DataDestruction'
            },
            {
                label: 'Data Transfer',
                key: 'DataTransfer'
            },
            {
                label: 'Transfer Recipients',
                key: 'TransferRecipients'
            },
            {
                label: 'Data Encryption',
                key: 'DataEncryption'
            },
            {
                label: 'Retention Period',
                key: 'RetentionPeriod'
            },
            {
                label: 'Data Export',
                key: 'DataExport'
            },
            {
                label: 'Export Recipients',
                key: 'ExportRecipients'
            },
            {
                label: 'Data Encryption',
                key: 'DataEncryption'
            }
        ],
    },
    {
        label: 'Data Export',
        key: 'DataExport',
        children: [
            {
                label: 'Export Methods',
                key: 'ExportMethods'
            },
            {
                label: 'Export Recipients',
                key: 'ExportRecipients'
            },
            {
                label: 'Data Encryption',
                key: 'DataEncryption'
            },
            {
                label: 'Sharing Agreements',
                key: 'SharingAgreements'
            },
            {
                label: 'Parties Involved',
                key: 'PartiesInvolved'
            },
            {
                label: 'Data Categories',
                key: 'DataCategories'
            },
            {
                label: 'Data Security',
                key: 'DataSecurity'
            },
        ],
    },
    {
        label: 'Responsibility Center',
        key: 'ResponsibilityCenter',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'AI Regulation Standards',
                key: 'AIRegulationStandards'
            },
            {
                label: 'Compliance Reporting',
                key: 'ComplianceReporting'
            },
            {
                label: 'Regulatory Audits',
                key: 'RegulatoryAudits'
            },
            {
                label: 'Compliance Records',
                key: 'ComplianceRecords'
            },
            {
                label: 'Incident Response',
                key: 'IncidentResponse'
            },
        ],
    },
    {
        label: 'AI Regulation Compliance(NIST)',
        key: 'AIRegulationCompliance(NIST)',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Training Standards',
                key: 'TrainingStandards'
            },
            {
                label: 'Training Process',
                key: 'TrainingProcess'
            },
            {
                label: 'Data Sources',
                key: 'DataSources'
            },
            {
                label: 'Data Preprocessing',
                key: 'DataPreprocessing'
            },
        ],
    },
    {
        label: 'AI Model Training',
        key: 'AIModelTraining',
        children: [
            {
                label: 'Training Data Sources ',
                key: 'TrainingDataSources '
            },
            {
                label: 'Data Quality Checks',
                key: 'DataQualityChecks'
            },
            {
                label: 'Data Preprocessing',
                key: 'DataPreprocessing'
            },
            {
                label: 'Data Augmentation',
                key: 'DataAugmentation'
            },
            {
                label: 'Data Privacy',
                key: 'DataPrivacy'
            },
            {
                label: 'Training Data Sources',
                key: 'TrainingDataSources '
            },
            {
                label: 'Data Quality Checks',
                key: 'DataQualityChecks'
            },
            {
                label: 'Data Augmentation',
                key: 'DataAugmentation'
            },
            {
                label: 'Model Validation',
                key: 'ModelValidation'
            },
            {
                label: 'Evaluation Metrics',
                key: 'EvaluationMetrics'
            },
        ],
    },
    {
        label: 'Model Validation',
        key: 'ModelValidation',
        children: [
            {
                label: 'Validation Techniques',
                key: 'ValidationTechniques'
            },
            {
                label: 'Evaluation Metrics',
                key: 'EvaluationMetrics'
            },
            {
                label: 'Validation Reports',
                key: 'ValidationReports'
            },
            {
                label: 'Model Testing',
                key: 'ModelTesting'
            },
            {
                label: 'Test Data',
                key: 'TestData'
            },
            {
                label: 'Test Scenarios',
                key: 'TestScenarios'
            },
            {
                label: 'Test Reports',
                key: 'TestReports'
            },
        ],
    },
    {
        label: 'AI Regulation Compliance(NIST)',
        key: 'AIRegulationCompliance(NIST)',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Deployment Standards',
                key: 'DeploymentStandards'
            },
            {
                label: 'Deployment Processes',
                key: 'DeploymentProcesses'
            },
            {
                label: 'Deployment Environment',
                key: 'DeploymentEnvironment'
            },
            {
                label: 'Model Monitoring',
                key: 'ModelMonitoring'
            },
            {
                label: 'Security Controls',
                key: 'SecurityControls'
            },
            {
                label: 'Reporting Mechanisms',
                key: 'ReportingMechanisms'
            },
            {
                label: 'Incident Response',
                key: 'IncidentResponse'
            },
            {
                label: 'Bias Assessment',
                key: 'BiasAssessment'
            },
            {
                label: 'Fairness Metrics',
                key: 'FairnessMetrics'
            },
            {
                label: 'Bias Mitigation',
                key: 'BiasMitigation'
            },
            {
                label: 'Mitigation Evaluation',
                key: 'MitigationEvaluation'
            },
            {
                label: 'Bias Reports',
                key: 'BiasReports'
            },
        ],
    },
    {
        label: 'Model Monitoring',
        key: 'ModelMonitoring',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Monitoring Procedures',
                key: 'MonitoringProcedures'
            },
            {
                label: 'Explainability Metrics',
                key: 'ExplainabilityMetrics'
            },
            {
                label: 'Interpretation Reports',
                key: 'InterpretationReports'
            },
            {
                label: 'Model Adjustments',
                key: 'ModelAdjustments'
            },
            {
                label: 'Compliance Logs',
                key: 'ComplianceLogs'
            },
        ],
    },
    {
        label: 'AI Bias Mitigation',
        key: 'AIBiasMitigation',
        children: [
            {
                label: 'Bias Assessment',
                key: 'BiasAssessment'
            },
            {
                label: 'Fairness Metrics',
                key: 'FairnessMetrics'
            },
            {
                label: 'Bias Mitigation',
                key: 'BiasMitigation'
            },
            {
                label: 'Mitigation Evaluation',
                key: 'MitigationEvaluation'
            },
            {
                label: 'Ethical Principles',
                key: 'EthicalPrinciples'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Ethical Reviews',
                key: 'EthicalReviews'
            },
        ],
    },
    {
        label: 'Responsibility Center',
        key: 'ResponsibilityCenter',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Security Policies',
                key: 'SecurityPolicies'
            },
            {
                label: 'Security Controls',
                key: 'SecurityControls'
            },
            {
                label: 'Security Testing',
                key: 'SecurityTesting'
            },
            {
                label: 'Incident Response',
                key: 'IncidentResponse'
            },
            {
                label: 'Security Auditing',
                key: 'SecurityAuditing'
            },
            {
                label: 'Responsibility Area',
                key: 'ResponsibilityArea'
            },
            {
                label: 'Team Members',
                key: 'TeamMembers'
            },
            {
                label: 'Audit Planning',
                key: 'AuditPlanning'
            },
            {
                label: 'Audit Execution',
                key: 'AuditExecution'
            },
            {
                label: 'Audit Findings',
                key: 'AuditFindings'
            },
            {
                label: 'GDPR Non-Compliance',
                key: 'GDPRNonCompliance'
            },
            {
                label: 'AI Regulation Violations',
                key: 'AIRegulationViolations'
            },
            {
                label: 'Security Vulnerabilities',
                key: 'SecurityVulnerabilities'
            },
            {
                label: 'Audit Action Plans',
                key: 'AuditActionPlans'
            },
            {
                label: 'Remediation Plans',
                key: 'RemediationPlans'
            },
            {
                label: 'Compliance Improvements',
                key: 'ComplianceImprovements'
            },
            {
                label: 'Review Elements',
                key: 'ReviewElements'
            },
            {
                label: 'Policies and Procedures',
                key: 'PoliciesAndProcedures'
            },
            {
                label: 'GDPR Policies',
                key: 'GDPRPolicies'
            },
            {
                label: 'AI Regulation Procedure',
                key: 'AIRegulationProcedure'
            },
            {
                label: 'Cybersecurity Policies',
                key: 'CybersecurityPolicies'
            },
            {
                label: 'Security Procedures',
                key: 'SecurityProcedures'
            },
            {
                label: 'Asset Reviews',
                key: 'AssetReviews'
            },
            {
                label: 'Data Assets',
                key: 'DataAssets'
            },
            {
                label: 'AIModels',
                key: 'AIModels'
            },
            {
                label: 'Test Logs',
                key: 'TestLogs'
            },
            {
                label: 'Data Sets',
                key: 'DataSets'
            },
            {
                label: 'Bias Elimination',
                key: 'BiasElimination'
            },
            {
                label: 'Codebase',
                key: 'Codebase'
            },
            {
                label: 'Cloud Infrastructure',
                key: 'CloudInfrastructure'
            },
            {
                label: 'Risk Assessment',
                key: 'RiskAssessment'
            },
            {
                label: 'CIA Risk Assessment',
                key: 'CIARiskAssessment'
            },
            {
                label: 'Risk Mitigation Strategies',
                key: 'RiskMitigationStrategies'
            },
            {
                label: 'Risk Reduction Measures',
                key: 'RiskReductionMeasures'
            },
            {
                label: 'SDLC and Documentation',
                key: 'SDLCAndDocumentation'
            },
            {
                label: 'Code Reviews',
                key: 'CodeReviews'
            },
            {
                label: 'Design Documents',
                key: 'DesignDocuments'
            },
            {
                label: 'DeploymentPlans',
                key: 'DeploymentPlans'
            },
            {
                label: 'Logs and Monitoring',
                key: 'LogsAndMonitoring'
            },
            {
                label: 'Change Management',
                key: 'ChangeManagement'
            },
            {
                label: 'Documentation Reviews',
                key: 'DocumentationReviews'
            },
            {
                label: 'Requirements',
                key: 'Requirements'
            },
            {
                label: 'Requirement Documents',
                key: 'RequirementDocuments'
            },
            {
                label: 'Requirement Reviews',
                key: 'RequirementReviews'
            },
            {
                label: 'Requirement Version Tracking',
                key: 'RequirementVersionTracking'
            },
            {
                label: 'Requirement Traceability',
                key: 'RequirementTraceability'
            },
            {
                label: 'Test Cases',
                key: 'TestCases'
            },
            {
                label: 'Test Case Documents',
                key: 'TestCaseDocuments'
            }, {
                label: 'Test Case Reviews',
                key: 'TestCaseReviews'
            },
            {
                label: 'Test Case Version Tracking',
                key: 'TestCaseVersionTracking'
            },
            {
                label: 'Test Case Execution Reports',
                key: 'TestCaseExecutionReports'
            },
            {
                label: 'Test Automation',
                key: 'TestAutomation'
            },
            {
                label: 'IT Infrastructure',
                key: 'ITInfrastructure'
            },
            {
                label: 'Infrastructure Documentation',
                key: 'InfrastructureDocumentation'
            },
            {
                label: 'Infrastructure Reviews',
                key: 'InfrastructureReviews'
            },
            {
                label: 'Infrastructure Version Tracking',
                key: 'InfrastructureVersionTracking'
            },
            {
                label: 'Infrastructure Updates',
                key: 'InfrastructureUpdates'
            }, {
                label: 'Cloud Infrastructure',
                key: 'CloudInfrastructure'
            },
            {
                label: 'Tools',
                key: 'Tools'
            }, {
                label: 'Tool Reviews',
                key: 'ToolReviews'
            },
            {
                label: 'Tool Version Tracking',
                key: 'ToolVersionTracking'
            }, {
                label: 'Tool Updates',
                key: 'ToolUpdates'
            },
            {
                label: 'Data Lineage',
                key: 'DataLineage'
            }, {
                label: 'Data Sources',
                key: 'Data Sources'
            },
            {
                label: 'Data Transformations',
                key: 'DataTransformations'
            }, {
                label: 'Data Destinations',
                key: 'DataDestinations'
            },
            {
                label: 'Data Quality',
                key: 'DataQuality'
            },
            {
                label: 'Data Quality Rules',
                key: 'Data Quality Rules'
            },
            {
                label: 'Data Quality Assessments',
                key: 'DataQualityAssessments'
            },
            {
                label: 'Data Quality Reports',
                key: 'DataQualityReports'
            },
            {
                label: 'Governance Frameworks',
                key: 'GovernanceFrameworks'
            },
            {
                label: 'Framework A',
                key: 'FrameworkA'
            },
            {
                label: 'Control Areas',
                key: 'ControlAreas'
            },
            {
                label: 'Control X',
                key: 'ControlX'
            },
            {
                label: 'Subcontrols',
                key: 'Subcontrols'
            },
            {
                label: 'Subcontrol P',
                key: 'SubcontrolP'
            },
            {
                label: 'Evidences',
                key: 'Evidences'
            },
            {
                label: 'Evidence 1',
                key: 'Evidence1'
            },
            {
                label: 'Evidence 2',
                key: 'Evidence2'
            },
            {
                label: 'Audit Action Plans',
                key: 'Audit Action Plans'
            },
            {
                label: 'Audit Action Plans',
                key: 'Audit Action Plans'
            },
        ],
    },
    {
        label: 'Cybersecurity Compliance',
        key: 'CybersecurityCompliance',
        children: [
            {
                label: 'Compliance Area',
                key: 'ComplianceArea'
            },
            {
                label: 'Compliance Team',
                key: 'ComplianceTeam'
            },
            {
                label: 'Compliance Policies',
                key: 'CompliancePolicies'
            },
            {
                label: 'Security Policies',
                key: 'SecurityPolicies'
            },
            {
                label: 'Policy Categories',
                key: 'PolicyCategories'
            },
            {
                label: 'Policy Elements',
                key: 'PolicyElements'
            },
            {
                label: 'Policy Implementation',
                key: 'PolicyImplementation'
            },
            {
                label: 'Policy Reviews',
                key: 'PolicyReviews'
            },
            {
                label: 'Security Controls',
                key: 'SecurityControls'
            },
            {
                label: 'Control Categories',
                key: 'ControlCategories'
            },
            {
                label: 'Control Measures',
                key: 'ControlMeasures'
            },
            {
                label: 'Control Implementation',
                key: 'ControlImplementation'
            },
            {
                label: 'Control Assessments',
                key: 'ControlAssessments'
            },
            {
                label: 'Testing Schedule',
                key: 'TestingSchedule'
            },
            {
                label: 'Testing Results',
                key: 'TestingResults'
            },
            {
                label: 'Remediation Plans',
                key: 'RemediationPlans'
            },
            {
                label: 'Security Auditing',
                key: 'SecurityAuditing'
            },
            {
                label: 'Incident Response',
                key: 'IncidentResponse'
            },
            {
                label: 'Incident Reporting',
                key: 'IncidentReporting'
            },
            {
                label: 'Investigation Process',
                key: 'InvestigationProcess'
            },
            {
                label: 'Resolution Measures',
                key: 'ResolutionMeasures'
            },
        ],
    },
    {
        label: 'IncidentResponse',
        key: 'IncidentResponse',
        children: [
            {
                label: 'Incident Categories',
                key: 'IncidentCategories'
            },
            {
                label: 'Incident Reporting',
                key: 'IncidentReporting'
            },
            {
                label: 'Investigation',
                key: 'Investigation'
            },
            {
                label: 'Incident Resolution',
                key: 'IncidentResolution'
            },
            {
                label: 'Communication',
                key: 'Communication'
            },
            {
                label: 'Security Auditing',
                key: 'SecurityAuditing'
            },
            {
                label: 'Auditing Schedule',
                key: 'AuditingSchedule'
            },
            {
                label: 'Audit Remediation',
                key: 'AuditRemediation'
            },
        ],
    },
    {
        label: 'Audit Elements',
        key: 'AuditElements',
        children: [
            {
                label: 'Audit Planning',
                key: 'AuditPlanning'
            },
            {
                label: 'Audit Execution',
                key: 'AuditExecution'
            },
            {
                label: 'Audit Findings',
                key: 'AuditFindings'
            },
            {
                label: 'GDPR Non-Compliance',
                key: 'GDPRNonCompliance'
            },
            {
                label: 'AI Regulation Violations',
                key: 'AIRegulationViolations'
            },
            {
                label: 'Audit Action Plans',
                key: 'AuditActionPlans'
            },
            {
                label: 'Review Elements',
                key: 'ReviewElements'
            },
            {
                label: 'Policies and Procedures',
                key: 'PoliciesAndProcedures'
            },
            {
                label: 'Asset Reviews',
                key: 'AssetReviews'
            },
            {
                label: 'Risk Assessment',
                key: 'RiskAssessment'
            },
            {
                label: 'SDLC and Documentation',
                key: 'SDLCAndDocumentation'
            },
            {
                label: 'Requirements',
                key: 'Requirements'
            },
            {
                label: 'Test Cases',
                key: 'TestCases'
            },
            {
                label: 'IT Infrastructure',
                key: 'ITInfrastructure'
            },
            {
                label: 'Tools',
                key: 'Tools'
            },
            {
                label: 'Data Lineage',
                key: 'DataLineage'
            },
            {
                label: 'Governance Frameworks',
                key: 'GovernanceFrameworks'
            },
            {
                label: 'Control ID',
                key: 'ControlID'
            },
            {
                label: 'Element ID',
                key: 'ElementID'
            },
            {
                label: 'Control Name',
                key: 'ControlName'
            },
            {
                label: 'Description',
                key: 'Description'
            },
            {
                label: 'Owner Name',
                key: 'OwnerName'
            },
            {
                label: 'Control Type',
                key: 'ControlType'
            },
            {
                label: 'General Information',
                key: 'General Information'
            },
            {
                label: 'Compliance Details',
                key: 'Compliance Details'
            },
            {
                label: 'Supporting Documents',
                key: 'Supporting Documents'
            },
        ],
    },
    {
        label: 'AI Regulation Violations',
        key: 'AIRegulationViolations',
        children: [
            {
                label: 'Security Vulnerabilities',
                key: 'SecurityVulnerabilities'
            }
        ],
    },
    {
        label: 'Audit Findings',
        key: 'AuditFindings',
        children: [
            {
                label: 'Remediation Plans',
                key: 'RemediationPlans'
            }
        ],
    },
    {
        label: 'Remediation Plans',
        key: 'RemediationPlans',
        children: [
            {
                label: 'Compliance Improvements',
                key: 'ComplianceImprovements'
            }
        ],
    },
    {
        label: 'Policies and Procedures',
        key: 'PoliciesAndProcedures',
        children: [
            {
                label: 'GDPR Policies',
                key: 'GDPRPolicies'
            },
            {
                label: 'AI Regulation Procedures',
                key: 'AIRegulationProcedures'
            },
            {
                label: 'Cybersecurity Policies',
                key: 'CybersecurityPolicies'
            },
        ],
    },
    {
        label: 'Cybersecurity Policies',
        key: 'CybersecurityPolicies',
        children: [
            {
                label: 'Security Procedures',
                key: 'SecurityProcedures'
            },
        ],
    },
    {
        label: 'Asset Reviews',
        key: 'AssetReviews',
        children: [
            {
                label: 'Data Assets',
                key: 'DataAssets'
            },
            {
                label: 'AI Models',
                key: 'AIModels'
            },
        ],
    },
    {
        label: 'AI Models',
        key: 'AIModels',
        children: [
            {
                label: 'Test Logs',
                key: 'TestLogs'
            },
            {
                label: 'Data sets',
                key: 'DataSets'
            },
        ],
    },
    {
        label: 'Data sets',
        key: 'DataSets',
        children: [
            {
                label: 'Bias Elimination',
                key: 'BiasElimination'
            },
        ],
    },
    {
        label: 'Asset Reviews',
        key: 'AssetReviews',
        children: [
            {
                label: 'Codebase',
                key: 'Codebase'
            },
        ],
    },
    {
        label: 'Codebase',
        key: 'Codebase',
        children: [
            {
                label: 'Cloud',
                key: 'Cloud'
            },
        ],
    },
    {
        label: 'Risk Assessment',
        key: 'RiskAssessment',
        children: [
            {
                label: 'CIA Risk Assessment',
                key: 'CIARiskAssessment'
            },
            {
                label: 'Risk Mitigation Strategies',
                key: 'RiskMitigationStrategies'
            },
        ],
    },
    {
        label: 'Risk Mitigation Strategies',
        key: 'RiskMitigationStrategies',
        children: [
            {
                label: 'Risk Reduction Measures',
                key: 'RiskReductionMeasures'
            },
            {
                label: 'Risk Mitigation Strategies',
                key: 'RiskMitigationStrategies'
            },
        ],
    },
    {
        label: 'SDLC and Documentation',
        key: 'SDLCAndDocumentation',
        children: [
            {
                label: 'SDLC Process',
                key: 'SDLCProcess'
            },
            {
                label: 'Code Reviews',
                key: 'CodeReviews'
            },
            {
                label: 'Design Documents',
                key: 'DesignDocuments'
            },
            {
                label: 'Deployment Plans',
                key: 'Deployment Plans'
            },
            {
                label: 'Logs and Monitoring',
                key: 'LogsAndMonitoring'
            },
            {
                label: 'Change Management',
                key: 'ChangeManagement'
            },
        ],
    },
    {
        label: 'Change Management',
        key: 'ChangeManagement',
        children: [
            {
                label: 'Documentation Reviews',
                key: 'DocumentationReviews'
            },
        ],
    },
    {
        label: 'Requirements',
        key: 'Requirements',
        children: [
            {
                label: 'Requirement Documents',
                key: 'RequirementDocuments'
            },
            {
                label: 'Requirement Reviews',
                key: 'RequirementReviews'
            },
            {
                label: 'Requirement Version Tracking',
                key: 'RequirementVersionTracking'
            },
            {
                label: 'Requirement Traceability',
                key: 'RequirementTraceability'
            },
        ],
    },
    {
        label: 'Test Cases',
        key: 'TestCases',
        children: [
            {
                label: 'Test Case Documents',
                key: 'TestCaseDocuments'
            },
            {
                label: 'Test Case Reviews ',
                key: 'TestCaseReviews '
            },
            {
                label: 'Test Case Version',
                key: 'TestCaseVersion'
            },
            {
                label: 'Test Case Execution Report',
                key: 'TestCaseExecutionReport'
            },
        ],
    },
    {
        label: 'Test Case Execution Reports',
        key: 'TestCaseExecutionReports',
        children: [
            {
                label: 'Test Automation',
                key: 'TestAutomation'
            },
        ],
    },
    {
        label: 'IT Infrastucture',
        key: 'ITInfrastucture',
        children: [
            {
                label: 'Infrastructure Documentation',
                key: 'InfrastructureDocumentation'
            },
            {
                label: 'Infrastructure Reviews',
                key: 'InfrastructureReviews'
            },
            {
                label: 'Infrastructure Version Tracking',
                key: 'InfrastructureVersionTracking'
            },
            {
                label: 'Infrastructure Updates',
                key: 'Infrastructure Updates'
            },
        ],
    },
    {
        label: 'Infrastructure Updates',
        key: 'InfrastructureUpdates',
        children: [
            {
                label: 'Cloud Infrastructure',
                key: 'CloudInfrastructure'
            },
        ],
    },
    {
        label: 'Tools',
        key: 'Tools',
        children: [
            {
                label: 'Tool Documentation',
                key: 'ToolDocumentation'
            },
            {
                label: 'Tool Reviews',
                key: 'ToolReviews'
            },
            {
                label: 'Tool Version Tracking',
                key: 'ToolVersionTracking'
            },
        ],
    },
    {
        label: 'Tool Version Tracking',
        key: 'ToolVersionTracking',
        children: [
            {
                label: 'Tool Updates',
                key: 'ToolUpdates'
            },
        ],
    },
    {
        label: 'Data Lineage',
        key: 'DataLineage',
        children: [
            {
                label: 'Data Sources',
                key: 'DataSources'
            },
            {
                label: 'Data Transformations',
                key: 'DataTransformations'
            },
            {
                label: 'Data Destinations',
                key: 'DataDestinations'
            },
        ],
    },
    {
        label: 'Data Destinations',
        key: 'DataDestinations',
        children: [
            {
                label: 'Data Quality',
                key: 'DataQuality'
            },
            {
                label: 'Data Quality Rules',
                key: 'DataQualityRules '
            },
            {
                label: 'Data Quality Assessments',
                key: 'DataQualityAssessments '
            },
        ],
    },
    {
        label: 'Data Quality Assessments',
        key: 'DataQualityAssessments',
        children: [
            {
                label: 'Data Quality Reports',
                key: 'DataQualityReports'
            },
        ],
    },
    {
        label: 'Governance Frameworks',
        key: 'Governance Frameworks',
        children: [
            {
                label: 'Framework A',
                key: 'FrameworkA'
            },
        ],
    },
    // 1107
    {
        label: 'Frameworks A',
        key: 'FrameworksA1',
        children: [
            {
                label: 'Control Areas',
                key: 'ControlAreas'
            },
        ],
    },
    {
        label: 'Control Areas',
        key: 'ControlAreas1',
        children: [
            {
                label: 'Control X',
                key: 'ControlX'
            },
        ],
    },
    {
        label: 'Control X',
        key: 'ControlX1',
        children: [
            {
                label: 'Subcontrols',
                key: 'Subcontrols'
            },
        ],
    },
    {
        label: 'Subcontrols',
        key: 'Subcontrols1',
        children: [
            {
                label: 'Subcontrol P',
                key: 'SubcontrolP'
            },
        ],
    },
    {
        label: 'Subcontrol P',
        key: 'SubcontrolP1',
        children: [
            {
                label: 'Evidence Type',
                key: 'EvidenceType'
            },
            {
                label: 'Evidence Details',
                key: 'EvidenceDetails'
            },
            {
                label: 'Evidence Source',
                key: 'EvidenceSource'
            },
            {
                label: 'Evidence Date',
                key: 'EvidenceDate'
            },
            {
                label: 'Evidence File',
                key: 'EvidenceFile'
            },
        ],
    },
    {
        label: 'Evidences',
        key: 'Evidences',
        children: [
            {
                label: 'Item Number',
                key: 'Item Number'
            },
            {
                label: 'Item Description',
                key: 'Item Description'
            },
            {
                label: 'Item Location',
                key: 'Item Location'
            },
            {
                label: 'Item Custodian',
                key: 'Item Custodian'
            },
            {
                label: 'Item Storage',
                key: 'Item Storage'
            },
        ],
    },
    {
        label: 'Control Y',
        key: 'ControlY',
        children: [
            {
                label: 'Control ID',
                key: 'ControlID1'
            },
            {
                label: 'Element ID',
                key: 'ElementID1'
            },
            {
                label: 'Control Name',
                key: 'ControlName1'
            },
            {
                label: 'Description',
                key: 'Description1'
            },
            {
                label: 'Owner Name',
                key: 'OwnerName1'
            },
            {
                label: 'Control Type',
                key: 'ControlType1'
            },
            {
                label: 'Control Status',
                key: 'Control Status'
            },
            {
                label: 'Control Documentation',
                key: 'Control Documentation'
            },
            {
                label: 'Control Testing Results',
                key: 'Control Testing Results'
            },
            {
                label: 'Control Compliance',
                key: 'Control Compliance'
            },
            {
                label: 'Control Effectiveness',
                key: 'Control Effectiveness'
            },
        ],
    },
    {
        label: 'Compliance Scores',
        key: 'ComplianceScores',
        children: [
            {
                label: 'Framework Information',
                key: 'FrameworkInformation'
            },
            {
                label: 'Compliance Information',
                key: 'ComplianceInformation'
            },
            {
                label: 'Supporting Documents',
                key: 'SupportingDocuments'
            },
        ],
    },
    {
        label: 'Control X Scores',
        key: 'ControlXScores',
        children: [
            {
                label: 'Subcontrol Information',
                key: 'SubcontrolInformation'
            },
            {
                label: 'Compliance Information',
                key: 'ComplianceInformation'
            },
            {
                label: 'Supporting Documents',
                key: 'SupportingDocuments'
            },
        ],
    },
]