import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { REPORT_TYPE } from "../../constant/reportType";
import "./reports.scss";
import { BiCheckCircle } from "react-icons/bi";
import { useAppSelector } from "../../redux-toolkit/hooks";
import { getGdprDetails } from "../../redux-toolkit/slices/gdprSlice";
import { useSaveAuditLogMutation } from "../../redux-toolkit/services/auditLogService";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { checkPermissions } from "../../component/utils";
import { PERMISSION, PERMISSION_CODE } from "../../constant/userPermission";

type Props = {};

const CompliantReports = (props: Props) => {
  const navigate = useNavigate();
  const selectedReportName: any = localStorage.getItem("selectedReportName");
  const tab: any = localStorage.getItem("tab");

  const [selectedReport, setSelectedReport]: any = useState(localStorage.getItem("selectedReport"));
  const startTime = new Date();
  const [saveAuditLog, saveAuditLogResponse] = useSaveAuditLogMutation();
  const localUserObj = getLocalStorageData("localUser");
  // const gdprFileName: any = useAppSelector(getGdprFileName);
  const getDataPrivacyComplianceTestDetails = useAppSelector(getGdprDetails);
  const userBasedRoute: any = localStorage.getItem("userBasedRoute");

  useEffect(() => {
    const selectedReportTab: any = localStorage.getItem("selectedReport");
    setSelectedReport(selectedReportTab);
    if (selectedReport &&
      (selectedReport !== REPORT_TYPE.PRIVACYGUARD_PRO &&
        selectedReport !== REPORT_TYPE.CYBERSECAI_SHIELD &&
        selectedReport !== REPORT_TYPE.BIASMITIGATE_PRO &&
        selectedReport !== REPORT_TYPE.PREDICTION_MODELS)) {
      localStorage.removeItem("selectedReportName");
    }
  }, [selectedReport])

  // useEffect(() => {
  //  if(gdprFileName === null) {
  //   localStorage.removeItem("selectedReportName");
  //  }
  // }, [gdprFileName])

  const [reportTypes, setReportTypes] = useState([
    {
      id: 1,
      name: "PrivacyGuard Pro",
      isActive:
        localStorage.getItem("selectedReport") ===
          REPORT_TYPE.PRIVACYGUARD_PRO
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "PrivacyGuard Pro",
          reports: [
            {
              id: "0",
              name: "Data Privacy Compliance Test",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.DATA_PRIVACY_COMPLIANCE_TEST, localUserObj?.permissions): false,
              route: "/complianceReports/Data Privacy Compliance Test",
            },
            {
              id: "1",
              name: "Data Privacy Penalty Prediction",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.DATA_PRIVACY_PENALTY_PREDICTION, localUserObj?.permissions): false,
              route: "/complianceReports/Data Privacy Penalty Prediction",
            },
            {
              id: "2",
              name: "Sensitive and Bias Data Identification",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION, localUserObj?.permissions) : false,
              route: "/complianceReports/Sensitive and Bias Data Identification",
            },
            {
              id: "3",
              name: "AI Regulation Compliance Test",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.AI_REGULATION_COMPLIANCE_TEST, localUserObj?.permissions) : false,
              route: "/complianceReports/AI Regulation Compliance Test",
            },
            {
              id: "4",
              name: "Employment Bias",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.EMPLOYMENT_BIAS, localUserObj?.permissions) : false,
              route: "/complianceReports/Employment Bias",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "CyberSecAI Shield",
      isActive:
        localStorage.getItem("selectedReport") === REPORT_TYPE.CYBERSECAI_SHIELD
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "CyberSecAI Shield",
          reports: [
            {
              id: "0",
              name: "Code Cyber Security Vulnerability Analysis",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS, localUserObj?.permissions) : false,
              route: "/complianceReports/Code Cyber Security Vulnerability Analysis",
            },
            {
              id: "1",
              name: "AI Algorithm Accuracy and Reliability Test",
              disabled: localUserObj?.permissions ? checkPermissions( PERMISSION_CODE.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST, localUserObj?.permissions) : false,
              route: "/complianceReports/AI Algorithm Accuracy and Reliability Test",
            },
            {
              id: "2",
              name: "Code AI Bias Analysis",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.CODE_AI_BIAS_ANALYSIS, localUserObj?.permissions) : false,
              route: "/complianceReports/Code AI Bias Analysis",
            },
            {
              id: "3",
              name: "Feature Dominance",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.FEATURE_DOMINANCE, localUserObj?.permissions) : false,
              route: "/complianceReports/Feature Dominance",
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: "BiasMitigate Pro",
      isActive:
        localStorage.getItem("selectedReport") === REPORT_TYPE.BIASMITIGATE_PRO
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "BiasMitigate Pro",
          reports: [
            {
              id: "0",
              name: "Data Set Bias Test",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.DATA_SET_BIAS_TEST, localUserObj?.permissions) : false,
              route: "/complianceReports/Data Set Bias Test",
            },
            {
              id: "1",
              name: "Fairness Metrics for Machine Learning Prediction",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.FAIRNESS_METRICS_FOR_MACHINE_LEARNING, localUserObj?.permissions) : false,
              route: "/complianceReports/Fairness Metrics for Machine Learning Prediction",
            },
            {
              id: "2",
              name: "Project Code Bias and Vulnerability Summary",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY, localUserObj?.permissions) : false,
              route: "/complianceReports/Project Code Bias and Vulnerability Summary",
            },
            {
              id: "3",
              name: "Unstructured Data Bias Test",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.UNSTRUCTURED_DATA_BIAS_TEST, localUserObj?.permissions) : false,
              route: "/complianceReports/Unstructured Data Bias Test",
            },
            {
              id: "4",
              name: "Term Comparison Analysis",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.TERM_COMPARISON_ANALYSIS, localUserObj?.permissions) : false,
              route: "/complianceReports/Term Comparison Analysis",
            },
          ],
        },
      ],
    },
    {
      id: 4,
      name: "Prediction Models",
      isActive:
        localStorage.getItem("selectedReport") === REPORT_TYPE.PREDICTION_MODELS
          ? true
          : false,
      data: [
        {
          id: 0,
          name: "Prediction Models",
          reports: [
            {
              id: "0",
              name: "Data Privacy Penalty Prediction",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.DATA_PRIVACY_PENALTY_PREDICTION_PM, localUserObj?.permissions) : false,
              route: "/complianceReports/Data Privacy Penalty Prediction",
            },
            {
              id: "1",
              name: "Regulation Prediction",
              disabled: localUserObj?.permissions ? checkPermissions(PERMISSION_CODE.REGULATION_PREDICTION, localUserObj?.permissions) : false,
              route: "/complianceReports/Regulation Prediction",
            },
          ],
        },
      ],
    },
  ]);

  // Methods
  const handleReportTypeClick = (reportType: any) => {
    localStorage.setItem("selectedReport", reportType.name);

    let reportTypesCpy = [...reportTypes];

    reportTypesCpy.forEach((report, i) => {
      if (report.id === reportType.id) {
        report.isActive = true;
      } else {
        report.isActive = false;
      }
    });

    setReportTypes(reportTypesCpy);
  };

  return (
    <div className="cm-reports pb-4">
      {/* Inlet */}
      {/* <h4 className="title-d">Compliant Test</h4> */}
      {/* Content */}
      <div className="d-flex mt-4 bg-white rounded-3 overflow-x-auto shadow-sm">
        <div className="report-list bg-cm-primary d-flex flex-column my-5">
          {reportTypes.map((reportType, i) => (
            <button
              className="btn text-start ps-4 cm-btn border-0 p-3 text-white border-top rounded-0 border-white position-relative"
              onClick={() => {
                handleReportTypeClick(reportType);
              }}
              key={i}
            >
              <span>{reportType.name}</span>
              {reportType.isActive && (
                <div className="cm-active-polygon position-absolute"></div>
              )}
            </button>
          ))}
        </div>
        <div className="report-content  ms-4 container-fluid">
          {reportTypes.map(
            (reportItem, i) =>
              reportItem.isActive &&
              reportItem?.data?.map((report, ri) => {
                return (
                  <div className="my-4" key={Math.random()}>
                    <h5 className="fw-bold fs-4 text-cm-active-primary1 mb-3  ">
                      {report.name}
                    </h5>
                    {/* <div className="border-top  border-primary"></div> */}
                    <div className="row">
                      {report?.reports?.map((rep: any, ri: any) => (
                        <div
                          className="col-md-4 cursor-pointer"
                          key={ri}
                          onClick={() => {
                            if (rep.disabled) {
                              navigate(rep.route + `?report=${reportItem.name}`);
                              saveAuditLog({
                                payload: {
                                  startTime: startTime.toISOString(),
                                  // endTime: startTime.toISOString(),
                                  // request: {},
                                  // response: {},
                                  // serviceName: "login",
                                  eventName: rep.route,
                                  url: rep.route,
                                  userId: localUserObj?.id
                                }
                              })
                            }
                          }}
                        >
                          <ul className="fs-6">

                            {!rep.disabled ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="overlay-example" {...props}>
                                    If you want to access this please contact info@capitoltunnels.ai
                                  </Tooltip>
                                }
                              >
                                <li className="text-cm-light-text list-disc  text-decoration-underline disabled-link">
                                  {" "}
                                  {rep.name}
                                </li>
                              </OverlayTrigger>
                            ) : (
                              <div className="d-flex">
                                <li className={`text-cm-light-text  text-decoration-underline ${!rep.disabled ? 'disabled' : null}`}>
                                  {" "}
                                  {rep.name}
                                </li>
                                {selectedReportName && selectedReportName.includes(rep.name) ? (
                                  <p className="ms-2">
                                    <BiCheckCircle className="text-success" />
                                  </p>
                                ) : null}
                              </div>

                            )}

                            {/* <div className="d-flex">
                              <li className={`text-cm-light-text  text-decoration-underline ${rep.disabled ? 'disabled' : null}`}>
                                {" "}
                                {rep.name}
                              </li>
                              {selectedReportName && selectedReportName.includes(rep.name) ? (
                                <p className="ms-2">
                                  <BiCheckCircle className="text-success" />
                                </p>
                              ) : null}
                            </div> */}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </div>
    </div>
  );
};

export default CompliantReports;
