// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import PropTypes from "prop-types";
import merge from "lodash/merge";
import ReactApexChart from "react-apexcharts";
import { useState } from "react";


// ----------------------------------------------------------------------

BiasAndVulnerabiltySummary.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartSerires: PropTypes.array,
  chartLables: PropTypes.array,
  // chartData: PropTypes.array,
};

export default function BiasAndVulnerabiltySummary({
  title,
  chartSerires,
  chartLables,
  ...other
}) {

  const [color, setColor] = useState("");

  const chartLabels = chartLables && chartLables.map((i) => i);

  const chartSeries = chartSerires && chartSerires.map((i) => Number(i));

  const chartOptions = merge({
    colors: ["#06b8b2", "#bd248f", "#315bbd", "#5e5d5c", "#b8544d", "#2f91b5", "#72b869", "#e6534c", "#ffa50a", "#fc6938"],
    labels: chartLabels,
    stroke: { colors: ["#c0c0c0"] },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    plotOptions: {
      pie: { pie: { labels: { show: true } } }
    },
    selection: {
      enabled: true
    },
    chart: {
      toolbar: {
        show: true,
        position: 'left',
        offsetX: 10,
        offsetY: 10,
        tools: {
          download: true,
        },
      },
    },
  });


  return (
    <div className="overflow-scroll" {...other}>
      <p className="chart-title text-near-black-accent">{title}</p>

      <ReactApexChart
        type="pie"
        series={chartSeries}
        options={chartOptions}
        height={250}
      />
    </div>
  );
}
