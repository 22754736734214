import "../dashboard.scss";
import committes from '../../../assets/dashboardImage/CommitteesInsights.png';
import webScrap from '../../../assets/dashboardImage/webScrap.png';
import congress from '../../../assets/dashboardImage/CongressDashboard.png';
import EuLegislative from '../../../assets/dashboardImage/EULegislativeTitleInsights.png';
import GlobalAILegislationPrivacy from '../../../assets/dashboardImage/globalAILDataPrivacy.png';
import GlobalLegislativePDFDataLibrary from '../../../assets/dashboardImage/pdfDatalibrary.png';
import USAAICyberSecurityDataPrivacy from '../../../assets/dashboardImage/USAAICyberSecurityDataPrivacy.png';
import GDPRPenalty from '../../../assets/dashboardImage/GDPRPenalty.png'
import EuLegislativeDashboard from '../../../assets/dashboardImage/EULegislativeDashboard.png';
import Top10Sponsors from '../../../assets/dashboardImage/Top10SponsorsDashboard.png';
import USADataWordcloud from '../../../assets/dashboardImage/USALegislativeDataWordcloudAnalytics.png';
import globalAILFoucusAreas from '../../../assets/dashboardImage/globalAILFoucusAreas.png';
import aiLegislationBillStatusUpdates from '../../../assets/dashboardImage/aiLegislationBillStatusUpdates.png'
import { corporateBigObject, govBigObject, govSmallObject } from '../../../constant/mockData';
import { corporateSmallObject } from '../../../constant/mockData';
import { USER_ROLE } from "../../../constant/AppConstant";
import { useEffect, useState } from "react";

type Props = {
    title?: any;
};

const DashboardCorporateUser = (props: Props) => {
    const userBasedRoute = localStorage.getItem("userBasedRoute");
    const [bigObject, serBigObject]: any = useState();
    const [smallObject, setSmallObject]: any = useState();
    const [title, setTitle]: any = useState(USER_ROLE.CORPORATE)

    useEffect(() => {
        if (userBasedRoute === USER_ROLE.CORPORATE) {
            serBigObject(corporateBigObject)
            setSmallObject(corporateSmallObject)
        } else if (userBasedRoute === USER_ROLE.GOVERNMENT) {
            serBigObject(govBigObject)
            setSmallObject(govSmallObject)
        } else if (userBasedRoute === USER_ROLE.INNOVATOR) {
            serBigObject(corporateBigObject)
        } else if (userBasedRoute === USER_ROLE.NGO) {
            serBigObject(corporateBigObject)
            setSmallObject(corporateSmallObject)
        }
    }, [])

    useEffect(() => {
        if (userBasedRoute === USER_ROLE.ADMIN || userBasedRoute === USER_ROLE.BETA_TEST_USER) {
            if (props.title === USER_ROLE.CORPORATE) {
                serBigObject(corporateBigObject)
                setSmallObject(corporateSmallObject)
            } else if (props.title === USER_ROLE.GOVERNMENT) {
                serBigObject(govBigObject)
                setSmallObject(govSmallObject)
            } else if (props.title === USER_ROLE.INNOVATOR) {
                serBigObject(corporateBigObject)
                setSmallObject(govSmallObject)
            } else if (props.title === USER_ROLE.NGO) {
                serBigObject(corporateBigObject)
                setSmallObject(corporateSmallObject)
            }
        }
    }, [])

    useEffect(() => {
        if (props.title) {
            setTitle(props.title);
        }
    }, [title])


    return (
        <>
            <div className="mt-4">
                {/* Inlet */}
                {/* {userBasedRoute !== USER_ROLE.ADMIN ? (
                    <div className="d-flex justify-content-between flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row">
                        <h4 className="title-d">{userBasedRoute && userBasedRoute} Dashboard</h4>
                        <div className="d-flex align-items-center justify-content-start justify-content-md-end"></div>
                    </div>
                ) : null} */}
                {/* Content */}
                <div className="d-flex row px-3 cursor-pointer justify-content-around col-lg-12">
                    {bigObject && bigObject.map((item: any, index: any) => {
                        return (
                            <div key={index.toString()} className="d-flex flex-column col-lg-5 col-md-4 col-sm-12 bg-cm-light-primary1 rounded-3 mx-2 px-3 mt-3" onClick={() => {
                                window.open(item.link, '_blank');
                            }}>
                                <p className="chart-title text-dark text-start mt-2">
                                    {item.title}
                                </p>
                                <img
                                    src={item.image === "GlobalAILegislationPrivacy" ? GlobalAILegislationPrivacy
                                        : item.image === "GlobalLegislativePDFDataLibrary" ? GlobalLegislativePDFDataLibrary
                                            : item.image === "USAAICyberSecurityDataPrivacy" ? USAAICyberSecurityDataPrivacy
                                                : item.image === "GDPRPenalty" ? GDPRPenalty
                                                    : item.image === "globalAILFoucusAreas" ? globalAILFoucusAreas
                                                        : item.image === "aiLegislationBillStatusUpdates" ? aiLegislationBillStatusUpdates
                                                            : ""}
                                    className="mb-3 rounded px-3"
                                />
                            </div>
                        )
                    })}
                </div>
                <div className="d-flex row px-3 cursor-pointer justify-content-around col-lg-12">
                    {/* flex-column flex-sm-row */}
                    {smallObject && smallObject.map((item: any, index: any) => {
                        return (
                            <div key={index.toString()} className="d-flex flex-column col-lg-3 col-md-3 col-sm-12 bg-cm-light-primary1 rounded-3 mx-2 px-3 mt-3" onClick={() => {
                                window.open(item.link, '_blank');
                            }}>
                                <p className="chart-title text-dark text-start mt-2">
                                    {item.title}
                                </p>
                                <img
                                    src={item.image === "webScrap" ? webScrap
                                        : item.image === "committes" ? committes
                                            : item.image === "congress" ? congress
                                                : item.image === "EuLegislative" ? EuLegislative
                                                    : item.image === "EuLegislativeDashboard" ? EuLegislativeDashboard
                                                        : item.image === "Top10Sponsors" ? Top10Sponsors
                                                            : item.image === "USADataWordcloud" ? USADataWordcloud
                                                                : ""}
                                    className="mb-3 rounded px-3"
                                />
                            </div>
                        )
                    })}
                </div>
                <footer
                    style={{
                        padding: "20px",
                        textAlign: "center",
                        borderTop: "2px solid #EEF3FF",
                        // marginTop: "5%",
                    }}
                >
                    <p style={{ fontSize: "1.0rem", color: "#4D4D4D" }}>
                        Capitol Tunnels.ai &#169; 2023
                    </p>
                </footer>
            </div>
        </>
    );
};
export default DashboardCorporateUser;
