import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Select, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import {
  ACTION_TYPE, REPORT_NAME,
} from "../constant/reportType";
import { CODE_CYBER_SEC_VUL_ANALYSIS_COLUMN, CODE_AI_BIAS_ANALYSIS_COLUMN, AI_ALGORITHM_ACCURACY_RELIABILITY_TEST_COLUMN, FEATURE_DOMINANCE_COLUMN } from "../constant/AppConstant";
import {
  getAllStatesTotalRecords,
} from "../redux-toolkit/slices/statesSlice";
import { getLocalStorageData } from "../utils/LocalStorageService";
import CyberSecAiSheildData from "../pages/reports/CyberSecAiSheildData";
import { getAiAlgAccReliabilityTestDetails, getCodeAIBiasAnalysisDetails, getCodeAIBiasAnalysisFilePath, getFeatureDominanceDetails, getVulnerabiltyDetails } from "../redux-toolkit/slices/vulnerabiltySlice";
import { Excel } from "antd-table-saveas-excel";
import { useGeneratePdfMutation } from "../redux-toolkit/services/fastApiService";

const { Option } = Select;
type Props = {};

const CyberSecAIShield = (props: Props) => {
  const params = useParams();
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // States
  const [show, setShow] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [reportName, setReportName]: any = useState();
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [fileName, setFileName]: any = useState();
  const [showRiskBtn, setShowRiskBtn]: any = useState();

  //columns
  const [codeCyberSecAnalysisColumn, setcodeCyberSecAnalysisColumn]: any = useState(CODE_CYBER_SEC_VUL_ANALYSIS_COLUMN);
  const [codeAiBiasAnalysisColumn, setCodeAiBiasAnalysisColumn]: any = useState(CODE_AI_BIAS_ANALYSIS_COLUMN);
  const [aiAlgAccReliabilityTestColumn, setAiAlgAccReliabilityTestColumn]: any = useState(AI_ALGORITHM_ACCURACY_RELIABILITY_TEST_COLUMN);
  const [featureDominanceColumn, setFeatureDominanceColumn]: any = useState(FEATURE_DOMINANCE_COLUMN);

  //datasource
  const [datasource, setdatasource]: any = useState([]);
  const [codeAiBiasAnalysisDataSource, setCodeAiBiasAnalysisDataSource]: any = useState([]);
  const [aiAlgAccReliabilityTestDataSource, setAiAlgAccReliabilityTestDataSource]: any = useState([]);
  const [featureDominanceDataSource, setFeatureDominanceDataSource]: any = useState([]);

  //selector
  const allStatesTotalRecords: any = useAppSelector(getAllStatesTotalRecords);
  const getVulnerabiltyDetailsData: any = useAppSelector(getVulnerabiltyDetails);
  const getCodeAiBiasAnalysisDetailsData: any = useAppSelector(getCodeAIBiasAnalysisDetails);
  const codeAIBiasAnalysisFilePath: any = useAppSelector(getCodeAIBiasAnalysisFilePath);
  const getAiAlgAccReliabilityTestData: any = useAppSelector(getAiAlgAccReliabilityTestDetails);
  const getFeatureDominanceData: any = useAppSelector(getFeatureDominanceDetails);

  //mutation
  const [generatePdf, generatePdfResponse] = useGeneratePdfMutation();

  useEffect(() => {

    switch (params.reportType) {
      case REPORT_NAME.CODE_AI_BIAS_ANALYSIS:
        setReportName(REPORT_NAME.CODE_AI_BIAS_ANALYSIS);
        break;
      case REPORT_NAME.CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS:
        setReportName(REPORT_NAME.CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS);
        break;
      case REPORT_NAME.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST:
        setReportName(REPORT_NAME.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST);
        break;
      case REPORT_NAME.FEATURE_DOMINANCE:
        setReportName(REPORT_NAME.FEATURE_DOMINANCE);
        break;
      default:
    }
  }, []);

  const paginationChange = (page: number, offset: number) => {
    if (reportName === "state") {
    }
    setCurrentPage(page);
    setOffset(offset);
  };

  const handleShow = () => setShow(true);

  // navigate............
  const handleBack = () => {
    navigate("/complianceReports");
  };

  useEffect(() => {
    if (getVulnerabiltyDetailsData) {
      setdatasource(getVulnerabiltyDetailsData);
    }
  }, [getVulnerabiltyDetailsData]);

  useEffect(() => {
    if (getCodeAiBiasAnalysisDetailsData) {
      setShowRiskBtn(true);
      setCodeAiBiasAnalysisDataSource(getCodeAiBiasAnalysisDetailsData);
    }
  }, [getCodeAiBiasAnalysisDetailsData]);

  // useEffect(() => {
  //   if (codeAIBiasAnalysisFilePath) {
  //     console.log(codeAIBiasAnalysisFilePath)

  //   }
  // }, [codeAIBiasAnalysisFilePath]);

  useEffect(() => {
    if (getAiAlgAccReliabilityTestData) {

      let temp: any = [];
      getAiAlgAccReliabilityTestData?.forEach((element: any, i: any) => {

        element?.Results?.map((result: any) => {
          let obj: any = {};
          obj.algorithmName = element["Algorithm Name"];
          obj.dataset = element["Dataset"];
          obj.variable = result["Variable"];
          obj.accuracy = (result["Accuracy"]).toFixed(2);
          obj.reliability = (result["Reliability"]).toFixed(2);
          temp.push(obj)
        })
      });

      setAiAlgAccReliabilityTestDataSource(temp);
    }
  }, [getAiAlgAccReliabilityTestData]);

  useEffect(() => {
    if (getFeatureDominanceData) {
      let featureDominanceArr = Object.entries(getFeatureDominanceData);

      let temp: any = [];

      featureDominanceArr?.forEach((element: any, i: any) => {

        let obj: any = {};

        Object.keys(element).map(key => {
          obj.feature = element[0];
          obj.value = ((element[1]) * 100).toFixed(2) + "%";
        });
        temp.push(obj)
      });
      setFeatureDominanceDataSource(temp);
    }
  }, [getFeatureDominanceData]);

  const handleRiskClick = () => {
    const payload = {
      file_path: codeAIBiasAnalysisFilePath
    }
    generatePdf(payload)
  }

  const handleExportClick = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === REPORT_NAME.CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS) {
      fileName = REPORT_NAME.CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS;
      columns = codeCyberSecAnalysisColumn;
      dataSource = datasource;
    } else if (reportName === REPORT_NAME.CODE_AI_BIAS_ANALYSIS) {
      fileName = REPORT_NAME.CODE_AI_BIAS_ANALYSIS
      columns = codeAiBiasAnalysisColumn;
      dataSource = codeAiBiasAnalysisDataSource;
    } else if (reportName === REPORT_NAME.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST) {
      fileName = REPORT_NAME.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST
      columns = aiAlgAccReliabilityTestColumn;
      dataSource = aiAlgAccReliabilityTestDataSource;
    } else if (reportName === REPORT_NAME.FEATURE_DOMINANCE) {
      fileName = REPORT_NAME.FEATURE_DOMINANCE
      columns = featureDominanceColumn;
      dataSource = featureDominanceDataSource
    }
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true
      })
      .saveAs(`${fileName}.xlsx`);
  };

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div onClick={handleShow} className="d-flex px-2 align-items-center mt-1">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0 text-nowrap"
              onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
            >
              {/* <BiPlus /> */}
              New Request
            </Button>
          </div>
          {show && (
            <CyberSecAiSheildData
              show={show}
              setShow={setShow}
              reportName={params.reportType}
              reportNameCode={reportName}
              setdatasource={setdatasource}
              setCodeAiBiasAnalysisDataSource={setCodeAiBiasAnalysisDataSource}
              setAiAlgAccReliabilityTestDataSource={setAiAlgAccReliabilityTestDataSource}
              setFileName={setFileName}
              setShowRiskBtn={setShowRiskBtn}
            />
          )}
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
          <div className="primary mt-2 mt-md-0 d-flex justify-content-end w-100 mx-2">
            {showRiskBtn && (
              <Button
                variant="primary"
                className="d-flex align-items-center mx-2"
                onClick={handleRiskClick}
              >
                Risk Assessment
              </Button>
            )}
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleExportClick}
            >
              Export
            </Button>
          </div>
        </div>
        {fileName && (
          <p className="mt-2 mx-2 fw-bold">{fileName}</p>
        )
        }
      </div>
      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          rowKey={"id"}
          pagination={
            reportName === "state"
              ? {
                total: allStatesTotalRecords && allStatesTotalRecords,
                responsive: true,
                current: currentpage,
                showSizeChanger: true,
                pageSizeOptions: [15, 20, 30],
                onChange: (page, offset) => {
                  paginationChange(page, offset);
                },
                pageSize: offset,
              }
              : {
                responsive: true,
              }
          }
          columns={
            reportName === REPORT_NAME.CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS
              ? codeCyberSecAnalysisColumn
              : reportName === REPORT_NAME.CODE_AI_BIAS_ANALYSIS
                ? codeAiBiasAnalysisColumn
                : reportName === REPORT_NAME.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST
                  ? aiAlgAccReliabilityTestColumn
                  : reportName === REPORT_NAME.FEATURE_DOMINANCE
                    ? featureDominanceColumn
                    : ""
          }
          dataSource={
            reportName === REPORT_NAME.CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS
              ? datasource
              : reportName === REPORT_NAME.CODE_AI_BIAS_ANALYSIS
                ? codeAiBiasAnalysisDataSource
                : reportName === REPORT_NAME.AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST
                  ? aiAlgAccReliabilityTestDataSource
                  : reportName === REPORT_NAME.FEATURE_DOMINANCE
                    ? featureDominanceDataSource
                    : ""
          }
        />
      </div>
    </div>
  );
};
export default CyberSecAIShield;
