export const PERMISSION = {
    DASHBOARD_ALL: ['SA1', 'M11', 'MS1', 'SPA1'],
    ADMIN1: ['Dashboard', 'Master', 'Compliance Test', 'AI Governance', 'PrivacyGuard Pro', 'CyberSecAI Shield', 'BiasMitigate Pro', 'Prediction Models'],

    BETA_TEST_USER: ['CAI_DASH', 'CAI_PGP_R1', 'CAI_PGP_R4', 'CAI_BMP_R1'],

    Admin: ['CAI_DASH', 'CAI_CTA', 'CAI_DSM', 'CAI_COMM', 'CAI_PGP', 'CAI_CSAIS', 'CAI_BMP', 'CAI_PM', 'CAI_DG', 'CAI_AAA', 'CAI_RM',
        'CAI_DSM_R1', 'CAI_DSM_R2', 'CAI_DSM_R3', 'CAI_DSM_R4', 'CAI_DSM_R5', 'CAI_DSM_R6', 'CAI_DSM_R7', 'CAI_DSM_R8', 'CAI_UM_R1', 'CAI_UM_R2',
        'CAI_UM_R3', 'CAI_UM_R4', 'CAI_COMM_R1', 'CAI_COMM_R2', 'CAI_PGP_R1', 'CAI_PGP_R2', 'CAI_PGP_R3', 'CAI_PGP_R4', 'CAI_PGP_R5',
        'CAI_CSAIS_R1', 'CAI_CSAIS_R2', 'CAI_CSAIS_R3', 'CAI_CSAIS_R4', 'CAI_BMP_R1', 'CAI_BMP_R2', 'CAI_BMP_R3', 'CAI_BMP_R4', 'CAI_PM_R1',
        'CAI_PM_R2', 'CAI_DG_R1', 'CAI_AAA_R1', 'CAI_RM_R1'],

    MENU: ['CAI_DASH', 'CAI_CTA', 'CAI_MA', 'CAI_AIGA'],

    SUB_MENU: ['CAI_DSM', 'CAI_UM', 'CAI_COMM', 'CAI_PGP', 'CAI_CSAIS', 'CAI_BMP', 'CAI_PM', 'CAI_DG', 'CAI_AAA', 'CAI_RM'],

    SUB_MENU_REPORT: ['CAI_DSM_R1', 'CAI_DSM_R2', 'CAI_DSM_R3', 'CAI_DSM_R4', 'CAI_DSM_R5', 'CAI_DSM_R6', 'CAI_DSM_R7', 'CAI_DSM_R8',
        'CAI_UM_R1', 'CAI_UM_R2', 'CAI_UM_R3', 'CAI_UM_R4',
        'CAI_COMM_R1', 'CAI_COMM_R2',
        'CAI_PGP_R1', 'CAI_PGP_R2', 'CAI_PGP_R3', 'CAI_PGP_R4', 'CAI_PGP_R5',
        'CAI_CSAIS_R1', 'CAI_CSAIS_R2', 'CAI_CSAIS_R3', 'CAI_CSAIS_R4',
        'CAI_BMP_R1', 'CAI_BMP_R2', 'CAI_BMP_R3', 'CAI_BMP_R4',
        'CAI_PM_R1', 'CAI_PM_R2',
        'CAI_DG_R1',
        'CAI_AAA_R1',
        'CAI_RM_R1']

};

export const PERMISSION_CODE = {
    DASHBOARD : ['CAI_DASH'],
    COMPLIANCE_TEST_ACCESS : ['CAI_CTA'],
    MASTER_ACCESS : ['CAI_MA'],
    AI_GOVERNANCE_ACCESS : ['CAI_AIGA'],

    DATA_SOURCE_MANAGEMENT : [['CAI_DSM']],
    USER_MASTER : ['CAI_UM'],
    COMMON : ['CAI_COMM'],

    PRIVACYGUARD_PRO : ['CAI_PGP'],
    CYBERSECAI_SHIELD : ['CAI_CSAIS'],
    BIASMITIGATE_PRO : ['CAI_BMP'],
    PREDICTION_MODELS : ['CAI_PM'],

    DATA_GOVERNANCE : ['CAI_DG'],
    ASSESSMENT_AND_AUDITS : ['CAI_AAA'],
    RISK_MANAGEMENT : ['CAI_RM'],

    VALUE_CHAIN_CATEGORY : ['CAI_DSM_R1'],
    VALUE_CHAIN : ['CAI_DSM_R2'],
    DATA_PIPELINE_CATEGORY : ['CAI_DSM_R3'],
    DATA_PIPELINE : ['CAI_DSM_R4'],
    TERM_SET : ['CAI_DSM_R5'],
    TERM : ['CAI_DSM_R6'],
    DATA_RECORDS : ['CAI_DSM_R7'],
    VALUE_CHAIN_PARAGRAPH_EXTRACT : ['CAI_DSM_R8'],

    USER_GROUP : ['CAI_UM_R1'],
    SUBSCRIBER : ['CAI_UM_R2'],//userrole
    USER : ['CAI_UM_R3'],
    USER_ACTIVITY : ['CAI_UM_R4'],

    COUNTRY : ['CAI_COMM_R1'],
    STATE : ['CAI_COMM_R2'],

    DATA_PRIVACY_COMPLIANCE_TEST : ['CAI_PGP_R1'],
    DATA_PRIVACY_PENALTY_PREDICTION : ['CAI_PGP_R2'],
    SENSITIVE_AND_BIAS_DATA_IDENTIFICATION : ['CAI_PGP_R3'],
    AI_REGULATION_COMPLIANCE_TEST : ['CAI_PGP_R4'],
    EMPLOYMENT_BIAS : ['CAI_PGP_R5'],

    CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS : ['CAI_CSAIS_R1'],
    AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST : ['CAI_CSAIS_R2'],
    CODE_AI_BIAS_ANALYSIS : ['CAI_CSAIS_R3'],
    FEATURE_DOMINANCE : ['CAI_CSAIS_R4'],

    DATA_SET_BIAS_TEST : ['CAI_BMP_R1'],
    FAIRNESS_METRICS_FOR_MACHINE_LEARNING : ['CAI_BMP_R2'],
    PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY : ['CAI_BMP_R3'],
    UNSTRUCTURED_DATA_BIAS_TEST : ['CAI_BMP_R4'],
    TERM_COMPARISON_ANALYSIS : ['CAI_BMP_R5'],

    DATA_PRIVACY_PENALTY_PREDICTION_PM : ['CAI_PM_R1'],
    REGULATION_PREDICTION : ['CAI_PM_R2'],

    DATA_LINEAGE : ['CAI_DG_R1'],

    ASSESSMENT_AND_AUDITS_R : ['CAI_AAA_R1'],

    RISK_MANAGEMENT_R : ['CAI_RM_R1'],

}


