import { useLocation } from "react-router-dom";
import { REPORT_TYPE } from "../../constant/reportType";
import UserMaster from "../../component/UserMaster";
import DataSourceManagementMaster from "../../component/DataSourceManagementMaster";
import Common from "../../component/Common";

type Props = {};

const Reports = (props: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const report = searchParams.get("report") !== null ? searchParams.get("report") : localStorage.getItem("selectedReport")

  if (report == REPORT_TYPE.DATA_SOURCE_MANAGEMENT) {
    return (
      <DataSourceManagementMaster />
    )
  } else if (report == REPORT_TYPE.USER_MASTER) {
    return (
      <UserMaster />
    )
  } else if (report == REPORT_TYPE.COMMON) {
    return (
      <Common />
    )
  }
  // else if (report == REPORT_TYPE.PRIVACYGUARD_PRO) {
  //   return (
  //     <PrivacyGuardPro />
  //   )
  // } else if(report == REPORT_TYPE.CYBERSECAI_SHIELD) {
  //   return (
  //     <CyberSecAIShield />
  //   )
  // } else if(report == REPORT_TYPE.BIASMITIGATE_PRO) {
  //   return (
  //     <BiasMitigatePro />
  //   )
  // }
  else {
    return (
      <div>Something went wrong</div>
    )
  }

};
export default Reports;
