import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Select, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import {
  ACTION_TYPE, REPORT_NAME,
} from "../constant/reportType";
import { DATA_SET_BIAS_TEST_COLUMN, FAIRNESS_METRICS_FOR_MACHINE_LEARNING_COLUMN, PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_COLUMN, PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_DATSOURCE, SENSITIVE_BIAS_DATA_IDENTIFICATION_COLUMN, TERM_COMPARISON_ANALYSIS_COLUMN, USER_ACTIVITY_COLUMN } from "../constant/AppConstant";
import {
  getAllStatesTotalRecords,
} from "../redux-toolkit/slices/statesSlice";
import { getLocalStorageData } from "../utils/LocalStorageService";
import BiasMitigateProData from "../pages/reports/BiasMitigateProData";
import { getDataSetBiasTestDetails, getFairnessMetricsDetails } from "../redux-toolkit/slices/fastApiSlice";
import BiasAndVulnerabiltySummary from "./BiasAndVulnerabiltySummary";
import { BiPieChart } from "react-icons/bi";
import { Excel } from "antd-table-saveas-excel";
import { getTermComaprisonAnalysisDetails, getTermComaprisonAnalysisWithTermFileDetails } from "../redux-toolkit/slices/textMatcherSlice";

const { Option } = Select;
type Props = {};

const BiasMitigatePro = (props: Props) => {
  const params = useParams();
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // States
  const [show, setShow] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [reportName, setReportName]: any = useState();
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [fileName, setFileName]: any = useState();
  const [termFileName, setTermFileName]: any = useState();

  // datasource
  const [dataSetBiasTestDataSource, setDataSetBiasTestDataSource]: any = useState([]);
  const [fairnessMetricsDataSource, setFairnessMetricsDataSource]: any = useState([]);
  const [projectCodeBiasAndVulSummaryDataSource, setProjectCodeBiasAndVulSummaryDataSource]: any = useState(PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_DATSOURCE);
  const [termComparisonAnalysisDataSource, setTermComparisonAnalysisDataSource]: any = useState([]);

  const [pieData, setPieData]: any = useState([]);

  //columns
  const [dataSetBiasTestColumn, setDataSetBiasTestColumn]: any = useState(DATA_SET_BIAS_TEST_COLUMN);
  const [fairnessMetricsColumn, setFairnessMetricsColumn]: any = useState(FAIRNESS_METRICS_FOR_MACHINE_LEARNING_COLUMN);
  const [sensitiveBiasDataIdnColumn, setSensitiveBiasDataIdnColumn]: any = useState(SENSITIVE_BIAS_DATA_IDENTIFICATION_COLUMN);
  const [projectCodeBiasAndVulSummarycolumn, setProjectCodeBiasAndVulSummarycolumn]: any = useState(PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_COLUMN);

  //selector
  const allStatesTotalRecords: any = useAppSelector(getAllStatesTotalRecords);
  const getDataSetBiasTestData: any = useAppSelector(getDataSetBiasTestDetails);
  const getFairnessMetricsData: any = useAppSelector(getFairnessMetricsDetails);
  const getTermComaprisonAnalysisData: any = useAppSelector(getTermComaprisonAnalysisDetails);
  const getTermComaprisonAnalysisDatawithTermFile: any = useAppSelector(getTermComaprisonAnalysisWithTermFileDetails);

  // useEffect(() => {
  //   const dataDetails: any = data;
  //   let tempPie: any = [];
  //   let tempTableData: any = [];
  //   dataDetails[1].map((element: any) => {
  //     tempPie.push(element)
  //   })
  //   console.log(tempPie, 'tempPie')
  //   setPieData(tempPie);
  // }, [])

  // //chart
  // const chartLabels = projectCodeBiasAndVulSummaryDataSource &&
  //   projectCodeBiasAndVulSummaryDataSource.map((i: any) => i.type);
  // const chartSeries = projectCodeBiasAndVulSummaryDataSource &&
  //   projectCodeBiasAndVulSummaryDataSource.map((i: any) => Number(i.count));

  useEffect(() => {
    switch (params.reportType) {
      case REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION:
        setReportName(REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION)
        break;
      case REPORT_NAME.DATA_SET_BIAS_TEST:
        setReportName(REPORT_NAME.DATA_SET_BIAS_TEST)
        // setReportName("state");
        // setIsSelect(true);
        // getAllCountry({});
        // getAllState({ page: currentpage - 1, offset: offset, orderBy: "asc" });
        break;
      case REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING:
        setReportName(REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING)
        break;
      case REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY:
        setReportName(REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY)
        break;
      case REPORT_NAME.TERM_COMPARISON_ANALYSIS:
        setReportName(REPORT_NAME.TERM_COMPARISON_ANALYSIS)
        break;
      default:
    }
  }, []);

  const paginationChange = (page: number, offset: number) => {
    if (reportName === "state") {

    }
    setCurrentPage(page);
    setOffset(offset);
  };

  const handleShow = () => setShow(true);

  // navigate............
  const handleBack = () => {
    navigate("/complianceReports");
  };

  useEffect(() => {
    if (getDataSetBiasTestData) {
      let dataSetBiasTestArr = Object.entries(getDataSetBiasTestData);
      let temp: any = [];

      dataSetBiasTestArr?.forEach((element: any, i: any) => {
        let obj: any = {};
        Object.keys(element).map(key => {
          obj.column = element[0];
          obj.biasAnalysis = element[1]["Bias Analysis"];
          obj.maxPercentage = element[1]["Max Percentage"];
          obj.maxValue = element[1]["Max Value"];
          obj.fairnessScore = element[1]["Fairness Score"];
          obj.explicitFairnessFormula = element[1]["Explicit Fairness Formula"];
        });
        temp.push(obj)
      });

      setDataSetBiasTestDataSource(temp);
    }
  }, [getDataSetBiasTestData]);

  function replaceNaN(str: any) {
    return str.replace(/NaN/g, 'null'.toString());
  }


  useEffect(() => {
    if (getFairnessMetricsData) {

      const json = JSON.parse(replaceNaN(getFairnessMetricsData));


      let fairnessMetrics = Object.entries(json);

      let temp: any = [];

      fairnessMetrics?.forEach((element: any, i: any) => {

        // let obj: any = {};
        // Object.keys(element).map(key => {
        //   obj.metric = element[0];
        //   obj.value = element[1];
        // });
        // temp.push(obj)
        let obj: any = {};
        Object.keys(element).map(key => {
          obj.metric = element[0];
          obj.FDR = element[1]["False Discovery Rate (FDR)"] === null ? "-" : element[1]["False Discovery Rate (FDR)"];
          obj.FDRAnalysis = element[1]["False Discovery Rate Analysis"] === "" ? "-" : element[1]["False Discovery Rate Analysis"];
          obj.FNR = element[1]["False Negative Rate (FNR)"] === null ? "-" : element[1]["False Negative Rate (FNR)"];
          obj.FNRAnalysis = element[1]["False Negative Rate Analysis"] === "" ? "-" : element[1]["False Negative Rate Analysis"];
          obj.FPR = element[1]["False Positive Rate (FPR)"] === null ? "-" : element[1]["False Positive Rate (FPR)"];
          obj.FPRAnalysis = element[1]["False Positive Rate Analysis"] === "" ? "-" : element[1]["False Positive Rate Analysis"];
          obj.NPV = element[1]["Negative Predictive Value (NPV)"] === null ? "-" : element[1]["Negative Predictive Value (NPV)"];
          obj.NPVAnalysis = element[1]["Negative Predictive Value Analysis"] === "" ? "-" : element[1]["Negative Predictive Value Analysis"];
          obj.PPV = element[1]["Positive Predictive Value (PPV)"] === null ? "-" : element[1]["Positive Predictive Value (PPV)"];
          obj.PPVAnalysis = element[1]["Positive Predictive Value Analysis"] === "" ? "-" : element[1]["Positive Predictive Value Analysis"];
          obj.TPR = element[1]["True Positive Rate (TPR)"] === null ? "-" : element[1]["True Positive Rate (TPR)"];
          obj.TPRAnalysis = element[1]["True Positive Rate Analysis"] === "" ? "-" : element[1]["True Positive Rate Analysis"];
        });
        temp.push(obj)
      });

      setFairnessMetricsDataSource(temp);
    }
  }, [getFairnessMetricsData]);

  useEffect(() => {
    if (getTermComaprisonAnalysisData) {
      let termComparisonAnalysisArr = Object.entries(getTermComaprisonAnalysisData);

      const resultArray = [];

      for (const item of termComparisonAnalysisArr) {
        const fileName = item[0];
        const topicsObject: any = item[1];

        for (const [keyTerm, frequency] of Object.entries(topicsObject)) {
          resultArray.push({
            fileName,
            keyTerm,
            frequency
          });
        }
      }

      let sortedArray: any = [];
      sortedArray = [...resultArray].sort(
        (a, b) => a.keyTerm > b.keyTerm ? 1 : -1
      );

      setTermComparisonAnalysisDataSource(sortedArray);
    }
  }, [getTermComaprisonAnalysisData]);

  useEffect(() => {
    if (getTermComaprisonAnalysisDatawithTermFile) {
      const outputList = [];

      for (const fileName in getTermComaprisonAnalysisDatawithTermFile) {
        const fileData = getTermComaprisonAnalysisDatawithTermFile[fileName];

        for (const keyGroup in fileData) {
          const keyTerms = fileData[keyGroup];

          for (const keyTerm in keyTerms) {
            const frequency = keyTerms[keyTerm];

            const outputItem = {
              fileName,
              keyGroup,
              keyTerm,
              frequency
            };

            outputList.push(outputItem);
          }
        }
      }

      let sortedArray = [...outputList].sort((a, b) => {
        if (a.keyGroup !== b.keyGroup) {
          return a.keyGroup.localeCompare(b.keyGroup); // Sort by keyGroup first
        } else {
          return a.keyTerm.localeCompare(b.keyTerm); // If keyGroup is the same, then sort by keyTerm
        }
      });

      setTermComparisonAnalysisDataSource(sortedArray);
    }
  }, [getTermComaprisonAnalysisDatawithTermFile]);


  useEffect(() => {
    if (projectCodeBiasAndVulSummaryDataSource) {

      const desiredFormat: any = {};

      // this is for taking out the piegraph data
      for (const object of projectCodeBiasAndVulSummaryDataSource) {
        const fileName = object.FileName;
        const type = object.Type;
        const count = object.Count;

        if (!desiredFormat[fileName]) {
          desiredFormat[fileName] = {
            fileName,
            types: [],
            count: []
          };
        }

        desiredFormat[fileName].types.push(type);
        desiredFormat[fileName].count.push(count);
      }

      let desiredFormatArr = Object.entries(desiredFormat);

      let tempPieData: any = [];
      desiredFormatArr.forEach((element: any) => {
        tempPieData.push(element[1])
      });
      setPieData(tempPieData);
      // console.log(tempPieData,'tempPieData')

      let tempDataSource: any = [];

      projectCodeBiasAndVulSummaryDataSource.forEach((element: any) => {
        let obj: any = {};
        Object.keys(element).map(key => {
          obj.type = element["Type"];
          obj.sensitivity_score = element["Sensitivity Score"];
          obj.fileName = element["FileName"];
          obj.count = element["Count"];
        });
        tempDataSource.push(obj);
      });

      setProjectCodeBiasAndVulSummaryDataSource(tempDataSource);
    }
  }, [])

  const handleExportClick = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === REPORT_NAME.DATA_SET_BIAS_TEST) {
      fileName = REPORT_NAME.DATA_SET_BIAS_TEST;
      columns = dataSetBiasTestColumn;
      dataSource = dataSetBiasTestDataSource;
    } else if (reportName === REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING) {
      fileName = REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING
      columns = fairnessMetricsColumn;
      dataSource = fairnessMetricsDataSource;
    } else if (reportName === REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY) {
      fileName = REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY
      columns = projectCodeBiasAndVulSummarycolumn;
      dataSource = projectCodeBiasAndVulSummaryDataSource;
    } else if (reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
      fileName = REPORT_NAME.TERM_COMPARISON_ANALYSIS;
      columns = TERM_COMPARISON_ANALYSIS_COLUMN;
      dataSource = termComparisonAnalysisDataSource;
    }
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true
      })
      .saveAs(`${fileName}.xlsx`);
  };

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div onClick={handleShow} className="d-flex px-2 align-items-center mt-1">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0 text-nowrap"
              onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
            >
              {/* <BiPlus /> */}
              New Request
            </Button>
          </div>
          {show && (
            <BiasMitigateProData
              show={show}
              setShow={setShow}
              reportName={params.reportType}
              reportNameCode={reportName}
              setDataSetBiasTestDataSource={setDataSetBiasTestDataSource}
              setFairnessMetricsDataSource={setFairnessMetricsDataSource}
              setFileName={setFileName}
              setTermFileName={setTermFileName}
            />
          )}
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
          <div className="primary mt-2 mt-md-0 d-flex justify-content-end w-100 mx-2">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleExportClick}
            >
              Export
            </Button>
          </div>
        </div>
        {fileName && (
          <p className="mt-2 mx-2 fw-bold">{fileName}</p>
        )
        }
      </div>
      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          rowKey={"id"}
          pagination={
            reportName === "state"
              ? {
                total: allStatesTotalRecords && allStatesTotalRecords,
                responsive: true,
                current: currentpage,
                showSizeChanger: true,
                pageSizeOptions: [15, 20, 30],
                onChange: (page, offset) => {
                  paginationChange(page, offset);
                },
                pageSize: offset,
              }
              : {
                responsive: true,
              }
          }
          columns={
            reportName === REPORT_NAME.DATA_SET_BIAS_TEST
              ? dataSetBiasTestColumn
              : reportName === REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING
                ? fairnessMetricsColumn
                : reportName === REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION
                  ? sensitiveBiasDataIdnColumn
                  : reportName === REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY
                    ? projectCodeBiasAndVulSummarycolumn
                    : reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS
                      ? TERM_COMPARISON_ANALYSIS_COLUMN
                      : ""
          }
          dataSource={
            reportName === REPORT_NAME.DATA_SET_BIAS_TEST
              ? dataSetBiasTestDataSource
              : reportName === REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING
                ? fairnessMetricsDataSource
                : reportName === REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY
                  ? projectCodeBiasAndVulSummaryDataSource
                  : reportName === REPORT_NAME.TERM_COMPARISON_ANALYSIS
                    ? termComparisonAnalysisDataSource
                    : ""
          }
        />
      </div>
      {reportName === REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY ? (
        (pieData.map((element: any) => {
          return (
            <div className="">
              {/* <p className="fw-bolder text-cm-active-primary1">{REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY}</p> */}
              <div className="shadow-lg p-3 mb-5 bg-body rounded overflow-scroll">
                <BiasAndVulnerabiltySummary title={element.fileName} chartSerires={element.count} chartLables={element.types} />
              </div>
            </div>
          )
        }))
      ) : null}
    </div>
  );
};
export default BiasMitigatePro;
