import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { dataServiceBaseUrl, dataService } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const companyService = createApi({
  reducerPath: "companyService",
  baseQuery: fetchBaseQuery({ baseUrl: dataServiceBaseUrl }),
  tagTypes: ["Company"],
  endpoints: (builder) => ({
    getAllCompanies: builder.mutation({
      query(body) {
        return {
          url: `${dataService.companyController.getAllCompanies}`,
          method: "GET",
          // headers: { Authorization: `Bearer ${body.token}`},
          body: body.payload,
        };
      },
    }),
  }),
});
export const {
  useGetAllCompaniesMutation,
} = companyService;
