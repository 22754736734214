import React, { useEffect, useState } from "react";
// import "./auth.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import "./style.css";
import "../../assets/icons/mission.png";
import "../../assets/icons/vision.png";
import "../../assets/logo-blue.png";
import { Typography } from "antd";
import SignUp from "./SignUp";
import ForgotResetPassword from "./ForgotResetPassword";
import { PAGE_TITLE, USER_ROLE } from "../../constant/AppConstant";
import TestYourProject from "./TestYourProject";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { BsEnvelope, BsFacebook, BsGeoAlt, BsLinkedin, BsTelephone, BsTwitter } from "react-icons/bs";

type Props = {};
const { Link } = Typography;

const LandingPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const localUserObj = getLocalStorageData("localUser");
    const userBasedRoute = localStorage.getItem("userBasedRoute");

    const [show, setShow] = useState(false);
    const [forgotPasswordShow, setForgotPasswordShow] = useState(false);
    const [showDataModal, setShowDataModal] = useState(false);

    useEffect(() => {
        localStorage.setItem("routeTo", "home")
    }, []);


    const handleShow = () => setShow(true);
    const handleLoginClick = () => {
        localStorage.setItem("routeTo", "login");
        navigate("/login")
    };

    const handleHomeClick = () => {
        localStorage.setItem("routeTo", "home");
        navigate("/home")
    };

    const handleAbout = () => {
        navigate("/about");
    }

    const handleDashboardClick = () => {
        if (userBasedRoute === USER_ROLE.ADMIN || userBasedRoute === USER_ROLE.BETA_TEST_USER) {
            navigate("/dashboard");
        } else {
            navigate("/corporate-user-dashboard");
        }
    }

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [isShown, setIsSHown] = useState(false);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <>
            {/* <Header position="fixed" homeId="#home"/> */}
            <header className="header sticky-top">
                <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#">
                            <img
                                src={require("../../assets/logo-blue.png")}
                                alt="Bootstrap"
                                width="100"
                                height="40"
                            />
                        </a>

                        <button
                            type="button"
                            className="navbar-toggler"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <div className="navbar-nav ms-auto">
                                <a
                                    href="#home"
                                    // onClick={handleAbout}
                                    className="nav-item nav-link menu-font text-secondadry-darker px-4 cursor-pointer"
                                >
                                    Home
                                </a>
                                <a
                                    href="#aboutUs"
                                    // onClick={handleAbout}
                                    className="nav-item nav-link menu-font text-secondadry-darker px-4 cursor-pointer"
                                >
                                    About us
                                </a>
                                <li className="nav-item dropdown  text-secondadry-darker px-4">
                                    <a className="nav-link dropdown-toggle menu-font" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        ReguVista
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item cursor-pointer" onClick={handleHomeClick}>Home</a></li>
                                        {localUserObj !== null ? (
                                            <li><a className="dropdown-item cursor-pointer" onClick={handleDashboardClick}>Dashboard</a></li>
                                        ) : (
                                            <li><a className="dropdown-item cursor-pointer" onClick={handleLoginClick}>Login</a></li>
                                        )}
                                        {localUserObj === null ? (
                                            <li><a className="dropdown-item cursor-pointer" onClick={handleShow}>Sign Up</a></li>
                                        ) : null}
                                    </ul>
                                </li>
                                <a
                                    href="#contactUs"
                                    // onClick={handleAbout}
                                    className="nav-item nav-link menu-font text-secondadry-darker px-4 cursor-pointer"
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <div id="home">
                <div className="jumbotron" style={{ overflow: "hidden" }}>
                    <div className="container-fluid p-0">
                        <img src={require("../../assets/capitolAsset/indightUnleashed.png")} className="img-fluid w-100" alt="Responsive image" />
                    </div>
                </div>
                <div className="container-fluid mt-5">
                    <div className="row justify-content-center">
                        <div className="col-md-3 align-items-center d-flex flex-column border-gradient border-gradient-blue mx-3">
                            <h4 className="text-cm-primary1 fw-bold">Our Vision</h4>
                            <p className="fs-6 text-cm-dark-blue text-center">
                                Become the trusted global authority in AI regulatory compliance,
                                fostering a world where AI innovation and compliance thrive hand in hand
                            </p>
                        </div>
                        <div className="col-md-3 align-items-center d-flex flex-column border-gradient border-gradient-blue mx-3">
                            <h4 className="text-cm-primary1 fw-bold">Our Mission</h4>
                            <p className="fs-6 text-cm-dark-blue text-center">
                                Deliver AI-driven real-time
                                legislation and compliance
                                analyses so that businesses,
                                regulators, and organizations can
                                make informed decisions,
                                cultivating ethical and responsible
                                AI practices
                            </p>
                        </div>
                        <div className="col-md-3 align-items-center d-flex flex-column border-gradient border-gradient-blue mx-3">
                            <h4 className="text-cm-primary1 fw-bold">Our Values</h4>
                            <p className="fs-6 text-cm-dark-blue text-center">
                                We are driven by steadfast commitment to delivering exceptional values to our clients and stakeholders.
                                Out core values are Innovation, Integrity, Customer-Centric, Collaboration, Empowerment, Responsibility, Transparency
                            </p>

                        </div>
                    </div>

                </div>
                <div className="bg-cm-primary1 h-25 mt-5 d-flex py-4 flex-column justify-content-center align-items-center">
                    <h4 className="text-white">Test your project for Data Privacy, Data and Algorithm Bias</h4>
                    <span className="badge rounded-pill bg-cm-icon-grey text-cm-primary1 py-2 px-5 fs-6 cursor-pointer"
                        onClick={() => {
                            setShowDataModal(true);
                        }}>
                        Click here
                    </span>
                </div>
            </div>

            {/*-------------------------- About us section------------------------  */}
            <div className="mt-5 container" id="aboutUs">
                <div className="flex-lg-row flex-column-reverse main d-flex w-100">
                    <div className="col-lg-6  ml-auto">
                        <img
                            src={require("../../assets/capitolAsset/microchip.jpg")}
                            alt="capitolAI Home"
                            className="img-fluid mb-5"
                            style={{ height: "100%", width: "100%", objectFit: "cover" }}
                        // width="100"
                        // height="40"
                        />
                    </div>
                    <div className="bg-cm-light col-lg-6 pr-lg-5 mb-5 mb-md-0">
                        <div className="vision-mission bg-color-#E7F0FF-30 py-2 px-4">
                            <h2 className="text-cm-primary1 text-stroke">
                                About Us
                            </h2>
                            <p className="">
                                <span className="text-cm-primary1 fw-bold"></span>Capitol Tunnels AI Inc, headquartered in Washington, DC, is at the forefront of global AI regulatory insights and compliance solutions.
                                In the same way that the historic capitol tunnels connect the key legislative buildings in Washington DC, Capitol Tunnels AI creates digital pathways of understanding the complexities of artificial intelligence in legislation, regulation, and compliance.
                                As trailblazers in regulatory intelligence, we are committed to fostering ethical AI deployment and safeguarding data privacy while embracing the possibilities of technological advancement. Our dedicated team of legal experts, data scientists, and AI specialists are here to help businesses and policymakers usher in a new era of responsible AI-driven growth.

                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid p-0 mt-5">
                <img src={require("../../assets/capitolAsset/values.png")} className="img-fluid w-100" alt="Responsive image" />
            </div>
            {/*-------------------------- our team section------------------------  */}
            <div className="container-fluid p-0 mt-5 bg-cm-light-grey pt-3">
                <h3 className="text-white text-center">
                    Our Team
                </h3>
                <div className="row justify-content-center">
                    <div className="col-md-2 d-flex align-items-center flex-column mx-4">
                        <img src={require("../../assets/capitolAsset/Team/sanjaypuri-w.png")} width={150} className="bd-placeholder-img rounded-circle" />
                        <h5 className="text-cm-medium-grey mt-3">Sanjay Puri</h5>
                    </div>
                    <div className="col-md-2 d-flex align-items-center flex-column mx-4">
                        <img src={require("../../assets/capitolAsset/Team/prakashsharma.jpg")} width={150} className="bd-placeholder-img rounded-circle" />
                        <h5 className="text-cm-medium-grey mt-3">Sanjay Puri</h5>
                    </div>
                    <div className="col-md-2 d-flex align-items-center flex-column mx-4">
                        <img src={require("../../assets/capitolAsset/Team/sumanthchepuri-w.png")} width={150} className="bd-placeholder-img rounded-circle" />
                        <h5 className="text-cm-medium-grey mt-3">Sanjay Puri</h5>
                    </div>
                    <div className="col-md-2 d-flex align-items-center flex-column mx-4">
                        <img src={require("../../assets/capitolAsset/Team/germys-w.png")} width={150} className="bd-placeholder-img rounded-circle" />
                        <h5 className="text-cm-medium-grey mt-3">Sanjay Puri</h5>
                    </div>

                </div>
            </div>
            {/*-------------------------- contact us section------------------------  */}
            <div className="container-fluid mt-5 p-0" id="contactUs">
                {/* <div className="container" style={{ marginTop: "50px", width: "100%" }}> */}
                <div className="card">
                    <img className=""
                        src={require("../../assets/capitolAsset/contactus.jpg")} width="100%" height="400" />

                    <div className="card-img-overlay card-inverse border-0">

                        <div className="d-flex flex-column ps-5">
                            <h2 className="text-stroke text-cm-primary1 mt-5">
                                Contact Us
                            </h2>
                            <p className="para-size">
                                Let us help you transform compliance into a proactive, insightful <br /> journey. Together,
                                we can unleash the true potential of AI within <br /> ethical and transparent boundries.
                            </p>
                            <div className="pt-3">
                                <div className="row">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-5">
                                        <h3 className="text-stroke text-white fst-italic text-center">
                                            Want to get in touch?
                                            <br />
                                            We'd love to hear from you.
                                            <br />
                                            Here's how you can reach us...
                                        </h3>
                                    </div>
                                    <div className="col-lg-6"></div>
                                </div>

                            </div>
                        </div>
                        {/* <div className="card-block pt-5">
                            <a href="#" className="card-link text-white">Link
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="container">
                <footer className="pt-5 pb-1">
                    <div className="row text-center">
                        <div className="col-md-4">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center rounder-circle">
                                <BsTelephone color="#3386ff" className="" size={50} />
                            </div>
                            <h5 className="mt-2">1-800-000-0000</h5>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center rounder-circle">
                                <BsEnvelope color="#3386ff" className="" size={50} />
                            </div>
                            <h5 className="mt-2">info@capitoltunnels.ai</h5>
                        </div>
                        <div className="col-md-4">
                            <div className="feature-icon d-inline-flex align-items-center justify-content-center rounder-circle">
                                <BsGeoAlt color="#3386ff" className="" size={50} />
                            </div>
                            <h5 className="mt-2">11490 Commerce Park Drive <br /> Suite 520 <br /> Reston, VA 20191</h5>
                        </div>
                    </div>
                    <div className="text-align-center mt-5">
                        <h3 className="text-cm-primary1">
                            Follow Us
                        </h3>
                        <div className="row justify-content-center">
                            <div className="col-sm-1">
                                <div className="feature-icon d-inline-flex align-items-center justify-content-center rounder-circle">
                                    <BsFacebook color="#3386ff" className="" size={40} />
                                </div>
                                
                            </div>
                            <div className="col-sm-1">
                                <div className="feature-icon d-inline-flex align-items-center justify-content-center rounder-circle">
                                    <BsLinkedin color="#3386ff" className="" size={40} />
                                </div>
                                
                            </div>
                            <div className="col-sm-1">
                                <div className="feature-icon d-inline-flex align-items-center justify-content-center rounder-circle">
                                    <BsTwitter color="#3386ff" className="" size={40} />
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="text-align-center mt-5">
                        <p className="text-cm-dark fs-6">
                            &#169; 2023 CAPITOL TUNNELS AI
                        </p>
                    </div>
                </footer>
            </div>


            {/* <footer className="text-align-center ">
                <p className="text-cm-dark fs-6">
                    Capitol Tunnels.ai &#169; 2023
                </p>
            </footer> */}
            {show && (
                <SignUp
                    show={show}
                    setShow={setShow}
                />
            )}
            {forgotPasswordShow && (
                <ForgotResetPassword
                    forgotResetShow={forgotPasswordShow}
                    setForgotResetShow={setForgotPasswordShow}
                    title={PAGE_TITLE.FORGOT_PASSWORD}
                />
            )}
            {showDataModal && (
                <TestYourProject
                    showDataModal={showDataModal}
                    setShowDataModal={setShowDataModal}
                />
            )}
        </>
    );
};
export default LandingPage;
