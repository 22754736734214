import React, { useEffect, useState } from "react";
// import "./auth.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import "./style.css";
import "../../assets/icons/mission.png";
import "../../assets/icons/vision.png";
import "../../assets/logo-blue.png";
import * as Yup from "yup";
import { Typography } from "antd";
import SignUp from "./SignUp";
import TestYourProject from "./TestYourProject";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { USER_ROLE } from "../../constant/AppConstant";

type Props = {};
const { Link } = Typography;

const AboutUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const localUserObj = getLocalStorageData("localUser");
  const userBasedRoute = localStorage.getItem("userBasedRoute");

  const [show, setShow] = useState(false);
  const [forgotPasswordShow, setForgotPasswordShow] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);

  useEffect(() => {
    localStorage.setItem("routeTo", "about")
  }, []);


  const handleShow = () => setShow(true);
  const handleLoginClick = () => {
    localStorage.setItem("routeTo", "login");
    navigate("/login")
  };

  const handleHomeClick = () => {
    localStorage.setItem("routeTo", "home");
    navigate("/home")
  };

  const handleDashboardClick = () => {
    if (userBasedRoute === USER_ROLE.ADMIN || userBasedRoute === USER_ROLE.BETA_TEST_USER) {
      navigate("/dashboard");
    } else {
      navigate("/corporate-user-dashboard");
    }
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isShown, setIsSHown] = useState(false);


  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
          <div className="container-fluid">
            <a className="navbar-brand" onClick={() => navigate("/home")}>
              <img
                src={require("../../assets/logo-blue.png")}
                alt="Bootstrap"
                width="100"
                height="40"
              />
            </a>

            <button
              type="button"
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto">
                <li className="nav-item dropdown  text-secondadry-darker px-4">
                  <a className="nav-link dropdown-toggle menu-font" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ReguVista
                  </a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item cursor-pointer" onClick={handleHomeClick}>Home</a></li>
                    {localUserObj !== null ? (
                      <li><a className="dropdown-item cursor-pointer" onClick={handleDashboardClick}>Dashboard</a></li>
                    ) : (
                      <li><a className="dropdown-item cursor-pointer" onClick={handleLoginClick}>Login</a></li>
                    )}
                    {localUserObj === null ? (
                      <li><a className="dropdown-item cursor-pointer" onClick={handleShow}>Sign Up</a></li>
                    ) : null}
                  </ul>
                </li>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className="px-5">
        <div className="row pb-5 align-items-center">
          <div className="flex-lg-row main align-items-center d-flex w-100">
            <div className="bg-cm-light pt-5 col-lg-12 pr-lg-5 mb-5 mb-md-0">
              {/* about us */}
              <div className="about">
                <h1 className="text-cm-primary1 fw-bold">
                  About Us
                </h1>
                <p className="para-size">
                  <span className="text-cm-primary1 fw-bold">Capitol Tunnels AI Inc</span>, headquartered in Washington, DC, is at the forefront of global AI regulatory insights and compliance solutions.
                  In the same way that the historic capitol tunnels connect the key legislative buildings in Washington DC, Capitol Tunnels AI creates digital pathways of understanding the complexities of artificial intelligence in legislation, regulation, and compliance.
                  As trailblazers in regulatory intelligence, we are committed to fostering ethical AI deployment and safeguarding data privacy while embracing the possibilities of technological advancement. Our dedicated team of legal experts, data scientists, and AI specialists are here to help businesses and policymakers usher in a new era of responsible AI-driven growth.

                </p>
              </div>
              {/* Team */}
              <div className="flex-lg-row flex-column-reverse main align-items-center d-flex w-100">
                <div className="login  col-lg-6  ml-auto">
                  <h1 className="text-cm-primary1 fw-bold">
                    Team
                  </h1>
                  <p className="fw-bold para-size">
                    We are passionate about cultivating the responsible, trustworthy and ethical use of AI!
                  </p>
                  <p className="para-size">
                    Our dedicated team of legal experts, data scientists, and AI specialists share a common vision - to create a world where regulatory compliance becomes insightful and innovation is fueled responsibly.
                    Through our AI-driven platform, we empower businesses and regulatory bodies with real-time, data-driven insights that transcend traditional compliance practices.
                  </p>
                </div>
                <div className="bg-cm-light col-lg-6 pr-lg-5 mb-5 mb-md-0">
                  <div className="vision-mission bg-color-#E7F0FF-30 py-2 px-4">
                    <div className="d-flex align-items-start">
                      <div className="vision-mission-c w-50 d-flex flex-column justify-content-center align-items-start text-center">
                        <div className="d-flex justify-content-center w-100 cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/sunilsabharwaldc/")}>
                          <img
                            src={require("../../assets/capitolAsset/Team/Sunil-Sabharwal.png")}
                            width={200}
                            height={200}
                            className="border border-secondary"
                          />
                        </div>
                        <div className="justify-content-center w-100 mt-2 fw-bold cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/sanjaykpuri/")}>
                          Sunil Sabharwal
                        </div>
                      </div>
                      <div className="vision-mission-c w-50 bg-color-#E7F0FF-30 d-flex flex-column justify-content-center align-items-start text-center">
                        <div className="d-flex justify-content-center w-100 cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/dr-prakash-sharma-330743a3/")}>
                          <img
                            src={require("../../assets/capitolAsset/Team/prakashsharma.jpg")}
                            width={200}
                            className="border border-secondary"
                          />
                        </div>
                        <div className="justify-content-center w-100 mt-2 fw-bold cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/dr-prakash-sharma-330743a3/")}>
                          Prakash Sharma
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-start mt-3">
                      <div className="vision-mission-c w-50 d-flex flex-column justify-content-center align-items-start text-center">
                        <div className="d-flex justify-content-center w-100 cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/sanjaykpuri/")}>
                          <img
                            src={require("../../assets/capitolAsset/Team/sanjaypuri-w.png")}
                            width={200}
                            
                            className="border border-secondary"
                          />
                        </div>
                        <div className="justify-content-center w-100 mt-2 fw-bold cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/sanjaykpuri/")}>
                          Sanjay Puri
                        </div>
                      </div>
                      <div className="vision-mission-c w-50 bg-color-#E7F0FF-30 d-flex flex-column justify-content-center align-items-start text-center">
                        {/* <div className="d-flex justify-content-center w-100 cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/jeremymspaulding/")}>
                          <img src={require("../../assets/capitolAsset/Team/germys-w.png")}
                            width={200}
                            className="border border-secondary"
                          />
                        </div>
                        <div className="justify-content-center w-100 mt-2 fw-bold cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/jeremymspaulding/")}>
                          Jeremy Spaulding
                        </div> */}
                        <div className="d-flex justify-content-center w-100 cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/sumanth-chepuri-b3a17a/")}>
                          <img
                            src={require("../../assets/capitolAsset/Team/sumanthchepuri-w.png")}
                            width={200}
                            className="border border-secondary"
                          />
                        </div>
                        <div className="justify-content-center w-100 mt-2 fw-bold cursor-pointer" onClick={() => window.open("https://www.linkedin.com/in/sumanth-chepuri-b3a17a/")}>
                          Sumanth Chepuri
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {/* Values */}
              <div className="about">
                <h1 className="text-cm-primary1 fw-bold">
                  Our Values
                </h1>
                <p className="para-size">
                  At Capitol Tunnels AI, we are driven by a steadfast commitment to delivering exceptional value to our clients and stakeholders. Our core values serve as the foundation of our organization, guiding every aspect of our work:
                </p>
                <div className="para-size d-flex">
                  <p className="text-cm-primary1 fw-bold col-lg-2">Innovation :</p>
                  <p>We embrace cutting-edge technology and creative thinking to provide innovative solutions that drive sustainable growth and excellence.</p>
                </div>
                <div className="para-size d-flex">
                  <p className="text-cm-primary1 fw-bold col-lg-2">Integrity :</p>
                  <p>We uphold the highest ethical standards, fostering trust, transparency, and accountability in all our interactions.</p>
                </div>
                <div className="para-size d-flex">
                  <p className="text-cm-primary1 fw-bold col-lg-2">Customer-Centric :</p>
                  <p>Our clients are at the heart of everything we do. We strive to understand their unique needs and deliver tailored solutions that exceed expectations.</p>
                </div>
                <div className="para-size d-flex">
                  <p className="text-cm-primary1 fw-bold col-lg-2">Collaboration :</p>
                  <p>We believe in the power of collaboration and teamwork. By working together, we achieve greater outcomes and foster a culture of mutual support.</p>
                </div>
                <div className="para-size d-flex">
                  <p className="text-cm-primary1 fw-bold col-lg-2">Empowerment :</p>
                  <p>We empower our clients with the knowledge and tools they need to navigate the complexities of regulatory compliance with confidence.</p>
                </div>
                <div className="para-size d-flex">
                  <p className="text-cm-primary1 fw-bold col-lg-2">Responsibility :</p>
                  <p>We are dedicated to promoting responsible AI practices, safeguarding data privacy, and ensuring compliance with global regulations.</p>
                </div>
                <div className="para-size d-flex">
                  <p className="text-cm-primary1 fw-bold col-lg-2">Transparency :</p>
                  <p>We give our clients insights from validated and trusted data sources and provide full visibility into where our insights originate along with our own business practices.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="text-align-center">
        <p className="text-cm-dark fs-6">
          Capitol Tunnels.ai &#169; 2023
        </p>
      </footer>
      {show && (
        <SignUp
          show={show}
          setShow={setShow}
        />
      )}
      {showDataModal && (
        <TestYourProject
          showDataModal={showDataModal}
          setShowDataModal={setShowDataModal}
        />
      )}
    </>
  );
};
export default AboutUs;
