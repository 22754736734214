import React, { useEffect, useState } from "react";
// import "./auth.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import "./style.css";
import "../../assets/icons/mission.png";
import "../../assets/icons/vision.png";
import "../../assets/logo-blue.png";
import { Typography } from "antd";
import SignUp from "./SignUp";
import ForgotResetPassword from "./ForgotResetPassword";
import { PAGE_TITLE, USER_ROLE } from "../../constant/AppConstant";
import TestYourProject from "./TestYourProject";
import { getLocalStorageData } from "../../utils/LocalStorageService";

type Props = {};
const { Link } = Typography;

const Home = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const localUserObj = getLocalStorageData("localUser");
  const userBasedRoute = localStorage.getItem("userBasedRoute");

  const [show, setShow] = useState(false);
  const [forgotPasswordShow, setForgotPasswordShow] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);

  useEffect(() => {
    localStorage.setItem("routeTo", "home")
  }, []);


  const handleShow = () => setShow(true);
  const handleLoginClick = () => {
    localStorage.setItem("routeTo", "login");
    navigate("/login")
  };

  const handleHomeClick = () => {
    localStorage.setItem("routeTo", "home");
    navigate("/home")
  };

  const handleAbout = () => {
    navigate("/about");
  }

  const handleDashboardClick = () => {
    if (userBasedRoute === USER_ROLE.ADMIN || userBasedRoute === USER_ROLE.BETA_TEST_USER) {
      navigate("/dashboard");
    } else {
      navigate("/corporate-user-dashboard");
    }
  }

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isShown, setIsSHown] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img
                src={require("../../assets/logo-blue.png")}
                alt="Bootstrap"
                width="100"
                height="40"
              />
            </a>

            <button
              type="button"
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto">
                <a
                  // href="#"
                  onClick={handleAbout}
                  className="nav-item nav-link menu-font text-secondadry-darker px-4 cursor-pointer"
                >
                  About us
                </a>
                {/* <a
                  href="#"
                  className="nav-item nav-link menu-font text-secondadry-darker px-4"
                >
                  Value Proposition
                </a> */}
                <li className="nav-item dropdown  text-secondadry-darker px-4">
                  <a className="nav-link dropdown-toggle menu-font" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    ReguVista
                  </a>
                  <ul className="dropdown-menu">
                    <li><a className="dropdown-item cursor-pointer" onClick={handleHomeClick}>Home</a></li>
                    {localUserObj !== null ? (
                      <li><a className="dropdown-item cursor-pointer" onClick={handleDashboardClick}>Dashboard</a></li>
                    ) : (
                      <li><a className="dropdown-item cursor-pointer" onClick={handleLoginClick}>Login</a></li>
                    )}
                    {localUserObj === null ? (
                      <li><a className="dropdown-item cursor-pointer" onClick={handleShow}>Sign Up</a></li>
                    ): null}
                  </ul>
                </li>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <div className="px-5">
        <div className="row pb-5 mt-4 align-items-center">
          {/* <div className="oveflow-x-hidden d-flex flex-column align-items-center justify-content-center "> */}
          {/* mainDiv  */}
          {/* <div className="overflow-hidden"> */}
          <div className="flex-lg-row flex-column-reverse main align-items-center d-flex w-100">
            <div className="bg-cm-light pt-5 col-lg-6 pr-lg-5 mb-5 mb-md-0">
              <div className="about">
                <h1 className="text-cm-primary1">
                  Insights Unleashed, Compliance Redefined
                </h1>
                <p className="fs-6">
                  Capitol Tunnels AI Inc, headquartered
                  in Washington, DC, is at the forefront
                  of global AI regulatory insights and
                  compliance solutions. In the same way
                  that the historic capitol tunnels
                  connect the key legislative buildings in
                  Washington DC, Capitol Tunnels AI
                  creates digital pathways of
                  understanding the complexities of
                  artificial intelligence in legislation,
                  regulation, and compliance.
                </p>
                {/* <a href="" className="know-more">Know More</a> */}
              </div>

              <div className="vision-mission bg-color-#E7F0FF-30 py-2 px-4">
                <div className="vision-mission-c d-flex justify-content-center align-items-center">
                  <img
                    src={require("../../assets/icons/vision1.png")}
                    width={30}
                    className="text-cm-primary1"
                  />
                  <div className="content p-3">
                    <h3 className="text-cm-dark-grey" style={{ fontWeight: 600 }}>OUR VISION</h3>
                    <p className="fs-6  text-cm-dark-blue">
                      Become the trusted global authority in AI regulatory compliance,
                      fostering a world where AI innovation and compliance thrive hand in hand
                    </p>
                  </div>
                </div>
                <div className="vision-mission-c bg-color-#E7F0FF-30 d-flex justify-content-center align-items-center">
                  <img src={require("../../assets/icons/mission1.png")} width={30} />
                  <div className="content p-3">
                    <h3 className="text-cm-dark-grey" style={{ fontWeight: 600 }}>OUR MISSION</h3>
                    <p className="fs-6 text-cm-dark-blue">
                      Deliver AI-driven real-time
                      legislation and compliance
                      analyses so that businesses,
                      regulators, and organizations can
                      make informed decisions,
                      cultivating ethical and responsible
                      AI practices
                    </p>
                  </div>
                </div>
              </div>
              <h3
                className="text-align-center text-cm-primary1 fw-bold mt-5"
                onClick={() => {
                  setShowDataModal(true);
                }}
              >
                <a>
                  Test your project for Data Privacy, Data and Algorithm
                  Bias
                </a>
              </h3>
            </div>
            <div className="bg-cm-light d-flex flex-column pt-5 col-lg-6 pr-lg-5 mb-5 mb-md-0">
              <img
                src={require("../../assets/home1.jpg")}
                alt="capitolAI Home"
                className="img-fluid mb-5"
              // width="100"
              // height="40"
              />
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
      <footer className="text-align-center ">
        <p className="text-cm-dark fs-6">
          Capitol Tunnels.ai &#169; 2023
        </p>
      </footer>
      {show && (
        <SignUp
          show={show}
          setShow={setShow}
        />
      )}
      {forgotPasswordShow && (
        <ForgotResetPassword
          forgotResetShow={forgotPasswordShow}
          setForgotResetShow={setForgotPasswordShow}
          title={PAGE_TITLE.FORGOT_PASSWORD}
        />
      )}
      {showDataModal && (
        <TestYourProject
          showDataModal={showDataModal}
          setShowDataModal={setShowDataModal}
        />
      )}
    </>
  );
};
export default Home;
