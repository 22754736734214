import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { Menu, Select, Switch, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import {
  ACTION_TYPE, REPORT_NAME,
} from "../constant/reportType";
import { STATE_COLUMN, COUNTRY_COLUMN } from "../constant/AppConstant";
import editIcon from "../assets/icons/editicon.svg";
import { getLocalStorageData } from "../utils/LocalStorageService";
import { useGetAllStatesMutation } from "../redux-toolkit/services/stateService";
import { Formik } from "formik";
import { MenuItemsData } from "../constant/mockData";
import { useGetAllResponsibilityCenterMutation, useGetAllResponsibilityGroupMutation } from "../redux-toolkit/services/responsibilityService";
import { alertMessage } from "../utils/AlertService";
import { getAllResponsibilityCenterDetails, getAllResponsibilityGroupDetails, setResponsibilityCenter, setResponsibilityGroup } from "../redux-toolkit/slices/responsibilitySlice";
import { getAllCompaniesDetails, setCompany } from "../redux-toolkit/slices/companySlice";
import { useGetAllCompaniesMutation } from "../redux-toolkit/services/companyService";

const { Option } = Select;
type Props = {};

const DataGovernance = (props: Props) => {
  const params = useParams();
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // States
  const [show, setShow] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [reportType, setReportType] = useState(params.reportType) as any;
  const [reportName, setReportName]: any = useState();
  const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
  const [selectedRow, setSelectedRow]: any = useState([]);
  const [isSelect, setIsSelect] = useState(false);
  const [call, setCall] = useState("N");
  const [stateData, setStateData] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedResGroup, setSelectedResGroup] = useState();
  const [selectedRespCenter, setSelectedRespCenter] = useState();

  //columns
  const [countryColumn, setCountryColumn]: any = useState(COUNTRY_COLUMN);
  const [stateColumn, setStateColumn]: any = useState(STATE_COLUMN);

  //get mutation
  const [getAllState, getAllStateResponse] = useGetAllStatesMutation();
  const [getAllResponsibilityGroup, allResponsibilityGroupResponse] = useGetAllResponsibilityGroupMutation();
  const [getAllResponsibilityCenter, allResponsibilityCenterResponse] = useGetAllResponsibilityCenterMutation();
  const [getAllCompanies, allCompaniesResponse] = useGetAllCompaniesMutation();

  //selector
  const allResponsibilityGroup: any = useAppSelector(getAllResponsibilityGroupDetails);
  const allResponsibilityCenter: any = useAppSelector(getAllResponsibilityCenterDetails);
  const allCompanies: any = useAppSelector(getAllCompaniesDetails);

  useEffect(() => {

    switch (params.reportType) {
      case REPORT_NAME.CODE_AI_BIAS_ANALYSIS:
        // setReportName("state");
        // setIsSelect(true);
        // getAllCountry({});
        // getAllState({ page: currentpage - 1, offset: offset, orderBy: "asc" });
        break;
      case REPORT_NAME.CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS:

        break;
      default:
    }
  }, []);

  useEffect(() => {
    getAllResponsibilityGroup({});
    getAllResponsibilityCenter({});
  }, []);

  useEffect(() => {
    getAllCompanies({});
  }, []);

  useEffect(() => {
    if (allResponsibilityGroupResponse.isSuccess) {
      dispatch(setResponsibilityGroup(allResponsibilityGroupResponse));
    } else if (allResponsibilityGroupResponse.isError) {
      let response: any = allResponsibilityGroupResponse.error;
      alertMessage(response, "error");
    }
  }, [allResponsibilityGroupResponse.isSuccess, allResponsibilityGroupResponse.isError]);

  useEffect(() => {
    if (allResponsibilityCenterResponse.isSuccess) {
      dispatch(setResponsibilityCenter(allResponsibilityCenterResponse));
    } else if (allResponsibilityCenterResponse.isError) {
      let response: any = allResponsibilityCenterResponse.error;
      alertMessage(response, "error");
    }
  }, [allResponsibilityCenterResponse.isSuccess, allResponsibilityCenterResponse.isError]);

  useEffect(() => {
    if (allCompaniesResponse.isSuccess) {
      dispatch(setCompany(allCompaniesResponse));
    } else if (allCompaniesResponse.isError) {
      let response: any = allCompaniesResponse.error;
      alertMessage(response, "error");
    }
  }, [allCompaniesResponse.isSuccess, allCompaniesResponse.isError]);


  useEffect(() => {
    if (call === "Y") {
      if (params.reportType === "State") {
        setReportName("state");
        setIsSelect(true);
        getAllState({ page: currentpage - 1, offset: offset, orderBy: "asc" });
        setCall("N");
      }
    }
  }, [call]);

  //delete..........
  const onDeleteData = (record: any) => {
    if (reportName === "country") {

    }
  };

  const handleOpenForm = (record: any) => {
    setShow(true);
    setActionType(ACTION_TYPE.UPDATE_ACTION);
    setSelectedRow(record);
  };
  useEffect(() => {
    const temp: any = [];
    const tempAction: any = {
      title: "Action",
      dataIndex: "action",
      key: "name",
      render: (index: any, record: any) => {
        return (
          // for delete switch button
          <div className="d-flex">
            <div className="px-2 pe-auto">
              <Switch
                checked={
                  record.status === true ||
                    record.enabled === true ||
                    record.isEnabled === true
                    ? true
                    : false
                }
                onClick={() => {
                  onDeleteData(record);
                }}
              />
            </div>
            {record.status === true ||
              record.enabled === true ||
              record.isEnabled === true ? (
              <div
                className="px-2 pe-auto"
                onClick={() => {
                  handleOpenForm(record);
                }}
              >
                <img src={editIcon} alt="update" />
              </div>
            ) : null}
          </div>
        );
      },
    };
    if (reportName === "state" && stateColumn) {
      stateColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setStateColumn(temp);
    } else if (reportName === "country" && countryColumn) {
      countryColumn.map((item: any) => {
        temp.push(item);
      });
      temp.push(tempAction);
      setCountryColumn(temp);
    }
  }, [reportName]);

  const paginationChange = (page: number, offset: number) => {
    if (reportName === "state") {

    }
    setCurrentPage(page);
    setOffset(offset);
  };

  const handleShow = () => setShow(true);

  // navigate............
  const handleBack = () => {
    navigate("/aiGovernanceReports");
  };

  const handleSearch = (event: any) => {
    // if ((event.target.value).length >= 3 || event.target.value == 0) {
    if (reportName === "state") {

    }
    // }
  };

  const MenuItems = [
    // {
    //   label: 'Item 1',
    //   key: 'item-1',
    // },
    // {
    //   label: 'Item 2',
    //   key: 'item-2',
    // },
    {
      label: 'ObjectType1',
      key: 'ObjectType1',
      children: [
        {
          label: 'Object1',
          key: 'Object1',
          children: [
            {
              label: 'Parameter',
              key: 'parameter',
              onClick: (e: any) => {
                console.log(e, 'e')
                // Do something when the Parameter item is clicked.
              },
            },
            {
              label: 'Evidence',
              key: 'evidence',
            },
            {
              label: 'Risk',
              key: 'risk',
            },
            {
              label: 'Governance Control',
              key: 'governanceControl',
            },
            {
              label: 'Assessment',
              key: 'assessment',
            },
            {
              label: 'Audit Plan',
              key: 'auditPlan',
            },
            {
              label: 'Audit',
              key: 'audit',
            },
          ],
        },
        {
          label: 'Object2',
          key: 'Object2',
        },
      ],
    },
    {
      label: 'ObjectType2',
      key: 'ObjectType2',
      children: [
        {
          label: 'Object1',
          key: 'Object21',
          // children: [
          //   {
          //     label: 'Option 2',
          //     key: 'submenu-item-2',
          //   },
          // ]
        },
        {
          label: 'Object2',
          key: 'Object22',
        },
      ],
    },
  ];

  // handleSubmit...........
  const handleSubmit = (values: any) => {
    console.log(values, 'values')
  };

  const handleCompany = (event: any) => {
    // console.log(event.target.value)
    setSelectedCompany(event.target.value);
  };

  const handleRespGroup = (event: any) => {
    // console.log(event.target.value)
    setSelectedResGroup(event.target.value);
  };

  const handleRespCenter = (event: any) => {
    // console.log(event.target.value)
    setSelectedRespCenter(event.target.value);
  };

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        {/* <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div className="input-group px-2 mt-1">
            <Form.Control
              type="search"
              placeholder="Search"
              className="border-0 bg-cm-light-primary1"
              aria-label="Search"
              onChange={handleSearch}
            />
            <div className="input-group-append bg-cm-light-primary1 p-1 rounded-end">
              <span className="input-group-text bg-cm-light-primary1 border-0">
               
                <BsSearch className=" bg-cm-light-primary1 border-cm-light-primary1" />

              </span>
            </div>
          </div>
          <div onClick={handleShow} className="d-flex px-2 align-items-center mt-1">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0 text-nowrap"
              onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
            >
              New Request
            </Button>
          </div>
          {show && (
            <AIGovernanceData
              show={show}
              setShow={setShow}
              actionType={actionType}
              getAllReportAsync={() => null}
              updateAllReportAsync={({ }) => null}
              reportType={reportType}
              reportName={params.reportType}
              reportNameCode={reportName}
              isSelect={isSelect}
              data={selectedRow}
              call={call}
              setCall={setCall}
            />
          )}
        </div> */}
      </div>

      <div className="d-flex ">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        {/* {getAllCountryResponse.isLoading ||
          getAllStateResponse.isLoading ||
          getAllCountriesWithSearchResponse.isLoading ||
          getAllStatesWithSearchResponse.isLoading ? (
          <Loading />
        ) : (
          ""
        )} */}
      </div>
      <div>
        <Formik
          // validationSchema={formSchema}
          enableReinitialize={true}
          initialValues={{
            company: "",
            responsibiltyGroup: "",
            responsibiltyCenter: ""
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="mt-5 d-flex">
                <p>
                  <div className="d-flex">
                    <div>Company</div>
                    <div style={{ color: "red" }}>*</div>
                  </div>
                  <div className="pe-2">
                    <select
                      className="form-select form-select-sm w-100"
                      aria-label=".form-select-sm example"
                      onChange={handleCompany}
                      name="Company"
                      value={selectedCompany}
                      required
                    >
                      <option value="">Select Comapny</option>
                      {allCompanies &&
                        allCompanies.map((itemType: any, index: any) => {
                          return (
                            <option
                              key={index.toString()}
                              value={itemType.id}
                            >
                              {itemType.companyName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </p>
                <p>
                  <div className="d-flex">
                    <div>Responsibilty Group</div>
                    <div style={{ color: "red" }}>*</div>
                  </div>
                  <div className="pe-2">
                    <select
                      className="form-select form-select-sm w-100"
                      aria-label=".form-select-sm example"
                      onChange={handleRespGroup}
                      name="responsibiltyGroup"
                      value={selectedResGroup}
                      required
                    >
                      <option value="">Select Responsibility Group</option>
                      {allResponsibilityGroup &&
                        allResponsibilityGroup.map((itemType: any, index: any) => {
                          return (
                            <option
                              key={index.toString()}
                              value={itemType.group_code_id}
                            >
                              {itemType.groupName}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </p>
                <p>
                  <div className="d-flex">
                    <div>Responsibilty Center</div>
                    <div style={{ color: "red" }}>*</div>
                  </div>
                  <div className="pe-2">
                    <select
                      className="form-select form-select-sm w-100"
                      aria-label=".form-select-sm example"
                      onChange={handleRespCenter}
                      name="responsibiltyCenter"
                      value={selectedRespCenter}
                      required
                    >
                      <option value="">Select Responsibility Center</option>
                      {allResponsibilityCenter &&
                        allResponsibilityCenter.map((itemType: any, index: any) => {
                          return (
                            <option
                              key={index.toString()}
                              value={itemType.responsibility_center_code_id}
                            >
                              {itemType.respCenterCodeDesc}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="my-2 w-100 overflow-x-auto rounded-3 mt-5 " style={{ height: "50vh" }}>
        <Menu className="bg-cm-light-primary1" triggerSubMenuAction="click" items={MenuItemsData} style={{ width: "fit-content" }} />
        {/* <Table
          rowKey={"id"}
          pagination={
            reportName === "state"
              ? {
                total: allStatesTotalRecords && allStatesTotalRecords,
                responsive: true,
                current: currentpage,
                showSizeChanger: true,
                pageSizeOptions: [15, 20, 30],
                onChange: (page, offset) => {
                  paginationChange(page, offset);
                },
                pageSize: offset,
              }
              : {
                responsive: true,
              }
          }
          columns={
            reportName === "state"
              ? stateColumn
              : reportName === "country"
                ? countryColumn
                : ""
          }
          dataSource={
            reportName === "state"
              ? stateData
              : reportName === "country"
                ? countryDataSource
                : ""
          }
        /> */}
      </div>
    </div>
  );
};
export default DataGovernance;
