// @ts-nocheck
import { format, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'MM/dd/yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'MM/dd/yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'MM/dd/yyyy hh:mm a');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fTime(date) {
  if (date !== null) {
    return format(new Date(date), 'HH:mm');
  } else {
    return '00:00';
  }
}

export function checkPermissions(permissionArray: Array<string>, userPermissionArray: Array<string>) {
  return userPermissionArray.some((item: string) => permissionArray && permissionArray.indexOf(item) > -1);
}

export function checkPermissions1(permissionArray: Array<string>, userPermissionArray: Array<string>, linkPermission: string) {
  return userPermissionArray.some((item: string) => permissionArray && permissionArray.indexOf(item) > -1 && linkPermission === item);
}

export function checkMatch(reportCode: string, userPermissionArray: Array<string>) {
  return userPermissionArray.includes(reportCode);
}
