export const userManagementBaseUrl = process.env.REACT_APP_USER_MANAGEMENT_BASE_URL

export const dataServiceBaseUrl = process.env.REACT_APP_DATA_SERVICE_BASE_URL

export const webUIBaseUrl = process.env.REACT_APP_WEB_UI_BASE_URL

export const fastApiBaseUrl = process.env.REACT_APP_FAST_API_BASE_URL

export const assessmentAndAuditsUrl = process.env.REACT_APP_ASSESSMENT_AND_AUDITS_URL

export const riskManagementUrl = process.env.REACT_APP_RISK_MANAGEMENT_URL

export const DataLineageUrl = process.env.REACT_APP_DATA_LINEAGE_URL

export const fastApi = {
  calculatePenalty : "/calculate_penalty/",
  algorithmAccuracy : "/analyze_accuracy/",
  calculateFairnessMetrics : "/calculate_fairness_metrics/",
  identifySensitiveColumns: "/identify_sensitive_columns/",
  dataSetBiasTest: "/analyze/",
  featureDominance: "/analyze-csv/",
  generate_graph: "generate_graph",
  employmentbias: "/employmentbias/",
  unstructuredDataBiasTest: "/process-files",
  generatePdf: "/generate-pdf"
}

export const dataService = {

  valueChainCategoryController: {
    getAllValueChainCategory: "/valuechaincategories/with-pagination",
    updateValueChainCategory: "/valuechaincategories",
    deleteValueChainCategoryById: "/valuechaincategories",
    getValueChainCategoryById: "/valuechaincategories",
    saveValueChainCategory: "/valuechaincategories",
    getAllValueChainCategoryWithSearch: "/valuechaincategories/getAllWithSearch"
  },

  valueChainsController: {
    getValueChainsById: "/valuechains",
    updateValueChain: "/valuechains",
    deleteValueChainById: "/valuechains",
    getAllValueChains: "/valuechains/with-pagination",
    saveValueChain: "/valuechains",
    getAllValueChainsWithSearch: "/valuechains/getAllWithSearch",
    getValueChainCategoryCountName: "/valuechains/getCountAndValueChainCategoryName"
  },

  termController: {
    getTermById: "/terms",
    updateTerm: "/terms",
    deleteTermById: "/terms",
    getAllTerm: "/terms/with-pagination",
    saveTerm: "/terms",
    getTermsTermsetIdById: "/terms/termsetid",
    getTermsStringlistTermsetIdById: "/terms/stringlist/termsetid",
    getAllTermWithSearch: "/terms/getAllWithSearch",
    getCountAndTermSetName : "/terms/getCountAndTermSetName",
  },

  termSetController: {
    getTermSetById: "/termsets",
    updateTermSet: "/termsets",
    deleteTermSetById: "/termsets",
    getAllTermSet: "/termsets/with-pagination",
    saveTermSet: "/termsets",
    getAllTermSetWithSearch: "/termsets/getAllWithSearch"
  },

  dataPipelineController: {
    getDataPipelineById: "/datapipelines",
    updateDataPipeline: "/datapipelines",
    deleteDataPipelineById: "/datapipelines",
    getAllDataPipelines: "/datapipelines/with-pagination",
    saveDataPipeline: "/datapipelines",
    getAllDataPipelinesWithSearch: "/datapipelines/getAllWithSearch",
    getCountAndPipelineCategoryName: "datapipelines/getCountAndPipelineCategoryName",
  },

  dataPipelineCategoryController: {
    getDataPipelineCategoryById: "/datapipelinecategories",
    updateDataPipelineCategory: "/datapipelinecategories",
    deleteDataPipelineCategoryById: "/datapipelinecategories",
    getAllDataPipelinesCategories: "/datapipelinecategories/with-pagination",
    saveDataPipelineCategory: "/datapipelinecategories",
    getAllDataPipelinesCategoriesWithSearch: "/datapipelinecategories/getAllWithSearch"
  },

  valueChainParagraphExtractController: {
    getValueChainParagraphExtractById: "/valuechainparagraphextract",
    updateValueChainParagraphExtract: "/valuechainparagraphextract",
    deleteValueChainParagraphExtractById: "/valuechainparagraphextract",
    getAllValueChainParagraphExtract: "/valuechainparagraphextract",
    saveValueChainParagraphExtract: "/valuechainparagraphextract",
    getAllValueChainParagraphExtractWithPagination:
      "/valuechainparagraphextract/with-pagination",
    getAllValueChainParagraphExtractWithSearch: "/valuechainparagraphextract/getAllWithSearch"
  },

  dataRecordController: {
    getAllDataRecords: "/datarecords",
    updateDataRecord: "/datarecords",
    deleteDataRecordById: "/datarecords",
    getAllDataRecord: "/datarecords",
    saveDataRecord: "/datarecords",
    getDataRecordById: "/datarecords",
    getDataRecordsWithPagination: "/datarecords/with-pagination",
    getAllDataRecordsWithSearch: "/datarecords/getAllWithSearch"
  },

  responsibilityGroupController: {
    getAllResponsibilityGroup: "/responsibilityGroup/allResponsibilityGroup",
  },

  responsibilityCenterController: {
    getAllResponsibilityCenter: "/responsibilityCenter/allResponsibilityCenter"
  },

  companyController: {
    getAllCompanies: "/companies/all"
  }
};

export const webUI = {
  webSearchController: {
    getResultByTerms: "/getResultByTerms",
  },
  gdprController: {
    dataPrivacyComplianceTest : "/process/gdpr/data-policy/check",
    aiRegulationComplianceTest: "/process/ai/regulation/check",
    // aiRegulationComplianceTest: "/process/ai/bias/check",
  },
  vulnerabiltyController: {
    codeCyberSecVulAnalysis: "/process/vulnerability/check",
  },
  aiBiasController: {
    codeAiBiasAnalysis: "/process/ai/bias/check",
    codeVulnerability: "/process/ai/vulnerability/check"
  },
  textMatcherController: {
    termComparisonAnalysis: "/process/ai/text-matcher/frequencey",
  }
};

export const userManagement = {
  enumController: {
    getEnumData: "/enums",
    getEnumDataFromCode: "/enums/",
    saveEnumData: "/enums/enums",
    updateEnumData: "/enums/enums/",
  },
  loginController: {
    login: "/login",
    logout: "/logout",
  },
  permissionController: {
    getAllPermissions: "/permissions/findAllPermissions",
  },
  roleController: {
    getRoles: "/roles",
    addRole: "/roles",
    findRoleById: "/roles/",
    updateRole: "/roles/",
    deleteRole: "/roles/",
  },
  userController: {
    addUser: "/users",
    findUserById: "/users",
    updateUser: "/users",
    deleteUser: "/users",
    getUsers: "/users",
    forgotPassword: "/users/forgotPassword",
    resetPassword: "/users/resetPassword",
    uploadUsers: "/users/upload",
    findUsername: "/users/username",
    uploadProfileImage: "/users/uploadProfileImage",
    downloadProfileImage: "/users/downloadProfileImage",
    // uploadMerchantImage:"/users/uploadMerchantImage",
    // downloadMerchantImage:"/users/downloadMerchantImage",
    downloadMerchantImage: "/users/merchantDetails",
    saveMerchant: "/users/saveMerchant",
  },
  userRoleController: {
    getUserRoleById: "/roles",
    updateUserRole: "/roles/updateUserRole",
    deleteUserRoleById: "/roles/deleteUserRole",
    getAllUserRole: "/roles/findAllUserRole",
    saveUserRole: "/roles",
  },
  userGroupController: {
    getUserGroupById: "/userGroup/findUserGroup",
    updateUserGroup: "/userGroup/updateUserGroup",
    deleteUserGroupById: "/userGroup/deleteUserGroup",
    getAllUserGroup: "/userGroup/findAllUserGroup",
    saveUserGroup: "/userGroup/saveUserGroup",
  },
  usersController: {
    getUserById: "/users",
    updateUser: "/users",
    deleteUserById: "/users",
    getAllUser: "/users",
    saveUser: "/users",
    resetPassword: "/users/resetPassword",
    getAllUsersWithSearch: "/users/getAllUsersWithSearch",
  },
  countryController: {
    getAllCountries: "/country/getAllCountries",
    saveCountry: "/country/saveCountry",
    updateCountry: "/country/updateCountry",
    deleteCountry: "/country/deleteCountry",
    getCountryById: "/country/getCountryById",
    getAllCountriesWithSearch: "/country/getAllCountriesWithSearch",
  },
  cityController: {
    getAllCities: "/city/getAllCities",
  },
  stateController: {
    getState: "/state/getState",
    saveState: "/state/saveState",
    updateState: "/state/updateState",
    deleteState: "/state/deleteState",
    getStateById: "/state/getStateById",
    getAllStates: "/state/getAllStates",
    getAllStatesWithSearch: "/state/getAllStatesWithSearch",
  },
  auditLogController: {
    saveAuditLog: "auditLog/save",
    getAuditLog: "auditLog/find"
  },
};