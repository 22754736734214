import { useLocation } from "react-router-dom";
import { REPORT_TYPE } from "../../constant/reportType";
import UserMaster from "../../component/UserMaster";
import DataSourceManagementMaster from "../../component/DataSourceManagementMaster";
import Common from "../../component/Common";
import PrivacyGuardPro from "../../component/PrivacyGuardPro";
import CyberSecAIShield from "../../component/CyberSecAIShield";
import BiasMitigatePro from "../../component/BiasMitigatePro";
import PredictionModels from "../../component/PredictionModels";
import DataGovernance from "../../component/DataGovernance";

type Props = {};

const AllAIGovernanceReports = (props: Props) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const report = searchParams.get("report")

  if (report == REPORT_TYPE.DATA_GOVERNANCE) {
    return (
      <DataGovernance />
    )
  } else if (report == REPORT_TYPE.ASSESSMENT_AND_AUDITS) {
    return (
      <div>{REPORT_TYPE.ASSESSMENT_AND_AUDITS}</div>
      // <CyberSecAIShield />
    )
  } else if (report == REPORT_TYPE.RISK_MANAGEMENT) {
    return (
      // <BiasMitigatePro />
      <div>{REPORT_TYPE.RISK_MANAGEMENT}</div>
    )
  } else {
    return (
      <div>Something went wrong</div>
    )
  }

};
export default AllAIGovernanceReports;
