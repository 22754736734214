import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { Select, Switch, Table } from "antd";
import { useAppSelector } from "../redux-toolkit/hooks";
import { useDispatch } from "react-redux";
import { REPORT_NAME } from "../constant/reportType";
import { DATA_PRIVACY_COMPLIANCE_TEST, AI_REGULATION_COMPLIANCE_TEST, SENSITIVE_AND_BIAS_DATA_IDENTIFICATION_COLUMN, DATA_PRIVACY_PENALTY_PREDICTION_COLUMN, EMPLOYMENT_BIAS_COLUMN } from "../constant/AppConstant";
import { getAllStatesTotalRecords } from "../redux-toolkit/slices/statesSlice";
import { getLocalStorageData } from "../utils/LocalStorageService";
import PrivacyGuardProData from "../pages/reports/PrivacyGuardProData";
import { getAiRegComplianceTestDetails, getDataPrivacyPenaltyPredictionDetails, getGdprDetails, getSensitiveBiasDataIdentificationDetails } from "../redux-toolkit/slices/gdprSlice";
import { getEmploymentBiasDetails } from "../redux-toolkit/slices/fastApiSlice";
import BiasAndVulnerabiltySummary from "./BiasAndVulnerabiltySummary";
import { Excel } from "antd-table-saveas-excel";

const { Option } = Select;
type Props = {};

const PrivacyGuardPro = (props: Props) => {
  const params = useParams();
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // States
  const [show, setShow] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [reportName, setReportName]: any = useState();
  const [fileName, setFileName]: any = useState();
  const [dataPrivacyPieData, setdDtaPrivacyPieData]: any = useState([]);
  const [aiRegulationPieData, setAiRegulationPieData]: any = useState([]);

  //columns
  const [dataPrivacyCompTestColumn, setDataPrivacyCompTestColumn]: any = useState(DATA_PRIVACY_COMPLIANCE_TEST);
  const [aiRegCompliancecTestColumn, setAiRegCompliancecTestColumn]: any = useState(AI_REGULATION_COMPLIANCE_TEST);
  const [sensitiveBiasDataIdenColumn, setSensitiveBiasDataIdenColumn]: any = useState(SENSITIVE_AND_BIAS_DATA_IDENTIFICATION_COLUMN);
  const [dataPrivacyPenaltyPredColumn, setDataPrivacyPenaltyPredColumn]: any = useState(DATA_PRIVACY_PENALTY_PREDICTION_COLUMN);
  const [employmentBiasColumn, setEmploymentBiasColumn]: any = useState(EMPLOYMENT_BIAS_COLUMN);

  //datasource
  const [datasource, setDatasource]: any = useState([]);
  const [AIRegComplianceTestDatasource, setAIRegComplianceTestDatasource]: any = useState([]);
  const [sensitiveBiasDataIdenDatasource, setSensitiveBiasDataIdenDatasource]: any = useState([]);
  const [dataPrivacyPenaltyPredDatasource, setDataPrivacyPenaltyPredDatasource]: any = useState([]);
  const [employmentBiasDataSource, setEmploymentBiasDataSource]: any = useState([]);

  //selector
  const allStatesTotalRecords: any = useAppSelector(getAllStatesTotalRecords);
  const getGdprDetailsData: any = useAppSelector(getGdprDetails);
  const getAIRegComplianceTestDetails: any = useAppSelector(getAiRegComplianceTestDetails);
  const getDataPrivacyPenaltyPrediction: any = useAppSelector(getDataPrivacyPenaltyPredictionDetails);
  const getSensitiveBiasDataIdentification: any = useAppSelector(getSensitiveBiasDataIdentificationDetails);
  const getEmploymentBias: any = useAppSelector(getEmploymentBiasDetails);

  useEffect(() => {

    switch (params.reportType) {
      case REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST:
        setReportName(REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST);
        break;
      case REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION:
        setReportName(REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION);
        break;
      case REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION:
        setReportName(REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION);

        break;
      case REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST:
        setReportName(REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST);
        break;
      case REPORT_NAME.CODE_AI_BIAS_ANALYSIS:
        setReportName(REPORT_NAME.CODE_AI_BIAS_ANALYSIS);
        break;
      case REPORT_NAME.EMPLOYMENT_BIAS:
        setReportName(REPORT_NAME.EMPLOYMENT_BIAS);
        break;
      default:
    }
  }, []);

  const paginationChange = (page: number, offset: number) => {
    if (reportName === "state") {

    }
    setCurrentPage(page);
    setOffset(offset);
  };

  const handleShow = () => setShow(true);

  // navigate............
  const handleBack = () => {
    navigate("/complianceReports");
  };

  //data privacy compliance test
  useEffect(() => {
    if (getGdprDetailsData) {
      setDatasource(getGdprDetailsData);

      const data: any = [];
      getGdprDetailsData.map((element: any) => {
        const parts = (element.file_name).split("//");
        const splittedfileName = parts[parts.length - 1];
        data.push({
          fileName: splittedfileName,
          status: element.status
        })
      })

      const results: any = [];

      for (const file of data) {
        const fileName = file.fileName;
        const status = file.status;

        let found = false;
        for (const result of results) {
          if (result.fileName === fileName) {
            found = true;
            result.count[status === "Pass" ? 0 : 1]++;
            result.status = ["Pass", "Fail"];
            break;
          }
        }

        if (!found) {
          results.push({
            fileName,
            count: [status === "Pass" ? 1 : 0, status === "Fail" ? 1 : 0],
            status: ["Pass", "Fail"],
          });
        }
      }

      setdDtaPrivacyPieData(results);
    }
  }, [getGdprDetailsData]);

  useEffect(() => {
    if (getAIRegComplianceTestDetails) {
      setAIRegComplianceTestDatasource(getAIRegComplianceTestDetails);

      const data: any = [];
      getAIRegComplianceTestDetails.map((element: any) => {
        const parts = (element.file_name).split("//");
        const splittedfileName = parts[parts.length - 1];
        data.push({
          fileName: splittedfileName,
          status: element.status
        })
      })

      const results: any = [];

      for (const file of data) {
        const fileName = file.fileName;
        const status = file.status;

        let found = false;
        for (const result of results) {
          if (result.fileName === fileName) {
            found = true;
            result.count[status === "Pass" ? 0 : 1]++;
            result.status = ["Pass", "Fail"];
            break;
          }
        }

        if (!found) {
          results.push({
            fileName,
            count: [status === "Pass" ? 1 : 0, status === "Fail" ? 1 : 0],
            status: ["Pass", "Fail"],
          });
        }
      }

      setAiRegulationPieData(results);
    }
  }, [getAIRegComplianceTestDetails]);

  useEffect(() => {
    if (getDataPrivacyPenaltyPrediction) {
      setDataPrivacyPenaltyPredDatasource(getDataPrivacyPenaltyPrediction);
    }
  }, [getDataPrivacyPenaltyPrediction]);

  useEffect(() => {
    if (getSensitiveBiasDataIdentification) {
      let sensitiveAndBiasDataIdentificationArr = Object.entries(getSensitiveBiasDataIdentification);

      let temp: any = [];
      sensitiveAndBiasDataIdentificationArr?.forEach((element: any, i: any) => {
        if (element[0] === "Recommendations") {

          let recommendationsData = Object.keys(element[1]).map(key => {
            return (
              <div>
                <p><span className="fw-bold">{key}:</span> {element[1][key]} </p>
                {/* `${key}:${element[1][key]}`).join("."); */}
              </div>
            )
          })

          temp.push({
            category: element[0],
            recommendations: recommendationsData
          })
        } else if (element[0] !== "Recommendations") {
          temp.push({
            category: element[0],
            recommendations: Array.isArray(element[1]) ? element[1].join(",") : ""
          })
        }
      });
      // props.setSensitiveBiasDataIdenDatasource(temp);
      setSensitiveBiasDataIdenDatasource(temp);
    }
  }, [getSensitiveBiasDataIdentification]);

  useEffect(() => {
    if (getEmploymentBias) {
      let employmentBiasArr = Object.entries(getEmploymentBias);
      let temp: any = [];
      employmentBiasArr?.forEach((element: any, i: any) => {

        if (element[0] === "Salary_Disparity") {
          let ageDisparityData = Object.keys(element[1]).map(key => {
            let meanSalaryByGenderData;
            if (typeof element[1][key] === "object") {
              meanSalaryByGenderData = Object.keys(element[1]["Mean_Salary_by_Gender"]).map(key => {
                return (
                  <div>
                    <p><span className="fw-bold">{key}:</span> {(element[1]["Mean_Salary_by_Gender"][key]).toFixed(2)} </p>
                  </div>
                )
              })
            }
            temp.push({
              category: element[0],
              category1: key,
              values: typeof element[1][key] === "object" ? meanSalaryByGenderData : typeof element[1][key] === "number" ? (element[1][key]).toFixed(2) : element[1][key]
              // inference: element[1]["Inference"],
              // meanSalaryByGender: meanSalaryByGenderData,
              // percentageSalaryGapByGender: element[1]["Percentage_Salary_Gap_by_Gender"],
              // salaryGapByGender: element[1]["Salary_Gap_by_Gender"],
              // tTestPValueForSalaryDisparity: element[1]["T-test_p-value_for_Salary_Disparity"]
            })
            // }
          })

        } else if (element[0] === "Test_Score_Disparity") {
          let testScoreData = Object.keys(element[1]).map(key => {
            let meanTestScoreByData;
            if (typeof element[1][key] === "object") {
              meanTestScoreByData = Object.keys(element[1]["Mean_Test_Score_by_Gender"]).map(key => {
                return (
                  <div>
                    <p><span className="fw-bold">{key}:</span> {(element[1]["Mean_Test_Score_by_Gender"][key]).toFixed(2)} </p>
                  </div>
                )
              })
            }
            temp.push({
              category: element[0],
              category1: key,
              values: typeof element[1][key] === "object" ? meanTestScoreByData : typeof element[1][key] === "number" ? (element[1][key]).toFixed(2) : element[1][key]
            })
          })

        } else if (element[0] === "Promotion_Disparity") {
          let promotionData = Object.keys(element[1]).map(key => {
            let mean;
            if (typeof element[1][key] === "object") {
              mean = Object.keys(element[1]["Promotion_Rate_by_Gender"]).map(key => {
                return (
                  <div>
                    <p><span className="fw-bold">{key}:</span> {(element[1]["Promotion_Rate_by_Gender"][key].toFixed(2))} </p>
                  </div>
                )
              })
            }
            temp.push({
              category: element[0],
              category1: key,
              values: typeof element[1][key] === "object" ? mean : typeof element[1][key] === "number" ? (element[1][key]).toFixed(2) : element[1][key]
            })
          })
        } else if (element[0] === "Positive_Outcome_Disparity") {
          let data = Object.keys(element[1]).map(key => {
            let mean;
            if (typeof element[1][key] === "object") {
              mean = Object.keys(element[1]["Positive_Outcome_Rate_by_Protected_Category"]).map(key => {
                return (
                  <div>
                    <p><span className="fw-bold">{key}:</span> {(element[1]["Positive_Outcome_Rate_by_Protected_Category"][key]).toFixed(2)} </p>
                  </div>
                )
              })
            }
            temp.push({
              category: element[0],
              category1: key,
              values: typeof element[1][key] === "object" ? mean : typeof element[1][key] === "number" ? (element[1][key]).toFixed(2) : element[1][key]
            })
          })
        } else if (element[0] === "Risk_Assessment_Disparity") {
          let data = Object.keys(element[1]).map(key => {
            let mean;
            if (typeof element[1][key] === "object") {
              mean = Object.keys(element[1]["Positive_Outcome_Rate_by_Risk_Level"]).map(key => {
                return (
                  <div>
                    <p><span className="fw-bold">{key}:</span> {(element[1]["Positive_Outcome_Rate_by_Risk_Level"][key]).toFixed(2)} </p>
                  </div>
                )
              })
            }
            temp.push({
              category: element[0],
              category1: key,
              values: typeof element[1][key] === "object" ? mean : typeof element[1][key] === "number" ? (element[1][key]).toFixed(2) : element[1][key]
            })
          })
        } else if (element[0] === "Testing_Dataset_Disparity") {
          let data = Object.keys(element[1]).map(key => {
            let mean;
            if (typeof element[1][key] === "object") {
              mean = Object.keys(element[1]["Positive_Outcome_Rate_by_Testing_Dataset"]).map(key => {
                return (
                  <div>
                    <p><span className="fw-bold">{key}:</span> {(element[1]["Positive_Outcome_Rate_by_Testing_Dataset"][key]).toFixed(2)} </p>
                  </div>
                )
              })
            }
            temp.push({
              category: element[0],
              category1: key,
              values: typeof element[1][key] === "object" ? mean : typeof element[1][key] === "number" ? (element[1][key]).toFixed(2) : element[1][key]
            })
          })
        } else if (element[0] === "Uncertainty_Analysis_Disparity") {
          let data = Object.keys(element[1]).map(key => {
            let mean;
            if (typeof element[1][key] === "object") {
              mean = Object.keys(element[1]["Mean_Uncertainty_by_Risk_Level"]).map(key => {
                return (
                  <div>
                    <p><span className="fw-bold">{key}:</span> {(element[1]["Mean_Uncertainty_by_Risk_Level"][key]).toFixed(2)} </p>
                  </div>
                )
              })
            }
            temp.push({
              category: element[0],
              category1: key,
              values: typeof element[1][key] === "object" ? mean : typeof element[1][key] === "number" ? (element[1][key]).toFixed(2) : element[1][key]
            })
          })
        } else if (element[0] === "Race/Ethnicity_Disparity") {
          let data = Object.keys(element[1]).map(key => {
            let mean;
            if (typeof element[1][key] === "object") {
              mean = Object.keys(element[1]["Mean_Salary_by_Race/Ethnicity"]).map(key => {
                return (
                  <div>
                    <p><span className="fw-bold">{key}:</span> {(element[1]["Mean_Salary_by_Race/Ethnicity"][key]).toFixed(2)} </p>
                  </div>
                )
              })
            }
            temp.push({
              category: element[0],
              category1: key,
              values: typeof element[1][key] === "object" ? mean : typeof element[1][key] === "number" ? (element[1][key]).toFixed(2) : element[1][key]
            })
          })
        } else if (element[0] === "Age_Disparity") {
          let data = Object.keys(element[1]).map(key => {
            let mean;
            if (typeof element[1][key] === "object") {
              mean = Object.keys(element[1]["Mean_Age_by_Gender"]).map(key => {
                return (
                  <div>
                    <p><span className="fw-bold">{key}:</span> {(element[1]["Mean_Age_by_Gender"][key]).toFixed(2)} </p>
                  </div>
                )
              })
            }
            temp.push({
              category: element[0],
              category1: key,
              values: typeof element[1][key] === "object" ? mean : typeof element[1][key] === "number" ? (element[1][key]).toFixed(2) : element[1][key]
            })
          })
        } else if (element[0] === "EducationLevel_Disparity") {
          let data = Object.keys(element[1]).map(key => {
            let mean;
            if (typeof element[1][key] === "object") {
              mean = Object.keys(element[1]["Mean_Salary_by_EducationLevel"]).map(key => {
                return (
                  <div>
                    <p><span className="fw-bold">{key}:</span> {(element[1]["Mean_Salary_by_EducationLevel"][key]).toFixed(2)} </p>
                  </div>
                )
              })
            }
            temp.push({
              category: element[0],
              category1: key,
              values: typeof element[1][key] === "object" ? mean : typeof element[1][key] === "number" ? (element[1][key]).toFixed(2) : element[1][key]
            })
          })
        }
      })
      setEmploymentBiasDataSource(temp);
    }
  }, [getEmploymentBias]);

  const handleExportClick = () => {
    let columns: any;
    let dataSource: any;
    let fileName: any;
    if (reportName === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST) {
      fileName = REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST;
      columns = dataPrivacyCompTestColumn;
      dataSource = datasource;
    } else if (reportName === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST) {
      fileName = REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST
      columns = aiRegCompliancecTestColumn;
      dataSource = AIRegComplianceTestDatasource;
    } else if (reportName === REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION) {
      fileName = REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION
      columns = sensitiveBiasDataIdenColumn;
      dataSource = sensitiveBiasDataIdenDatasource;
    } else if (reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION) {
      fileName = REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION
      columns = dataPrivacyPenaltyPredColumn;
      dataSource = dataPrivacyPenaltyPredDatasource
    } else if (reportName === REPORT_NAME.EMPLOYMENT_BIAS) {
      fileName = REPORT_NAME.EMPLOYMENT_BIAS;
      columns = employmentBiasColumn;
      dataSource = employmentBiasDataSource;
    }
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(columns)
      .addDataSource(dataSource, {
        str2Percent: true
      })
      .saveAs(`${fileName}.xlsx`);
  };

  return (
    <div className="mt-2 overflow-y-auto">
      {/* navigate */}
      <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
        <div className="d-flex align-items-center w-100 px-2">
          <h5
            className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
            style={{ fontWeight: "700" }}
          >
            {params.reportType}
          </h5>
        </div>
        <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
          <div onClick={handleShow} className="d-flex px-2 align-items-center mt-1">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0 text-nowrap"
            // onClick={() => setActionType(ACTION_TYPE.ADD_ACTION)}
            >
              {/* <BiPlus /> */}
              New Request
            </Button>
          </div>
          {show && (
            <PrivacyGuardProData
              show={show}
              setShow={setShow}
              reportName={params.reportType}
              reportNameCode={reportName}
              setDatasource={setDatasource}
              setAIRegComplianceTestDatasource={setAIRegComplianceTestDatasource}
              setSensitiveBiasDataIdenDatasource={setSensitiveBiasDataIdenDatasource}
              setDataPrivacyPenaltyPredDatasource={setDataPrivacyPenaltyPredDatasource}
              setEmploymentBiasDataSource={setEmploymentBiasDataSource}
              setFileName={setFileName}
            />
          )}
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="primary mt-2 mt-md-0 d-flex justify-content-md-start w-100 mx-2">
          <Button
            variant="primary"
            className="d-flex align-items-center me-0"
            onClick={handleBack}
          >
            Back
          </Button>
          <div className="primary mt-2 mt-md-0 d-flex justify-content-end w-100 mx-2">
            <Button
              variant="primary"
              className="d-flex align-items-center me-0"
              onClick={handleExportClick}
            >
              Export
            </Button>
          </div>
        </div>
        {fileName && (
          <p className="mt-2 mx-2 fw-bold">{fileName}</p>
        )
        }
      </div>
      <div className="my-2 w-100 overflow-x-auto rounded-3">
        <Table
          bordered={true}
          rowKey={(obj: any, i: any) => {
            return i
          }}
          // rowKey={"file_name"}
          pagination={
            reportName === "state"
              ? {
                total: allStatesTotalRecords && allStatesTotalRecords,
                responsive: true,
                current: currentpage,
                showSizeChanger: true,
                pageSizeOptions: [15, 20, 30],
                onChange: (page, offset) => {
                  paginationChange(page, offset);
                },
                pageSize: offset,
              }
              : {
                responsive: true,
              }
          }
          columns={
            reportName === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST
              ? dataPrivacyCompTestColumn
              : reportName === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST
                ? aiRegCompliancecTestColumn
                : reportName === REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION
                  ? sensitiveBiasDataIdenColumn
                  : reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION
                    ? dataPrivacyPenaltyPredColumn
                    : reportName === REPORT_NAME.EMPLOYMENT_BIAS
                      ? employmentBiasColumn
                      : ""
          }
          dataSource={
            reportName === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST
              ? datasource
              : reportName === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST
                ? AIRegComplianceTestDatasource
                : reportName === REPORT_NAME.SENSITIVE_AND_BIAS_DATA_IDENTIFICATION
                  ? sensitiveBiasDataIdenDatasource
                  : reportName === REPORT_NAME.DATA_PRIVACY_PENALTY_PREDICTION
                    ? dataPrivacyPenaltyPredDatasource
                    : reportName === REPORT_NAME.EMPLOYMENT_BIAS
                      ? employmentBiasDataSource
                      : ""
          }
        />
      </div>
      {reportName === REPORT_NAME.DATA_PRIVACY_COMPLIANCE_TEST ? (

        (dataPrivacyPieData.map((element: any) => {
          return (
            <div className="">
              {/* <p className="fw-bolder text-cm-active-primary1">{REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY}</p> */}
              <div className="shadow-lg p-3 mb-5 bg-body rounded overflow-scroll">
                <BiasAndVulnerabiltySummary title={element.fileName} chartSerires={element.count} chartLables={element.status} />
              </div>
            </div>
          )
        }))
      ) : null}
      {reportName === REPORT_NAME.AI_REGULATION_COMPLIANCE_TEST ? (
        (aiRegulationPieData.map((element: any) => {
          return (
            <div className="">
              {/* <p className="fw-bolder text-cm-active-primary1">{REPORT_NAME.PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY}</p> */}
              <div className="shadow-lg p-3 mb-5 bg-body rounded overflow-scroll">
                <BiasAndVulnerabiltySummary title={element.fileName} chartSerires={element.count} chartLables={element.status} />
              </div>
            </div>
          )
        }))
      ) : null}
    </div>
  );
};
export default PrivacyGuardPro;
