import { Tooltip } from "antd";
import { HashLink } from "react-router-hash-link";
import { fDateTimeSuffix, fTime } from "../component/utils";
import { Link } from "react-router-dom";

export enum USER_ROLE {
  ADMIN = "Admin",
  CORPORATE = "Corporate",
  USER = "User",
  ADMINISTRATOR = "Administrator",
  GOVERNMENT = "Government",
  ACADEMICIAN = "Academician",
  NGO = "NGO",
  INNOVATOR = "Innovator",
  STARTUP = "Startup",
  STUDENT = "Student",
  BETA_TEST_USER = "Beta Test User"
}

export enum PAGE_TITLE {
  FORGOT_PASSWORD = "Forgot Password",
  RESET_PASSWORD = "Reset Password",
  DASHBOARD = "Dashboard",
  MASTER = "Master",
  COMPLIANCE_TEST = "Compliance Test",
  AI_GOVERNANCE_TITLE = "AI Governance"
}

export const HEADER_ITEMS = [
  {
    type: "link",
    title: (
      <HashLink smooth to="/#About-Us">
        About Us
      </HashLink>
    ),
    link: "/About-Us",
  },
  {
    type: "link",
    title: (
      <HashLink smooth to="/#Value -Proposition">
        Value Proposition
      </HashLink>
    ),
    link: "/Value -Proposition",
  },
  {
    type: "link",
    title: (
      <HashLink smooth to="/#ReguVista">
        ReguVista
      </HashLink>
    ),
    link: "/ReguVista",
  },
];

export const ALL_SIDE_NAV_LIST = [
  {
    type: "link",
    title: (
      <HashLink smooth to="/#About-Us">
        About Us
      </HashLink>
    ),
    link: "/About-Us",
  },
  {
    type: "link",
    title: (
      <HashLink smooth to="/#Value -Proposition">
        Value Proposition
      </HashLink>
    ),
    link: "/Value -Proposition",
  },
  {
    type: "link",
    title: (
      <HashLink smooth to="/#ReguVista">
        ReguVista
      </HashLink>
    ),
    link: "/ReguVista",
  },
];

export const VALUE_CHAIN_CATEGORY_COLUMN = [
  {
    // key: "categoryId",
    dataIndex: "id",
    title: "Category Id",
  },
  {
    // key: "categoryName",
    dataIndex: "name",
    title: "Category Name",
  },
];

export const VALUE_CHAIN_CATEGORY_DATASOURCE = [
  {
    // key: 1,
    categoryId: 1,
    categoryName: "xzyz",
  },
  {
    // key: 2,
    categoryId: 2,
    categoryName: "abcd",
  },
];

export const VALUE_CHAIN_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Value Chain Id",
  },
  {
    key: "categoryName",
    dataIndex: "categoryName",
    title: "Value Chain Category Name",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Value Chain Name",
  },
];

export const TERM_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Term Id",
  },
  {
    key: "termSetName",
    dataIndex: "termsetName",
    title: "Term Set Name",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Term Name",
  },
];

export const TERM_SET_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Term Set Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Term Set Name",
  },
];

export const VALUE_CHAIN_DATASOURCE = [
  {
    key: 1,
    valueChainId: 1,
    valueChainName: "xzyz",
  },
  {
    key: 2,
    valueChainId: 2,
    valueChainName: "abcd",
  },
];

export const DATA_PIPELINE_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Data Pipeline Id",
  },
  {
    key: "pipelineCategoryName",
    dataIndex: "pipelineCategoryName",
    title: "Data Pipeline Category Name",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Data Pipeline Name",
  },
];

export const DATA_PIPELINE_CATEGORY_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Data Pipeline Category Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Data Pipeline Category Name",
  },
];

export const USER_ROLE_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Subscriber Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Subscriber Name",
  },
];
export const USER_GROUP_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "User Group Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "User Group Name",
  },
];

export const USER_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "User Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Name",
  },
  {
    key: "mobileNo",
    dataIndex: "phoneNumber",
    title: "Mobile Number",
  },
  {
    key: "email",
    dataIndex: "email",
    title: "Email",
  },
  {
    key: "role",
    dataIndex: "userRole",
    title: "Subscriber",
  },
  {
    key: "group",
    dataIndex: "userGroup",
    title: "User Group",
  },
];

export const VALUE_CHAIN_PARAGRAPH_EXTRACT_COLULMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Id",
    width: 80,
  },
  {
    key: "valueChain",
    dataIndex: "valueChain",
    title: "Value Chain",
    width: 80,
  },
  {
    key: "dataRecord",
    dataIndex: "dataRecord",
    title: "Data Record",
    width: 80,
  },
  {
    key: "dataContentLink",
    dataIndex: "dataContentLink",
    title: "Data Content Link",
    // ellipsis: true,
    width: 300,
    render: (index: any, record: any) => {
      return (
        <a className="link-primary text-decoration-underline" onClick={() => {
          window.open(record.dataContentLink, '_blank')
        }}>
          {record.dataContentLink}
        </a>
      );
    },
  },
  {
    key: "paragraphContent",
    dataIndex: "paragraphContent",
    title: "Paragraph Content",
    ellipsis: true,
    width: 400,
    // render: (text: any) => {
    //   return (
    //     <>
    //       <p>{text.split("@")[0]}</p>
    //       <p>{text.split("@")[1]}</p>
    //     </>)
    // }
  },
];

export const DATA_RECORDS_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Id",
    width: 80,
  },
  {
    key: "dataContentLink",
    dataIndex: "dataContentLink",
    title: "Data Content Link",
    // ellipsis: true,
    width: 300,
    render: (index: any, record: any) => {
      return (
        <a className="link-primary text-decoration-underline" onClick={() => {
          window.open(record.dataContentLink, '_blank')
        }}>
          {record.dataContentLink}
        </a>
      );
    },
  },
  {
    key: "dataContent",
    dataIndex: "dataContent",
    title: "Data Content",
    ellipsis: true,
    width: 500,
    // ellipsis: {
    //   showTitle: false,
    // },
    // render: (dataContent: any) => (
    //   <Tooltip placement="topLeft" title={dataContent}>
    //     {dataContent}
    //   </Tooltip>
    // ),
  },
  {
    key: "valueChainCategoryName",
    dataIndex: "valueChainCategoryName",
    title: "Value Chain Category Name",
    width: 350,
  },
  {
    key: "valueChainName",
    dataIndex: "valueChainName",
    title: "Value Chain Name",
    width: 350,
  },
  {
    key: "termSetName",
    dataIndex: "termSetName",
    title: "Term Set Name",
    width: 350,
  },
  {
    key: "country",
    dataIndex: "country",
    title: "Country",
    width: 150,
  },
  {
    key: "state",
    dataIndex: "state",
    title: "State",
    width: 150,
  },
  {
    key: "city",
    dataIndex: "city",
    title: "City",
    width: 150,
  },
  {
    key: "createdOn",
    dataIndex: "createdOn",
    title: "Data Extracted",
    width: 180,
  },
  {
    key: "dataQualityStatus",
    dataIndex: "dataQualityStatus",
    title: "Data Quality Status",
    width: 80,
  },
  {
    key: "sourcecategoryid",
    dataIndex: "sourcecategoryid",
    title: "Source Category Id",
    width: 80,
  },
];

export const COUNTRY_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "Country Id",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "Country Name",
  },
];

export const STATE_COLUMN = [
  {
    key: "id",
    dataIndex: "id",
    title: "State Id",
  },
  {
    key: "name",
    dataIndex: "countryName",
    title: "Country Name",
  },
  {
    key: "name",
    dataIndex: "name",
    title: "State Name",
  },
];

export const SENSITIVE_BIAS_DATA_IDENTIFICATION_COLUMN = [
  {
    key: "dataType",
    dataIndex: "dataType",
    title: "Data Type",
  },
  {
    key: "columnsIdentified",
    dataIndex: "columnsIdentified",
    title: "Columns Identified",
  },
  {
    key: "recommendations ",
    dataIndex: "recommendations ",
    title: "Recommendations ",
  },
]

export const DATA_PRIVACY_COMPLIANCE_TEST = [
  {
    key: "file_name",
    dataIndex: "file_name",
    title: "File name",
    render: (index: any, record: any) => {
      const parts = (record.file_name).split("//");
      const splittedfileName = parts[parts.length - 1];
      return (
        <p>
          {splittedfileName}
        </p>
      );
    },
  },
  {
    key: "article_no",
    dataIndex: "article_no",
    title: "Article No",
    render: (index: any, record: any) => {
      const parts = (record.article_no).split("$");
      const articleNo = parts[parts.length - 1];

      const articleLink = record.article_no.substring(0, record.article_no.indexOf('$'));

      return (
        <a className="link-primary text-decoration-underline" onClick={() => {
          window.open(articleLink, '_blank')
        }}>
          {articleNo}
        </a>
      );
    },
  },
  {
    key: "terms",
    dataIndex: "terms",
    title: "Terms",
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status ",
    render: (index: any, record: any) => {
      const statusRemark = record.status === "Pass" ? "Strength" : "Opportunities"
      return (
        <p>{statusRemark}</p>
      );
    },
  },
  {
    key: "data",
    dataIndex: "data",
    title: "Data",
    // ellipsis: true,
    // width: 500,
  },
]

function removeLastComma(strng: any) {
  var n = strng.lastIndexOf(",");
  var a = strng.substring(0, n)
  return a;
}

const termClicked = (term: any) => {

  // const terms = removeLastComma(term)
  // console.log(terms,'terms')
  localStorage.setItem("terms", term)
}

export const AI_REGULATION_COMPLIANCE_TEST = [
  {
    key: "file_name",
    dataIndex: "file_name",
    title: "File name",
    render: (index: any, record: any) => {
      const parts = (record.file_name).split("//");
      const splittedfileName = parts[parts.length - 1];
      return (
        <p>
          {splittedfileName}
        </p>
      );
    },
  },
  {
    key: "checklist_points",
    dataIndex: "checklist_points",
    title: "Checklist points",
  },
  {
    key: "terms",
    dataIndex: "terms",
    title: "Terms",
    render: (index: any, record: any) => {

      return (
        // <a className="link-primary text-decoration-underline" onClick={() => {
        //   window.open(`/ai-governance?terms=${record.terms}`)
        // }}>
        //   {record.terms}
        // </a>
        <Link to={'/ai-governance'}
          onClick={() => termClicked(record.terms)}
          target="_blank"
        >
          {record.terms}
        </Link>

      );
    },
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status ",
    render: (index: any, record: any) => {
      const statusRemark = record.status === "Pass" ? "Strength" : "Opportunities"
      return (
        <p>{statusRemark}</p>
      );
    },
  },
  {
    key: "description",
    dataIndex: "description",
    title: "Description",
    // ellipsis: true,
    // width: 500,
  },
  {
    key: "data",
    dataIndex: "data",
    title: "Data",
  },
]

export const DATA_SET_BIAS_TEST_COLUMN = [
  {
    key: "column",
    dataIndex: "column",
    title: "Column",
  },
  {
    key: "biasAnalysis",
    dataIndex: "biasAnalysis",
    title: "Bias Analysis",
  },
  {
    key: "maxPercentage",
    dataIndex: "maxPercentage",
    title: "Max Percentage",
  },
  {
    key: "maxValue",
    dataIndex: "maxValue",
    title: "Max Value",
  },
  {
    key: "fairnessScore",
    dataIndex: "fairnessScore",
    title: "Fairness Score",
  },
  {
    key: "explicitFairnessFormula",
    dataIndex: "explicitFairnessFormula",
    title: "Explicit Fairness Formula",
  },
]

export const AI_ALGORITHM_ACCURACY_RELIABILITY_TEST_COLUMN = [
  {
    key: "algorithmName",
    dataIndex: "algorithmName",
    title: "Algorithm Name",
  },
  {
    key: "dataset",
    dataIndex: "dataset",
    title: "Dataset",
  },
  {
    key: "variable",
    dataIndex: "variable",
    title: "Variable",
  },
  {
    key: "accuracy",
    dataIndex: "accuracy",
    title: "Accuracy",
  },
  {
    key: "reliability",
    dataIndex: "reliability",
    title: "Reliability",
  },
]

export const DATA_PRIVACY_PENALTY_PREDICTION_COLUMN = [
  // {
  //   key: "srNo",
  //   dataIndex: "srNo",
  //   title: "Sr No",
  // },
  {
    key: "Country",
    dataIndex: "Country",
    title: "Country",
  },
  {
    key: "Data Privacy Regulation",
    dataIndex: "Data Privacy Regulation",
    title: "Data Privacy Regulation"
  },
  {
    key: "Penalty",
    dataIndex: "Penalty",
    title: "Penalty",
  },
  {
    key: "Currency",
    dataIndex: "Currency",
    title: "Currency",
  },
]

// export const FAIRNESS_METRICS_FOR_MACHINE_LEARNING_COLUMN = [
//   {
//     key: "metric",
//     dataIndex: "metric",
//     title: "Metric",
//   },
//   {
//     key: "value",
//     dataIndex: "value",
//     title: "Value",
//     render: (index: any, record: any) => {
//       let valueData = Object.keys(record["value"]).map(key => {
//         return (
//           <div>
//             <p>{key}:{record["value"][key]} </p>
//             {/* `${key}:${element[1][key]}`).join("."); */}
//           </div>
//         )
//       })
//       return (
//         <div>
//           {valueData}
//         </div>
//       );
//     },
//   },
// ]

export const FAIRNESS_METRICS_FOR_MACHINE_LEARNING_COLUMN = [
  // {
  //   key: "model",
  //   dataIndex: "model",
  //   title: "Model",
  // },
  {
    key: "metric",
    dataIndex: "metric",
    title: "Metric",
  },
  {
    key: "FDR",
    dataIndex: "FDR",
    title: "False Discovery Rate (FDR)",
  },
  {
    key: "FDRAnalysis",
    dataIndex: "FDRAnalysis",
    title: "False Discovery Rate Analysis",
  },
  {
    key: "FNR",
    dataIndex: "FNR",
    title: "False Negative Rate (FNR)",
  },
  {
    key: "FNRAnalysis",
    dataIndex: "FNRAnalysis",
    title: "False Negative Rate Analysis",
  },
  {
    key: "FPR",
    dataIndex: "FPR",
    title: "False Positive Rate",
  },
  {
    key: "FPRAnalysis",
    dataIndex: "FPRAnalysis",
    title: "False Positive Rate Analysis",
  },
  {
    key: "NPV",
    dataIndex: "NPV",
    title: "Negative Predictive Value (NPV)",
  },
  {
    key: "NPVAnalysis",
    dataIndex: "NPVAnalysis",
    title: "Negative Predictive Value Analysis",
  },
  {
    key: "PPV",
    dataIndex: "PPV",
    title: "Positive Predictive Value (PPV)",
  },
  {
    key: "PPVAnalysis",
    dataIndex: "PPVAnalysis",
    title: "Positive Predictive Value Analysis",
  },
  {
    key: "TPR",
    dataIndex: "TPR",
    title: "True Positive Rate (TPR)",
  },
  {
    key: "TPRAnalysis",
    dataIndex: "TPRAnalysis",
    title: "True Positive Rate Analysis",
  },
]

export const CODE_CYBER_SEC_VUL_ANALYSIS_COLUMN = [
  {
    key: "file_name",
    dataIndex: "file_name",
    title: "File name",
    render: (index: any, record: any) => {
      const parts = (record.file_name).split("//");
      const splittedfileName = parts[parts.length - 1];
      return (
        <p>
          {splittedfileName}
        </p>
      );
    },
  },
  {
    // key: "pattern_type",
    // dataIndex: "pattern_type",
    key: "bias_type",
    dataIndex: "bias_type",
    title: "Patterntype",
  },
  // {
  //   key: "line_no",
  //   dataIndex: "line_no",
  //   title: "Line no.",
  // },
  {
    // key: "vulnerability",
    // dataIndex: "vulnerability",
    key: "potential_bias",
    dataIndex: "potential_bias",
    title: "Vulnerability",
  },
  {
    key: "algorithm_package_term",
    dataIndex: "algorithm_package_term",
    title: "Algorithm package term",
  },
  // {
  //   key: "implications_and_remedial_actions",
  //   dataIndex: "implications_and_remedial_actions",
  //   title: "Implications and remedial actions",
  // },
]

export const CODE_AI_BIAS_ANALYSIS_COLUMN = [
  {
    key: "file_name",
    dataIndex: "file_name",
    title: "File name",
    render: (index: any, record: any) => {
      const parts = (record.file_name).split("//");
      const splittedfileName = parts[parts.length - 1];
      return (
        <p>
          {splittedfileName}
        </p>
      );
    },
  },
  {
    key: "line_no",
    dataIndex: "line_no",
    title: "Line no.",
  },
  {
    key: "bias_type",
    dataIndex: "bias_type",
    title: "Bias type ",
  },
  {
    key: "potential_bias",
    dataIndex: "potential_bias",
    title: "Potential bias",
  },
  {
    key: "algorithm_package_term",
    dataIndex: "algorithm_package_term",
    title: "Algorithm package term",
    // ellipsis: true,
    // width: 500,
  },
]

export const SENSITIVE_AND_BIAS_DATA_IDENTIFICATION_COLUMN = [
  {
    key: "category",
    dataIndex: "category",
    title: "Category"
  },
  {
    key: "recommendations",
    dataIndex: "recommendations",
    title: "Recommendations",
    // render: (index: any, record: any) => {
    //   console.log(record)
    //   return (
    //     <a className="link-primary text-decoration-underline" onClick={() => {
    //       window.open(record.dataContentLink, '_blank')
    //     }}>
    //       {record.dataContentLink}
    //     </a>
    //   );
    // },
  }
]

export const FEATURE_DOMINANCE_COLUMN = [
  {
    key: "feature",
    dataIndex: "feature",
    title: "Feature"
  },
  {
    key: "value",
    dataIndex: "value",
    title: "Value"
  },
]

export const PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_COLUMN = [
  {
    key: "fileName",
    dataIndex: "fileName",
    title: "FileName"
  },
  {
    key: "type",
    dataIndex: "type",
    title: "Type"
  },
  {
    key: "sensitivity_score",
    dataIndex: "sensitivity_score",
    title: "Sensitivity Score"
  },
  {
    key: "count",
    dataIndex: "count",
    title: "Count"
  },
]

export const PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY_DATSOURCE = [
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "0"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "0"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "1.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "2"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Algorithm Choice Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0",
    "FileName": "Data Collection  Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "1"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "1"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Data Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "0"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "1"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "1"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Evaluation Metrics Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "43"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "0"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "2"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "2"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Linear Regression.py",
    "Count": "6"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "3"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "2"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Model Training Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "1"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "2"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Preprocessing Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "0"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "2"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "1"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "2"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Text Classification Bias.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "12"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "0"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "1"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Trial1.py",
    "Count": "0"
  },
  {
    "Type": "Primary Security",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "3"
  },
  {
    "Type": "Code Vulnerability",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "0"
  },
  {
    "Type": "Decision Logic",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "1"
  },
  {
    "Type": "Exception Monitoring",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "0"
  },
  {
    "Type": "External Dependencies",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "0"
  },
  {
    "Type": "Sensitivity Data",
    "Sensitivity Score": "0.0",
    "FileName": "Trial2.py",
    "Count": "0"
  }
]

export const USER_ACTIVITY_COLUMN = [
  {
    key: "userId",
    dataIndex: "userId",
    title: "User Id"
  },
  {
    key: "username",
    dataIndex: "username",
    title: "User Name"
  },
  {
    key: "url",
    dataIndex: "url",
    title: "Url Visited"
  },
  {
    key: "eventName",
    dataIndex: "eventName",
    title: "Event"
  },
  {
    key: "duration",
    dataIndex: "duration",
    title: "Duration",
    render: (index: any, record: any) => {

      return (
        <p>
          {`${record?.duration} mins`}
        </p>
      );
    },
  },
  {
    key: "startTime",
    dataIndex: "startTime",
    title: "Date and Time",
    render: (index: any, record: any) => {
      const dateTime = fDateTimeSuffix(record?.startTime)
      return (
        <p>
          {dateTime}
        </p>
      );
    },
  },
]

export const EMPLOYMENT_BIAS_COLUMN = [
  {
    key: "category",
    dataIndex: "category",
    title: "Category"
  },
  {
    key: "category1",
    dataIndex: "category1",
    title: "Category1"
  },
  {
    key: "values",
    dataIndex: "values",
    title: "Values"
  },
]

export const AI_GOVERNANCE_EXPECTATIONS_COLUMN = [
  {
    key: "keyControls",
    dataIndex: "keyControls",
    title: "Key Controls"
  },
  {
    key: "mappedGovernanceSections",
    dataIndex: "mappedGovernanceSections",
    title: "Mapped Governance Sections"
  },
  {
    key: "euAiActReference",
    dataIndex: "euAiActReference",
    title: "EU AI Act Reference"
  },
  {
    key: "euAiActExpectations",
    dataIndex: "euAiActExpectations",
    title: "EU AI Act Expectations"
  },
  {
    key: "NISTGovernanceExpectation",
    dataIndex: "NISTGovernanceExpectation",
    title: "NIST Governance Expectation"
  },
  {
    key: "governanceExpectationExplanation",
    dataIndex: "governanceExpectationExplanation",
    title: "Governance Expectation Explanation"
  },
]

export const TERM_COMPARISON_ANALYSIS_COLUMN = [
  {
    key: "fileName",
    dataIndex: "fileName",
    title: "FileName"
  },
  {
    key: "keyGroup",
    dataIndex: "keyGroup",
    title: "Key Group",
    render: (index: any, record: any) => {
      if (!record.keyGroup) {
        return (
          <p>
            -
          </p>
        );
      } else {
        return (
          <p>
            {record.keyGroup}
          </p>
        );
      }
    },
  },
  {
    key: "keyTerm",
    dataIndex: "keyTerm",
    title: "Key Term"
  },
  {
    key: "frequency",
    dataIndex: "frequency",
    title: "Frequency"
  }
]

export const AI_GOVERNANCE_EXPECTATIONS_DATA = [
  {
    keyControls: "External Audits and Third-Party Assessments",
    mappedGovernanceSections: "External, Audits, Third-Party, Assessments",
    NISTGovernanceExpectation: "Auditing and Documentation (GOVERN 6.3)",
    governanceExpectationExplanation: "External audits and third-party assessments are expected to be conducted to ensure compliance with AI governance standards and document the findings for transparency and accountability.",
    euAiActReference: "Article 61, Article 62, Article 63, Article 64, Article 65, Article 66, Article 67, Article 68",
    euAiActExpectations: "Post-market monitoring, Information Sharing, Market Surveillance, Enforcement",
  },
  {
    keyControls: "Transparency and Data Disclosure",
    mappedGovernanceSections: "Transparency, Data, Disclosure, AI Driven Decision Making Process",
    NISTGovernanceExpectation: "Transparency and Data Disclosure (GOVERN 2.1)",
    governanceExpectationExplanation: "AI-driven decision-making processes should be transparent, and data disclosure practices should be in place to provide insight into how AI systems make decisions and use data.",
    euAiActReference: "Article 52",
    euAiActExpectations: "Transparency obligations for certain AI systems",
  },
  {
    keyControls: "Secure Data Storage and Encryption",
    mappedGovernanceSections: "Secure, Data, Storage, Encryption",
    NISTGovernanceExpectation: "Data Security and Storage (GOVERN 1.2)",
    governanceExpectationExplanation: "AI systems should ensure secure data storage and encryption to protect sensitive information and maintain data integrity.",
    euAiActReference: "Article 10",
    euAiActExpectations: "Data and data governance",
  },
  {
    keyControls: "Right to Explanation and Appeal",
    mappedGovernanceSections: "Right, Explanation, Appeal",
    NISTGovernanceExpectation: "User Rights and Consent (GOVERN 4.2)",
    governanceExpectationExplanation: "Users should have the right to receive an explanation of AI-driven decisions and the ability to appeal decisions that affect them adversely.",
    euAiActReference: "Article 16",
    euAiActExpectations: "Obligations of providers of high-risk AI systems",
  },
  {
    keyControls: "Right to Access and Erasure",
    mappedGovernanceSections: "Right, Access, Erasure, AI Systems",
    NISTGovernanceExpectation: "Data Protection and Privacy (GOVERN 2.3)",
    governanceExpectationExplanation: "Users should have the right to access their data held by AI systems and request the erasure of their data, aligning with data protection and privacy regulations.",
    euAiActReference: "Article 16, Article 29",
    euAiActExpectations: "Obligations of providers and users of high-risk AI systems",
  },
  {
    keyControls: "Monitoring and Feedback Mechanisms",
    mappedGovernanceSections: "Monitoring, Feedback, Mechanisms",
    NISTGovernanceExpectation: "Continuous Improvement (GOVERN 7.1)",
    governanceExpectationExplanation: "Monitoring and feedback mechanisms are expected to be in place to continuously improve AI systems and address issues and concerns proactively.",
    euAiActReference: "Article 61",
    euAiActExpectations: "Post-market monitoring by providers and post-market monitoring plan",
  },
  {
    keyControls: "Bias and Fairness Mitigation",
    mappedGovernanceSections: "Bias, Fairness, Mitigation, AI Systems",
    NISTGovernanceExpectation: "Ethical and Fair Use (GOVERN 4.2)",
    governanceExpectationExplanation: "Efforts should be made to identify and mitigate bias and promote fairness in AI systems to ensure equitable outcomes.",
    euAiActReference: "Article 15",
    euAiActExpectations: "Accuracy, robustness and cybersecurity",
  },
  {
    keyControls: "Risk Assessment and Mitigation",
    mappedGovernanceSections: "Risk, Assessment, Mitigation, Identify, Classify, AI systems, Implement Measures",
    NISTGovernanceExpectation: "High-Risk AI Systems (GOVERN 1.1)",
    governanceExpectationExplanation: "Risk assessment and mitigation strategies are expected to be applied, particularly for high-risk AI systems, by identifying, classifying, and implementing appropriate risk mitigation measures.",
    euAiActReference: "Article 9",
    euAiActExpectations: "Risk management system",
  },
  {
    keyControls: "User Awareness and Consent",
    mappedGovernanceSections: "User, Awareness, Consent",
    NISTGovernanceExpectation: "User Rights and Consent (GOVERN 4.1)",
    governanceExpectationExplanation: "Users should be made aware of how AI systems operate, and their consent should be obtained for data usage and AI-driven decisions.",
    euAiActReference: "Article 16",
    euAiActExpectations: "Obligations of providers of high-risk AI systems",
  },
  {
    keyControls: "Responsible AI Development Policies",
    mappedGovernanceSections: "Responsible, AI, Development, Policies",
    NISTGovernanceExpectation: "Accountability and Governance (GOVERN 3.1)",
    governanceExpectationExplanation: "Organizations should establish responsible AI development policies to ensure ethical AI outcomes and responsible behavior throughout the AI lifecycle.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
  {
    keyControls: "Governance Framework and Oversight",
    mappedGovernanceSections: "Governance, Framework, Oversight",
    NISTGovernanceExpectation: "Accountability and Governance (GOVERN 3.2)",
    governanceExpectationExplanation: "An effective governance framework and oversight mechanisms are expected to be in place to manage AI-related risks and ensure accountability.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
  {
    keyControls: "Impact Assessments for AI Systems",
    mappedGovernanceSections: "Impact, Assessments, AI, Systems",
    NISTGovernanceExpectation: "Accountability and Governance (GOVERN 3.3)",
    governanceExpectationExplanation: "Organizations should conduct impact assessments for AI systems to understand their effects and implications, contributing to overall accountability and governance.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
  {
    keyControls: "Transparency in Decision-Making",
    mappedGovernanceSections: "Transparency, Decision-Making",
    NISTGovernanceExpectation: "Ethical and Fair Use (GOVERN 4.3)",
    governanceExpectationExplanation: "Transparency in decision-making processes is crucial to ensure ethical and fair use of AI systems and to build trust with stakeholders.",
    euAiActReference: "Article 52",
    euAiActExpectations: "Transparency obligations for certain AI systems",
  },
  {
    keyControls: "Regular Security Audits and Updates",
    mappedGovernanceSections: "Regular, Security, Audits, Updates",
    NISTGovernanceExpectation: "Data Security and Storage (GOVERN 1.1)",
    governanceExpectationExplanation: "Regular security audits and updates are necessary to maintain the integrity and confidentiality of AI system data and protect against vulnerabilities.",
    euAiActReference: "Article 61",
    euAiActExpectations: "Post-market monitoring by providers and post-market monitoring plan",
  },
  {
    keyControls: "Documentation of AI Systems",
    mappedGovernanceSections: "Documentation, AI, Systems",
    NISTGovernanceExpectation: "Auditing and Documentation (GOVERN 6.1)",
    governanceExpectationExplanation: "Comprehensive documentation of AI systems should be maintained to facilitate auditing and accountability processes.",
    euAiActReference: "Article 11",
    euAiActExpectations: "Technical documentation",
  },
  {
    keyControls: "Adaptation to Regulatory Changes",
    mappedGovernanceSections: "Adaptation, Regulatory, Changes",
    NISTGovernanceExpectation: "Continuous Improvement (GOVERN 7.2)",
    governanceExpectationExplanation: "Organizations should be adaptable to regulatory changes and continuously improve AI governance practices to stay compliant and effective.",
    euAiActReference: "Article 84",
    euAiActExpectations: "Commission assessment of AI Act annually",
  },
  {
    keyControls: "Data Collection and Usage Consent",
    mappedGovernanceSections: "Data, Collection, Usage, Consent, AI Training",
    NISTGovernanceExpectation: "Data Protection and Privacy (GOVERN 1.6)",
    governanceExpectationExplanation: "Data collection and usage consent should align with AI training practices, respecting data protection and privacy regulations.",
    euAiActReference: "Article 10, Article 16",
    euAiActExpectations: "Data and data governance, Obligations of providers of high-risk AI systems",
  },
  {
    keyControls: "Regular Internal Audits and Review",
    mappedGovernanceSections: "Regular, Internal, Audits, Review",
    NISTGovernanceExpectation: "Auditing and Documentation (GOVERN 6.2)",
    governanceExpectationExplanation: "Regular internal audits and reviews of AI systems are essential to identify and rectify issues and maintain governance standards.",
    euAiActReference: "Article 61",
    euAiActExpectations: "Post-market monitoring by providers and post-market monitoring plan",
  },
  {
    keyControls: "Human Oversight and Intervention",
    mappedGovernanceSections: "Human, Oversight, Intervention",
    NISTGovernanceExpectation: "High-Risk AI Systems (GOVERN 1.3)",
    governanceExpectationExplanation: "High-risk AI systems should include mechanisms for human oversight and intervention to ensure safe and responsible operation.",
    euAiActReference: "Article 14",
    euAiActExpectations: "Human oversight",
  },
  {
    keyControls: "Ethical Use and Accountability",
    mappedGovernanceSections: "Ethical, Use, Accountability, Guidelines, AI outcomes, Responsible Behavior, Accountability",
    NISTGovernanceExpectation: "Ethical and Fair Use (GOVERN 4.1)",
    governanceExpectationExplanation: "Ethical use and accountability guidelines should be established to guide AI outcomes and promote responsible behavior and accountability.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
  {
    keyControls: "International Data Transfer Compliance",
    mappedGovernanceSections: "International, Data, Transfer, Compliance",
    NISTGovernanceExpectation: "Regulatory Compliance (GOVERN 5.2)",
    governanceExpectationExplanation: "Compliance with international data transfer regulations is essential for organizations handling global data and AI systems.",
    euAiActReference: "Article 6, Article 84",
    euAiActExpectations: "Classification rules for high-risk AI systems, Commission assessment of AI Act annually",
  },
  {
    keyControls: "Compliance with Sector-Specific Regulations",
    mappedGovernanceSections: "Compliance, Sector-Specific, Regulations",
    NISTGovernanceExpectation: "Compliance with Sector-Specific Regulations (GOVERN 2.1)",
    governanceExpectationExplanation: "Organizations must ensure compliance with sector-specific regulations that govern AI applications in specific industries.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
  {
    keyControls: "Data Minimization and Purpose Limitation",
    mappedGovernanceSections: "Data, Minimization, Purpose, Limitation",
    NISTGovernanceExpectation: "Data Protection and Privacy (GOVERN 1.5)",
    governanceExpectationExplanation: "Data minimization and purpose limitation principles should guide data handling and usage to protect data privacy and maintain compliance.",
    euAiActReference: "Article 10",
    euAiActExpectations: "Data and data governance",
  },
  {
    keyControls: "Compliance with National AI Regulations",
    mappedGovernanceSections: "Compliance, National, AI, Regulations",
    NISTGovernanceExpectation: "Regulatory Compliance (GOVERN 5.1)",
    governanceExpectationExplanation: "Organizations must ensure compliance with national AI regulations to operate AI systems within legal boundaries.",
    euAiActReference: "Article 6",
    euAiActExpectations: "Classification rules for high-risk AI systems",
  },
];