export enum COLUMN_REPORTYPE {

  /** Below constants for Database reports **/

  SQL_VMS = "RTDSQLVMS",
  SQL_NAMES = "RTDSQLN",
  ALL_SQL_SERVERS_RG = "RTDASQLSRG",
  AL_SQL_SERVERS_LOCATIONS = "RTDASQLSLOC",
  ALL_DB = "RTDADB",
  ALL_DB_STATUS = "RTDADBS",
  ALL_DB_PRICING_TIER = "RTDADBPT",
  DB_CREATED_DETETED = "RTDDBCD",
  ALL_DB_BACKUP = "RTDADBB",
  ALL_DB_ELASTIC_POOL = "RTDADBEP",
  ALL_DB_BACKUP_ZONE = "RTDADBBZ",

   /** Below constants for DataSourceManagement reports **/
  VALUE_CHAIN_CATEGORY = "DSMVCC",
  VALUE_CHAIN = "DSMVC",
  DATA_PIPELINE_CATEGORY = "DSMDPC",
  DATE_PIPELINE = "DSMDP",
  DATA_SOURCE = "DSMDS",
  TERM_SET = "DSMTS",
  TERMS = "DSMT",
  VALUE_CHAIN_PARAGRAPH_EXTRACT = "DSVCPE",
  DATA_RECORDS = "DSDR",

   /** Below constants for Use Master reports **/
  USER_GROUP = "UMUG",
  USER_ROLE = "UMUR",
  USER="USER",
  STATE="STATE",
  COUNTRY="COUNTRY"
}


export enum ACTION_TYPE {
  ADD_ACTION = "ADD_ACTION",
  UPDATE_ACTION = "UPDATE_ACTION"
}

export enum REPORT_TYPE {

  DATA_SOURCE_MANAGEMENT = "Data Source Management",
  USER_MASTER = "User Master",
  COMMON="Common",
  AI_GOVERNANCE="AI Governance",
  DATA_GOVERNANCE="Data Governance",
  PRIVACYGUARD_PRO = "PrivacyGuard Pro",
  CYBERSECAI_SHIELD = "CyberSecAI Shield",
  BIASMITIGATE_PRO = "BiasMitigate Pro",
  PREDICTION_MODELS = "Prediction Models",
  ASSESSMENT_AND_AUDITS = "Assessment and Audits",
  RISK_MANAGEMENT = "Risk Management",
  DATA_LINEAGE = "Data Lineage"

  // WVD = "WVD",
  // APP_SERVICE = "App service ",
  // NEW_SERVICE = "New Service ",
}

export enum REPORT_NAME {
// AI Governance report name
CODE_AI_BIAS_ANALYSIS  = "Code AI Bias Analysis",
CODE_CYBER_SECURITY_VULNERABILITY_ANALYSIS = "Code Cyber Security Vulnerability Analysis",
SENSITIVE_AND_BIAS_DATA_IDENTIFICATION = "Sensitive and Bias Data Identification",
DATA_PRIVACY_COMPLIANCE_TEST = "Data Privacy Compliance Test",
AI_REGULATION_COMPLIANCE_TEST = "AI Regulation Compliance Test",
DATA_SET_BIAS_TEST = "Data Set Bias Test",
AI_ALGORITHM_ACCURACY_AND_RELIABILITY_TEST = "AI Algorithm Accuracy and Reliability Test",
DATA_PRIVACY_PENALTY_PREDICTION = "Data Privacy Penalty Prediction",
FAIRNESS_METRICS_FOR_MACHINE_LEARNING = "Fairness Metrics for Machine Learning Prediction",
FEATURE_DOMINANCE = "Feature Dominance",
PROJECT_CODE_BIAS_AND_VULNERABILTY_SUMMARY = "Project Code Bias and Vulnerability Summary",
UNSTRUCTURED_DATA_BIAS_TEST = "Unstructured Data Bias Test",
REGULATION_PREDICTION = "Regulation Prediction",
USER_ACTIVITY = "User Activity",
EMPLOYMENT_BIAS = "Employment Bias",
DATA_LINEAGE = "Data Lineage",
ASSESSMENT_AND_AUDITS = 'Assessment and Audits',
RISK_MANAGEMENT = 'Risk Management',
TERM_COMPARISON_ANALYSIS = 'Term Comparison Analysis',
}
