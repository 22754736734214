import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import { ACTION_TYPE, REPORT_NAME } from "../../constant/reportType";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { alertMessage } from "../../utils/AlertService";
import { useDataSetBiasTestMutation, useFairnessMetricsForMachineLearPredMutation, useSensitiveAndBiasDataIdentificationMutation, useUnstructuredDataBiasTestMutation } from "../../redux-toolkit/services/fastApiService";
import Loading from "../../component/Loading";
import * as Yup from "yup";
import { setDataSetBiasTest, setFairnessMetrics } from "../../redux-toolkit/slices/fastApiSlice";
import { useTermComparisonAnalysisMutation } from "../../redux-toolkit/services/textMatcherService";
import { setTermComparisonAnalysis, setTermComparisonAnalysisWithTermFile } from "../../redux-toolkit/slices/textMatcherSlice";

type Props = {
  setShow: any;
  show: any;
  reportName?: string;
  reportNameCode?: string;
  setDataSetBiasTestDataSource?: any;
  setFairnessMetricsDataSource: any;
  setFileName: any;
  setTermFileName: any;
};

const BiasMitigateProData = (props: Props) => {
  // validation...........
  const formSchema = Yup.object().shape({
    annualUSD: Yup.string().required("Please enter Annual USD!"),
    numViolations: Yup.string().required("Please enter Num Violations!"),

  });
  const token: any = getLocalStorageData("localUser").token;
  const selectedReportNameL: any = localStorage?.getItem('selectedReportName');
  const dispatch = useDispatch();

  //state
  const [file, setFile]: any = useState<File | null>(null);
  const [file1, setFile1]: any = useState<File | null>(null);

  const [termFile, setTermFile]: any = useState<File | null>(null);
  const [isTerm, setIsTerm] = useState(false);

  //mutation
  const [uploadDataSetBiasTest, uploadDataSetBiasTestResponse] = useDataSetBiasTestMutation();
  const [uploadFairnessMetrics, uploadFairnessMetricsResponse] = useFairnessMetricsForMachineLearPredMutation();
  const [uploadUnstructuredDataBiasTest, uploadUnstructuredDataBiasTestResponse] = useUnstructuredDataBiasTestMutation();
  const [uploadTermComparisonAnalysis, uploadTermComparisonAnalysisResponse] = useTermComparisonAnalysisMutation();

  // handleSubmit...........
  const handleSubmit = async (values: any) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('term_file', termFile);

    let extFile: any;
    if (file?.name) {
      const fileName = file?.name;
      setFileName(fileName)
      const extension = fileName.lastIndexOf(".") + 1;
      extFile = fileName.substr(extension, fileName.length).toLowerCase();
    }

    if (extFile === "csv") {
      if (props.reportNameCode === REPORT_NAME.DATA_SET_BIAS_TEST) {
        uploadDataSetBiasTest({
          file: formData
        })
      } else if (props.reportNameCode === REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING) {
        uploadFairnessMetrics({
          file: formData
        })
      }
    } else if (extFile === 'pdf' || extFile === 'zip') {
      if (props.reportNameCode === REPORT_NAME.TERM_COMPARISON_ANALYSIS) {
        uploadTermComparisonAnalysis({
          file: formData,
        })
      }
    } else if (props.reportName === REPORT_NAME.UNSTRUCTURED_DATA_BIAS_TEST) {
      uploadUnstructuredDataBiasTest({
        // file: formData
        folder_path: values.folderPath,
        keywords_file_path: values.filePath
      })
    }
    else {
      alertMessage("Please select proper format of file!", "error");
    }
  }

  const handleClose = () => props.setShow(false);

  const [fileName, setFileName] = useState("");

  // upload image...............................
  const imageHandler = (e: any) => {
    setFile(e.target.files[0]);
    props.setFileName(e.target.files[0].name)
  };

  const termFileHandler = (e: any) => {
    setTermFile(e.target.files[0]);
    props.setTermFileName(e.target.files[0].name)
    setIsTerm(true);
  }

  //DataSetBiasTestResponse.......
  useEffect(() => {
    if (uploadDataSetBiasTestResponse.isSuccess) {
      // console.log(uploadDataSetBiasTestResponse, 'uploadDataSetBiasTestResponse')
      if (uploadDataSetBiasTestResponse.data !== null) {
        dispatch(setDataSetBiasTest(uploadDataSetBiasTestResponse));

        // let dataSetBiasTestArr = Object.entries(uploadDataSetBiasTestResponse.data);
        // buildDataSetBiasTest(dataSetBiasTestArr);

        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.DATA_SET_BIAS_TEST);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = uploadDataSetBiasTestResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }
    } else if (uploadDataSetBiasTestResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }
  }, [uploadDataSetBiasTestResponse.isSuccess, uploadDataSetBiasTestResponse.isError]);

  //DataSetBiasTestResponse.......
  useEffect(() => {
    if (uploadFairnessMetricsResponse.isSuccess) {
      // console.log(uploadFairnessMetricsResponse, 'uploadFairnessMetricsResponse')
      if (uploadFairnessMetricsResponse.data !== null) {
        dispatch(setFairnessMetrics(uploadFairnessMetricsResponse));

        // let fairnessMetrics = Object.entries(uploadFairnessMetricsResponse.data);
        // buildFairnessMetrics(fairnessMetrics);

        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.FAIRNESS_METRICS_FOR_MACHINE_LEARNING);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {
        const errorMsg = uploadFairnessMetricsResponse.data.message;
        alertMessage(errorMsg, 'error');
        props.setShow(false);
      }
    } else if (uploadFairnessMetricsResponse.isError) {
      alertMessage("error while fetching Data", "error");
    }
  }, [uploadFairnessMetricsResponse.isSuccess, uploadFairnessMetricsResponse.isError]);

  //uploadTermComparisonAnalysisResponse.......
  useEffect(() => {
    if (uploadTermComparisonAnalysisResponse.isSuccess) {
      if (uploadTermComparisonAnalysisResponse.data !== null) {
        // dispatch(setTermComparisonAnalysis(uploadTermComparisonAnalysisResponse));

        if(isTerm) {
          dispatch(setTermComparisonAnalysisWithTermFile(uploadTermComparisonAnalysisResponse))
        } else {
          dispatch(setTermComparisonAnalysis(uploadTermComparisonAnalysisResponse));
        }
        
        props.setShow(false);
        let selectedReportName = [];
        if (selectedReportNameL !== null) {
          selectedReportName = JSON.parse(selectedReportNameL);
        }
        selectedReportName.push(REPORT_NAME.TERM_COMPARISON_ANALYSIS);
        const jsonString = JSON.stringify(selectedReportName);
        localStorage.setItem('selectedReportName', jsonString);
      } else {

        alertMessage("Error while fetching data", 'error');
        props.setShow(false);
      }
    } else if (uploadTermComparisonAnalysisResponse.isError) {
      alertMessage("Error while fetching Data", "error");
    }
  }, [uploadTermComparisonAnalysisResponse.isSuccess, uploadTermComparisonAnalysisResponse.isError]);

  const directoryRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (directoryRef.current !== null) {
      // 2. set attribute as JS does
      directoryRef.current.setAttribute("directory", "");
      directoryRef.current.setAttribute("webkitdirectory", "");
    }
    // 3. monitor change of your ref with useEffect
  }, [directoryRef]);

  const handleFileChange = (file: any) => {
    // console.log(file, 'file')
    setFile1(file);
  }

  return (
    <div>
      {/* <!-- Modal --> */}
      <Modal
        show={props.show}
        onHide={handleClose}
        className=""
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          {props.reportNameCode === "dataRecord" ? (
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              Web Search
            </Modal.Title>
          ) : (
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              {/* {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "} */}
              {props.reportName}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Formik
            // validationSchema={formSchema}
            enableReinitialize={true}
            initialValues={{
              filePath: "",
              folderPath: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => (
              <Form>
                <div className="text-center">
                  {props.reportNameCode === REPORT_NAME.TERM_COMPARISON_ANALYSIS ? (
                    <>
                      <p>Allowed file format - PDF, ZIP</p>
                      <input type="file" id="fileUpload" onChange={imageHandler} />
                      <hr />

                      <p>Allowed file format - CSV</p>
                      <input type="file" id="fileUpload1" onChange={termFileHandler} />
                      <hr />
                    </>
                  ) :
                    <>
                      <p>Allowed file format - CSV</p>
                      <input type="file" id="fileUpload" onChange={imageHandler} />
                      <hr />
                    </>
                  }
                </div>
                <div className="d-flex flex-row justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                  >
                    {/* {props.actionType === ACTION_TYPE.ADD_ACTION
                      ? "Save"
                      : "Update"} */}
                    Submit
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  {uploadDataSetBiasTestResponse.isLoading ||
                    uploadFairnessMetricsResponse.isLoading ||
                    uploadTermComparisonAnalysisResponse.isLoading ?
                    (
                      <Loading />
                    ) : (
                      ""
                    )}
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default BiasMitigateProData;
