import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import { ACTION_TYPE } from "../../constant/reportType";
import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { alertMessage } from "../../utils/AlertService";
import * as Yup from "yup";
import {
  useGetAllValueChainCategoriesMutation,
  useGetValueChainCategoryByIdMutation,
  useSaveValueChainCategoryMutation,
  useUpdateValueChainCategoryMutation,
} from "../../redux-toolkit/services/allValueChainCategoriesService";
import {
  getAllValueChainCategoriesDetails,
  setAllValueChainCategories,
} from "../../redux-toolkit/slices/allValueChainCategorySlice";
import { useAppSelector } from "../../redux-toolkit/hooks";
import {
  useGetAllValueChainsByIdMutation,
  useGetAllValueChainsMutation,
  useSaveValueChainMutation,
  useUpdateValueChainMutation,
} from "../../redux-toolkit/services/allValueChainsService";
import {
  useGetTermByIdMutation,
  useSaveTermMutation,
  useUpdateTermMutation,
} from "../../redux-toolkit/services/allTermService";
import {
  useGetAllTermSetMutation,
  useGetTermSetByIdMutation,
  useSaveTermSetMutation,
  useUpdateTermSetMutation,
} from "../../redux-toolkit/services/allTermSetService";
import {
  getAllTermSetDetails,
  setAllTermSet,
} from "../../redux-toolkit/slices/allTermSetSlice";
import {
  useGetAllDataPipelinesCategoriesByIdMutation,
  useGetAllDataPipelinesCategoriesMutation,
  useSaveDataPipelineCategoryMutation,
  useUpdateDataPipelineCategoryMutation,
} from "../../redux-toolkit/services/allDataPipelinesCategoriesService";
import {
  getAllDataPipelinesCategoriesDetails,
  setAllDataPipelinesCategories,
} from "../../redux-toolkit/slices/allDataPipelinesCategoriesSlice";
import {
  useGetAllDataPipelinesByIdMutation,
  useSaveDataPipelineMutation,
  useUpdateDataPipelineMutation,
} from "../../redux-toolkit/services/allDataPipelinesService";
import {
  useGetAllUserRoleMutation,
  useGetUserRoleByIdMutation,
  useSaveUserRoleMutation,
  useUpdateUserRoleMutation,
} from "../../redux-toolkit/services/userRoleService";
import {
  useGetAllUserGroupMutation,
  useGetUserGroupByIdMutation,
  useSaveUserGroupMutation,
  useUpdateUserGroupMutation,
} from "../../redux-toolkit/services/userGroupService";
import {
  useGetUserByIdMutation,
  useSaveUserMutation,
  useUpdateUserMutation,
} from "../../redux-toolkit/services/allUserService";
import {
  getAllUserRoleDetails,
  setAllUserRole,
} from "../../redux-toolkit/slices/allUserRoleSlice";
import {
  getAllUserGroupDetails,
  setAllUserGroup,
} from "../../redux-toolkit/slices/allUserGroupSlice";
import Condition from "yup/lib/Condition";
import {
  getAllValueChainsDetails,
  setAllValueChains,
} from "../../redux-toolkit/slices/allValueChainsSlice";
import {
  useGetDataRecordByIdMutation,
  useSaveDataRecordMutation,
  useUpdateDataRecordMutation,
} from "../../redux-toolkit/services/allDataRecordsService";
import { useGetResultByTermsMutation } from "../../redux-toolkit/services/webSearchService";
import { useGetAllCitiesMutation } from "../../redux-toolkit/services/cityService";
import { useGetAllCountryMutation } from "../../redux-toolkit/services/countryService";
import { useGetStateMutation } from "../../redux-toolkit/services/stateService";
import {
  getFindStateByCountryNameDetail,
  setStates,
} from "../../redux-toolkit/slices/statesSlice";
import {
  getAllcountryDetail,
  setcountry,
} from "../../redux-toolkit/slices/countrySlice";
import { getFindCityByStateIdDetail } from "../../redux-toolkit/slices/citySlice";
import Loading from "../../component/Loading";
import { Checkbox, Select } from "antd";
import { useGetAllPermissionsMutation } from "../../redux-toolkit/services/permissionService";
import { getAllPermissions, setPermissions } from "../../redux-toolkit/slices/permissionSlice";

const { Option } = Select;

type Props = {
  setShow: any;
  show: any;
  actionType: ACTION_TYPE;
  data?: any;
  setReportData?: any;
  getAllReportAsync: () => void;
  updateAllReportAsync: (values: any) => void;
  reportType?: any;
  reportName?: string;
  isCategory?: boolean;
  isTermSet?: boolean;
  reportNameCode?: string;
  setCall?: any;
  call?: any;
};

// validation...........
const formSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  select: Yup.string().required("Please select"),
});

const userSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  mobileNo: Yup.string().required("Please enter mobile number"),
  email: Yup.string().email("Invalid email").required("Please enter email"),
});

const ScheduleReport = (props: Props) => {
  const token: any = getLocalStorageData("localUser").token;
  const dispatch = useDispatch();
  //selector
  const allValueChainCategories: any = useAppSelector(
    getAllValueChainCategoriesDetails
  );
  const allTermSets: any = useAppSelector(getAllTermSetDetails);
  const allDataPipelineCategories: any = useAppSelector(
    getAllDataPipelinesCategoriesDetails
  );
  const allUserRoles: any = useAppSelector(getAllUserRoleDetails);
  const allUserGroup: any = useAppSelector(getAllUserGroupDetails);
  const allValueChain: any = useAppSelector(getAllValueChainsDetails);
  const allCountries: any = useAppSelector(getAllcountryDetail);
  const allCities: any = useAppSelector(getFindCityByStateIdDetail);
  const allStates: any = useAppSelector(getFindStateByCountryNameDetail);
  const allPermission: any = useAppSelector(getAllPermissions);

  //state
  const [selectData, setSelectData]: any = useState();
  const [getByIdData, setGetByIdData]: any = useState();
  const [role, setRole]: any = useState();
  const [group, setGroup]: any = useState();
  const [country, setCountry]: any = useState();
  const [city, setCity]: any = useState();
  const [state, setState]: any = useState();
  const [valueChainCat, setValueChainCat]: any = useState();
  const [valueChain, setValueChain]: any = useState();
  const [termSet, setTermSet]: any = useState();
  const [selectedCountry, setSelectedCountry]: any = useState();

  const [selectedState, setSelectedState]: any = useState();
  const [stateName, setStateName]: any = useState();
  const [selectedPermissions, setSelectedPermissions]: any = useState([]);
  const [selectedPermissionDesc, setSelectedPermissionDesc]: any = useState([]);
  const [filteredOptions, setFilteredOptions]: any = useState([]);

  //getData
  const [getAllUserRole, getAllUserRoleResponse] = useGetAllUserRoleMutation();
  const [getAllUserGroup, getAllUserGroupResponse] =
    useGetAllUserGroupMutation();
  const [getAllTermSet, getAllTermSetResponse] = useGetAllTermSetMutation();
  const [getAllValueChainCat, getAllValueChainCatResponse] =
    useGetAllValueChainCategoriesMutation();
  const [getAllValueChain, getAllValueChainResponse] =
    useGetAllValueChainsMutation();
  const [getCountry, getCountryResponse] = useGetAllCountryMutation();
  const [getState, getStateResponse] = useGetStateMutation();
  const [getCities, getCitiesResponse] = useGetAllCitiesMutation();
  const [getAllPermission, getAllPermissionsResponse] = useGetAllPermissionsMutation();

  //save
  const [saveValueChain, saveValueChainResponse] = useSaveValueChainMutation();
  const [saveValueChainCategory, saveValueChainCategoryResponse] =
    useSaveValueChainCategoryMutation();
  const [saveTerm, saveTermResponse] = useSaveTermMutation();
  const [saveTermSet, saveTermSetResponse] = useSaveTermSetMutation();
  const [saveDataPipelineCategory, saveDataPipelineCategoryResponse] =
    useSaveDataPipelineCategoryMutation();
  const [saveDataPipeline, saveDataPipelineResponse] =
    useSaveDataPipelineMutation();
  const [saveUserRole, saveUserRoleResponse] = useSaveUserRoleMutation();
  const [saveUserGroup, saveUserGroupResponse] = useSaveUserGroupMutation();
  const [saveUser, saveUserResponse] = useSaveUserMutation();
  const [saveDataRecord, saveDataRecordResponse] = useSaveDataRecordMutation();
  const [getResultByTerms, getResultByTermsResponse] =
    useGetResultByTermsMutation();

  //update
  const [updateValueChain, updateValueChainResponse] =
    useUpdateValueChainMutation();
  const [updateValueChainCategory, updateValueChainCategoryResponse] =
    useUpdateValueChainCategoryMutation();
  const [updateTerm, updateTermResponse] = useUpdateTermMutation();
  const [updateTermSet, updateTermSetResponse] = useUpdateTermSetMutation();
  const [updateDataPipelineCategory, updateDataPipelineCategoryResponse] =
    useUpdateDataPipelineCategoryMutation();
  const [updateDataPipeline, updateDataPipelineResponse] =
    useUpdateDataPipelineMutation();
  const [updateUserRole, updateUserRoleResponse] = useUpdateUserRoleMutation();
  const [updateUserGroup, updateUserGroupResponse] =
    useUpdateUserGroupMutation();
  const [updateUser, updateUserResponse] = useUpdateUserMutation();
  const [updateDataRecord, updateDataRecordResponse] =
    useUpdateDataRecordMutation();

  //getById mutation
  const [getValueChainsById, valueChainsByIdResponse] =
    useGetAllValueChainsByIdMutation();
  const [getValueChainCategoriesById, valueChainCategoriesByIdResponse] =
    useGetValueChainCategoryByIdMutation();
  const [getTermById, getTermByIdResponse] = useGetTermByIdMutation();
  const [getTermSetById, getTermSetByIdResponse] = useGetTermSetByIdMutation();
  const [getDataPipelinesCategoriesById, dataPipelinesCategoiresByIdResponse] =
    useGetAllDataPipelinesCategoriesByIdMutation();
  const [getDataPipelinesById, dataPipelinesByIdResponse] =
    useGetAllDataPipelinesByIdMutation();
  const [getUserRoleById, getUserRoleByIdResponse] =
    useGetUserRoleByIdMutation();
  const [getUserGroupById, getUserGroupByIdResponse] =
    useGetUserGroupByIdMutation();
  const [getUserById, getUserByIdResponse] = useGetUserByIdMutation();
  const [getDataRecordById, getDataRecordByIdResponse] =
    useGetDataRecordByIdMutation();

  const [selectedValue, setSelectedValue] = useState("");
  //   props.actionType === ACTION_TYPE.UPDATE_ACTION &&
  //     props.reportNameCode === "term"
  //     ? props.data.termsetid
  //     : props.actionType === ACTION_TYPE.UPDATE_ACTION &&
  //       props.reportNameCode === "valueChain"
  //     ? props.data.categoryId
  //     : props.actionType === ACTION_TYPE.UPDATE_ACTION &&
  //       props.reportNameCode == "dataPipeline"
  //     ? props.data.pipelinecategoryid
  //     : ""
  // );

  const [selectedRole, setSelectedRole] = useState(
    props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "user"
      ? props.data.userRoleId
      : ""
  );
  const [selectedGroup, setSelectedGroup] = useState(
    props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "user"
      ? props.data.userGroupId
      : ""
  );
  const [selectedCountryId, setSelectedCountryId] = useState(
    (props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "user") ||
      props.reportNameCode === "dataRecord"
      ? props.data.countryId
      : ""
  );

  const [selectedStateId, setSelectedStateId] = useState(
    (props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "user") ||
      props.reportNameCode === "dataRecord"
      ? props.data.stateId
      : ""
  );

  const [selectedCity, setSelectedCity] = useState(
    props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "user"
      ? props.data.city
      : ""
  );

  const [selectedValueChainCat, setSelectedValueChainCat] = useState(
    props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "dataRecord"
      ? props.data.categoryId
      : ""
  );

  const [valueChainCatName, setValueChainCatName] = useState();

  const [selectedValueChain, setSelectedValueChain] = useState(
    props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "dataRecord"
      ? props.data.valueChainId
      : ""
  );

  const [valueChainName, setValueChainName] = useState();

  const [selectedTermSet, setSelectedTermSet] = useState(
    props.actionType === ACTION_TYPE.UPDATE_ACTION &&
      props.reportNameCode === "dataRecord"
      ? props.data.termsetId
      : ""
  );

  const [termSetName, setTermSetName] = useState();

  useEffect(() => {
    if (props.reportNameCode === "user") {
      getAllUserGroup({ token: token });
      getAllUserRole({ token: token });
    }
  }, []);

  useEffect(() => {
    if (props.reportNameCode === "dataRecord") {
      getAllTermSet({ page: 0, size: 200, sort: "id" });
      getAllValueChain({ page: 0, size: 200, sort: "id" });
      getAllValueChainCat({ page: 0, size: 200, sort: "id" });
    }
  }, []);

  useEffect(() => {
    if (
      props.reportNameCode === "dataRecord" ||
      props.reportNameCode === "user"
    ) {
      getCountry({ page: 0, size: 200, sort: "id" });
    }
  }, []);

  useEffect(() => {
    if (props.reportNameCode === "userRole") {
      getAllPermission({ token: token });
    }
  }, []);

  useEffect(() => {
    if (getCountryResponse.isSuccess) {
      dispatch(setcountry(getCountryResponse));
    } else if (getCountryResponse.isError) {
      let response: any = getCountryResponse.error;
      alertMessage(response, "error");
    }
  }, [getCountryResponse.isSuccess, getCountryResponse.isError]);

  useEffect(() => {
    if (getStateResponse.isSuccess) {
      dispatch(setStates(getStateResponse));
    } else if (getStateResponse.isError) {
      let response: any = getStateResponse.error;
      alertMessage(response, "error");
    }
  }, [getStateResponse.isSuccess, getStateResponse.isError]);

  useEffect(() => {
    if (getCitiesResponse.isSuccess) {
      dispatch(setCity(getCitiesResponse));
    } else if (getCitiesResponse.isError) {
      let response: any = getCitiesResponse.error;
      alertMessage(response, "error");
    }
  }, [getCitiesResponse.isSuccess, getCitiesResponse.isError]);

  useEffect(() => {
    if (getAllUserRoleResponse.isSuccess) {
      dispatch(setAllUserRole(getAllUserRoleResponse));
    } else if (getAllUserRoleResponse.isError) {
      let response: any = getAllUserRoleResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllUserRoleResponse.isSuccess, getAllUserRoleResponse.isError]);

  //getAllPermissionsReponse
  useEffect(() => {
    if (getAllPermissionsResponse.isSuccess) {
      dispatch(setPermissions(getAllPermissionsResponse));
    } else if (getAllPermissionsResponse.isError) {
      alertMessage("Error while fetching permissions", "error");
    }
  }, [getAllPermissionsResponse.isSuccess, getAllPermissionsResponse.isError]);

  useEffect(() => {
    if (getAllUserGroupResponse.isSuccess) {
      dispatch(setAllUserGroup(getAllUserGroupResponse));
    } else if (getAllUserGroupResponse.isError) {
      let response: any = getAllUserGroupResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllUserGroupResponse.isSuccess, getAllUserGroupResponse.isError]);

  useEffect(() => {
    if (getAllValueChainCatResponse.isSuccess) {
      dispatch(setAllValueChainCategories(getAllValueChainCatResponse));
    } else if (getAllValueChainCatResponse.isError) {
      let response: any = getAllValueChainCatResponse.error;
      alertMessage(response, "error");
    }
  }, [
    getAllValueChainCatResponse.isSuccess,
    getAllValueChainCatResponse.isError,
  ]);

  useEffect(() => {
    if (getAllValueChainResponse.isSuccess) {
      dispatch(setAllValueChains(getAllValueChainResponse));
    } else if (getAllValueChainResponse.isError) {
      let response: any = getAllValueChainResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllValueChainResponse.isSuccess, getAllValueChainResponse.isError]);

  useEffect(() => {
    if (getAllTermSetResponse.isSuccess) {
      dispatch(setAllTermSet(getAllTermSetResponse));
    } else if (getAllTermSetResponse.isError) {
      let response: any = getAllTermSetResponse.error;
      alertMessage(response, "error");
    }
  }, [getAllTermSetResponse.isSuccess, getAllTermSetResponse.isError]);

  useEffect(() => {
    if (props.reportNameCode === "user") {
      if (allUserRoles) {
        setRole(allUserRoles);
      }
      if (allUserGroup) {
        setGroup(allUserGroup);
      }
    }
  }, [allUserRoles, allUserGroup]);

  useEffect(() => {
    if (props.reportNameCode === "dataRecord") {
      if (allTermSets) {
        const allTermSetsAsc = [...allTermSets].sort((a, b) => a.id - b.id);
        setTermSet(allTermSetsAsc);
      }
      if (allValueChainCategories) {
        const allValueChainCategoriesAsc = [...allValueChainCategories].sort((a, b) => a.id - b.id);
        setValueChainCat(allValueChainCategoriesAsc);
      }
      if (allValueChain) {
        const allValueChainAsc = [...allValueChain].sort((a, b) => a.id - b.id);
        setValueChain(allValueChainAsc);
      }
    }
  }, [allTermSets, allValueChainCategories, allValueChain]);

  useEffect(() => {
    if (
      props.reportNameCode === "dataRecord" ||
      props.reportNameCode === "user"
    ) {
      if (allCountries) {
        setCountry(allCountries);
      }
      if (allStates) {
        setState(allStates);
      }
      if (allCities) {
        setCity(allCities);
      }
    }
  }, [allCountries, allStates, allCities]);

  // handleSubmit...........
  const handleSubmit = (values: any) => {
    if (props.actionType === ACTION_TYPE.ADD_ACTION) {
      if (props.reportNameCode === "valueChainCategory") {
        saveValueChainCategory({
          token: token,
          payload: {
            isActive: "Y",
            id: 0,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "valueChain") {
        saveValueChain({
          token: token,
          payload: {
            isActive: "Y",
            id: 0,
            categoryId: selectedValue,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "term") {
        saveTerm({
          payload: {
            isActive: "Y",
            id: 0,
            termsetid: selectedValue,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "termSet") {
        saveTermSet({
          payload: {
            isActive: "Y",
            id: 0,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "dataPipelineCategory") {
        saveDataPipelineCategory({
          payload: {
            isActive: "Y",
            id: 0,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "dataPipeline") {
        saveDataPipeline({
          payload: {
            isActive: "Y",
            pipelineId: 0,
            pipelinecategoryid: selectedValue,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "userRole") {
        saveUserRole({
          token: token,
          payload: {
            status: true,
            id: 0,
            name: values.name,
            permissionIds: selectedPermissions,
            userGroupId: 1,
          },
        });
      } else if (props.reportNameCode === "userGroup") {
        saveUserGroup({
          token: token,
          payload: {
            status: true,
            id: 0,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "user") {
        saveUser({
          token: token,
          payload: {
            id: 0,
            address: values.address,
            briefProfile: values.briefProfile,
            cityId: values.cityId,
            committeeName: values.committeeName,
            countryId: parseInt(selectedCountryId),
            stateId: parseInt(selectedStateId),
            domain: values.domain,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            linkedinRef: values.linkedinRef,
            organizationName: values.organizationName,
            password: values.email,
            phoneNumber: values.phoneNumber,
            photo: values.photo,
            postalCode: parseInt(values.pinCode),
            skills: values.skills,
            userRoleId: parseInt(selectedRole),
            userGroupId: parseInt(selectedGroup),
            username: values.email,
            roleIds: [parseInt(selectedRole)],
          },
        });
      } else if (props.reportNameCode === "dataRecord") {
        getResultByTerms({
          payload: {
            datapipelineId: 1,
            termsetId: parseInt(selectedTermSet),
            country: selectedCountry,
            state: selectedState,
            district: values.district,
            city: values.city,
            pinCode: values.pinCode,
            valueChainId: parseInt(selectedValueChain),
            valueChainName: valueChainName,
            valueChainCategoryId: parseInt(selectedValueChainCat),
            valueChainCategoryName: valueChainCatName,
            termSetName: termSetName,
          },
        });
      }
    } else if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
      if (props.reportNameCode === "valueChainCategory") {
        updateValueChainCategory({
          id: props?.data?.id,
          payload: {
            isActive: props.data.isActive,
            id: props?.data?.id,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "valueChain") {
        updateValueChain({
          payload: {
            isActive: props.data.isActive,
            id: props.data.id,
            categoryId: selectedValue,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "term") {
        updateTerm({
          id: props?.data?.id,
          payload: {
            isActive: props.data.isActive,
            id: props.data.id,
            termsetid: selectedValue,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "termSet") {
        updateTermSet({
          id: props?.data?.id,
          payload: {
            isActive: props.data.isActive,
            id: props.data.id,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "dataPipelineCategory") {
        updateDataPipelineCategory({
          payload: {
            isActive: props.data.isActive,
            id: props.data.id,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "dataPipeline") {
        updateDataPipeline({
          payload: {
            isActive: props.data.isActive,
            id: props.data.id,
            pipelinecategoryid: selectedValue,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "userRole") {
        updateUserRole({
          token: token,
          // id: props?.data?.id,
          payload: {
            status: props.data.status,
            id: props?.data?.id,
            name: values.name,
            permissionIds: selectedPermissions,
          },
        });
      } else if (props.reportNameCode === "userGroup") {
        updateUserGroup({
          token: token,
          id: props?.data?.id,
          payload: {
            status: props.data.status,
            id: props?.data?.id,
            name: values.name,
          },
        });
      } else if (props.reportNameCode === "user") {
        updateUser({
          token: token,
          id: props?.data?.id,
          payload: {
            // id:0,
            address: values.address,
            briefProfile: values.briefProfile,
            committeeName: values.committeeName,
            countryId: parseInt(selectedCountryId),
            stateId: parseInt(selectedStateId),
            city: values.cityId,
            domain: values.domain,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            linkedinRef: values.linkedinRef,
            organizationName: values.organizationName,
            password: values.email,
            phoneNumber: values.phoneNumber,
            photo: values.photo,
            pinCode: values.pinCode,
            skills: values.skills,
            userRoleId: parseInt(selectedRole),
            userGroupId: parseInt(selectedGroup),
            username: values.email,
            postalCode: values.pinCode,
            roleIds: [parseInt(selectedRole)],
          },
        });
      }
    }
  };

  useEffect(() => {
    if (props.actionType === ACTION_TYPE.UPDATE_ACTION) {
      if (props.reportNameCode === "valueChainCategory") {
        getValueChainCategoriesById({
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === "valueChain") {
        getValueChainsById({
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === "term") {
        getTermById({
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === "termSet") {
        getTermSetById({
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === "dataPipelineCategory") {
        getDataPipelinesCategoriesById({
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === "dataPipeline") {
        getDataPipelinesById({
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === "userRole") {
        getUserRoleById({
          token: token,
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === "userGroup") {
        getUserGroupById({
          token: token,
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === "user") {
        getUserById({
          token: token,
          id: props?.data?.id,
        });
      } else if (props.reportNameCode === "dataRecord") {
        getDataRecordById({
          id: props?.data?.id,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (valueChainsByIdResponse.isSuccess) {
      setGetByIdData(valueChainsByIdResponse.data);
      setSelectedValue(valueChainsByIdResponse?.data?.categoryId)
    } else if (valueChainsByIdResponse.isError) {
      let response: any = valueChainsByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [valueChainsByIdResponse.isSuccess, valueChainsByIdResponse.isError]);

  useEffect(() => {
    if (valueChainCategoriesByIdResponse.isSuccess) {
      setGetByIdData(valueChainCategoriesByIdResponse.data);
    } else if (valueChainCategoriesByIdResponse.isError) {
      let response: any = valueChainCategoriesByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [
    valueChainCategoriesByIdResponse.isSuccess,
    valueChainCategoriesByIdResponse.isError,
  ]);

  useEffect(() => {
    if (getTermByIdResponse.isSuccess) {
      setGetByIdData(getTermByIdResponse.data);
      setSelectedValue(getTermByIdResponse?.data?.termsetid);
    } else if (getTermByIdResponse.isError) {
      let response: any = getTermByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getTermByIdResponse.isSuccess, getTermByIdResponse.isError]);

  useEffect(() => {
    if (getTermSetByIdResponse.isSuccess) {
      setGetByIdData(getTermSetByIdResponse.data);
    } else if (getTermSetByIdResponse.isError) {
      let response: any = getTermSetByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getTermSetByIdResponse.isSuccess, getTermSetByIdResponse.isError]);

  useEffect(() => {
    if (dataPipelinesCategoiresByIdResponse.isSuccess) {
      setGetByIdData(dataPipelinesCategoiresByIdResponse.data);
    } else if (dataPipelinesCategoiresByIdResponse.isError) {
      let response: any = dataPipelinesCategoiresByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [
    dataPipelinesCategoiresByIdResponse.isSuccess,
    dataPipelinesCategoiresByIdResponse.isError,
  ]);

  useEffect(() => {
    if (dataPipelinesByIdResponse.isSuccess) {
      setGetByIdData(dataPipelinesByIdResponse.data);
      setSelectedValue(dataPipelinesByIdResponse?.data?.pipelinecategoryid);
    } else if (dataPipelinesByIdResponse.isError) {
      let response: any = dataPipelinesByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [dataPipelinesByIdResponse.isSuccess, dataPipelinesByIdResponse.isError]);

  useEffect(() => {
    if (getUserRoleByIdResponse.isSuccess) {
      setGetByIdData(getUserRoleByIdResponse.data);

      let selectedPermissionIds = getUserRoleByIdResponse?.data?.permissions.map((permission: any) => permission.id);
      // setSelectedPermissionDesc(selectedPermissionDesc);
      setSelectedPermissions(selectedPermissionIds);

    } else if (getUserRoleByIdResponse.isError) {
      let response: any = getUserRoleByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getUserRoleByIdResponse.isSuccess, getUserRoleByIdResponse.isError]);

  useEffect(() => {
    if (getUserGroupByIdResponse.isSuccess) {
      setGetByIdData(getUserGroupByIdResponse.data);
    } else if (getUserGroupByIdResponse.isError) {
      let response: any = getUserGroupByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getUserGroupByIdResponse.isSuccess, getUserGroupByIdResponse.isError]);

  useEffect(() => {
    if (getUserByIdResponse.isSuccess) {
      setGetByIdData(getUserByIdResponse.data);
    } else if (getUserByIdResponse.isError) {
      let response: any = getUserByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getUserByIdResponse.isSuccess, getUserByIdResponse.isError]);

  useEffect(() => {
    if (getDataRecordByIdResponse.isSuccess) {
      setGetByIdData(getDataRecordByIdResponse.data);
    } else if (getDataRecordByIdResponse.isError) {
      let response: any = getDataRecordByIdResponse?.error;
      alertMessage(response, "error");
    }
  }, [getDataRecordByIdResponse.isSuccess, getDataRecordByIdResponse.isError]);

  const handleClose = () => props.setShow(false);

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const handleSelectTermSet = (event: any) => {
    setSelectedTermSet(event.target.value);
    let object: any = termSet?.find((value: any) => value.id === parseInt(event.target.value));
    setTermSetName(object?.name);
  };

  const handleSelectValueChainCat = (event: any) => {
    setSelectedValueChainCat(event.target.value);
    let object: any = valueChainCat?.find((value: any) => value.id === parseInt(event.target.value));
    setValueChainName(object?.name);
  };

  const handleSelectValueChain = (event: any) => {
    setSelectedValueChain(event.target.value);
    let object: any = valueChain?.find((value: any) => value.id === parseInt(event.target.value));
    setValueChainCatName(object?.name);
  };

  const handleSelectCountry = (event: any) => {
    setSelectedCountryId(event.target.value);
    if (event) {
      getState({
        countryId: event.target.value,
      });
    }
    const countryName = allCountries.filter(
      (country: any) => country.id == event.target.value
    );
    setSelectedCountry(countryName[0].name);
  };

  const handleSelectState = (event: any) => {
    setSelectedStateId(event.target.value);
    const stateName = allStates.filter(
      (state: any) => state.id == event.target.value
    );
    setSelectedState(stateName[0].name);
  };

  const handleSelectCity = (event: any) => {
    setSelectedCity(event.target.value);
  };

  const handleSelectGroup = (event: any) => {
    setSelectedGroup(event.target.value);
  };

  const handleSelectRole = (event: any) => {
    setSelectedRole(event.target.value);
  };
  //after save
  useEffect(() => {
    if (saveValueChainResponse.isSuccess) {
      // dispatch(setAllValueChains(saveValueChainResponse));
      alertMessage("Value Chain added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveValueChainResponse.isError) {
      let response: any = saveValueChainResponse?.error;
      alertMessage(response, "error");
    }
  }, [saveValueChainResponse.isSuccess, saveValueChainResponse.isError]);

  //after update
  useEffect(() => {
    if (updateValueChainResponse.isSuccess) {
      // getAllValueChainCategories({});
      alertMessage("Value Chain updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateValueChainResponse.isError) {
      let response: any = updateValueChainResponse?.error;
      alertMessage(response, "error");
    }
  }, [updateValueChainResponse.isSuccess, updateValueChainResponse.isError]);

  //save value chain selectedValue
  useEffect(() => {
    if (saveValueChainCategoryResponse.isSuccess) {
      alertMessage("Value Chain Category added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveValueChainCategoryResponse.isError) {
      let response: any = saveValueChainCategoryResponse?.error;
      alertMessage(response, "error");
    }
  }, [
    saveValueChainCategoryResponse.isSuccess,
    saveValueChainCategoryResponse.isError,
  ]);

  //update value chain selectedValue
  useEffect(() => {
    if (updateValueChainCategoryResponse.isSuccess) {
      alertMessage("Value Chain Category updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateValueChainCategoryResponse.isError) {
      let response: any = updateValueChainCategoryResponse?.error;
      alertMessage(response, "error");
    }
  }, [
    updateValueChainCategoryResponse.isSuccess,
    updateValueChainCategoryResponse.isError,
  ]);

  useEffect(() => {
    if (saveTermResponse.isSuccess) {
      alertMessage("Term added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveTermResponse.isError) {
      let response: any = saveTermResponse?.error;
      alertMessage(response, "error");
    }
  }, [saveTermResponse.isSuccess, saveTermResponse.isError]);

  useEffect(() => {
    if (updateTermResponse.isSuccess) {
      alertMessage("Term updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateTermResponse.isError) {
      let response: any = updateTermResponse?.error;
      alertMessage(response, "error");
    }
  }, [updateTermResponse.isSuccess, updateTermResponse.isError]);

  useEffect(() => {
    if (saveTermSetResponse.isSuccess) {
      alertMessage("Term Set added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveTermSetResponse.isError) {
      let response: any = saveTermSetResponse?.error;
      alertMessage(response, "error");
    }
  }, [saveTermSetResponse.isSuccess, saveTermSetResponse.isError]);

  useEffect(() => {
    if (updateTermSetResponse.isSuccess) {
      alertMessage("Term Set updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateTermSetResponse.isError) {
      let response: any = updateTermSetResponse?.error;
      alertMessage(response, "error");
      handleClose();
    }
  }, [updateTermSetResponse.isSuccess, updateTermSetResponse.isError]);

  // save datapipeline category
  useEffect(() => {
    if (saveDataPipelineCategoryResponse.isSuccess) {
      alertMessage("Data Pipeline Category added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveDataPipelineCategoryResponse.isError) {
      let response: any = saveDataPipelineCategoryResponse?.error;
      alertMessage("Error while adding", "error");
    }
  }, [
    saveDataPipelineCategoryResponse.isSuccess,
    saveDataPipelineCategoryResponse.isError,
  ]);

  //update datapipeline category
  useEffect(() => {
    if (updateDataPipelineCategoryResponse.isSuccess) {
      alertMessage("Data Pipeline Category updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateDataPipelineCategoryResponse.isError) {
      alertMessage("Error while adding", "error");
      handleClose();
    }
  }, [
    updateDataPipelineCategoryResponse.isSuccess,
    updateDataPipelineCategoryResponse.isError,
  ]);

  // save datapipeline
  useEffect(() => {
    if (saveDataPipelineResponse.isSuccess) {
      alertMessage("Data Pipeline added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveDataPipelineResponse.isError) {
      alertMessage("Error while adding", "error");
    }
  }, [saveDataPipelineResponse.isSuccess, saveDataPipelineResponse.isError]);

  //update datapipeline
  useEffect(() => {
    if (updateDataPipelineResponse.isSuccess) {
      alertMessage("Data Pipeline updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateDataPipelineResponse.isError) {
      alertMessage("Error while updating Data Pipeline", "error");
      handleClose();
    }
  }, [
    updateDataPipelineResponse.isSuccess,
    updateDataPipelineResponse.isError,
  ]);

  //user role
  useEffect(() => {
    if (saveUserRoleResponse.isSuccess) {
      alertMessage("User role added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveUserRoleResponse.isError) {
      let response: any = saveUserRoleResponse?.error;
      let msg: any = response.data?.serviceErrors[0]?.msg;
      alertMessage(msg, "error");
    }
  }, [saveUserRoleResponse.isSuccess, saveUserRoleResponse.isError]);

  useEffect(() => {
    if (updateUserRoleResponse.isSuccess) {
      alertMessage("User role updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateUserRoleResponse.isError) {
      let response: any = updateUserRoleResponse?.error;
      let msg: any = response.data?.serviceErrors[0]?.msg;
      alertMessage(msg, "error");
    }
  }, [updateUserRoleResponse.isSuccess, updateUserRoleResponse.isError]);

  //user group
  useEffect(() => {
    if (saveUserGroupResponse.isSuccess) {
      alertMessage("User group added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveUserGroupResponse.isError) {
      let response: any = saveUserGroupResponse?.error;
      let msg: any = response.data?.serviceErrors[0]?.msg;
      alertMessage(msg, "error");
    }
  }, [saveUserGroupResponse.isSuccess, saveUserGroupResponse.isError]);

  useEffect(() => {
    if (updateUserGroupResponse.isSuccess) {
      alertMessage("User group updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateUserGroupResponse.isError) {
      let response: any = updateUserGroupResponse?.error;
      let msg: any = response?.error ? response.error.data.error : "Error while updating";
      alertMessage(msg, "error");
    }
  }, [updateUserGroupResponse.isSuccess, updateUserGroupResponse.isError]);

  //user
  useEffect(() => {
    if (saveUserResponse.isSuccess) {
      alertMessage("User added successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (saveUserResponse.isError) {
      let response: any = saveUserResponse?.error;
      let msg: any = response.data?.serviceErrors[0]?.msg;
      alertMessage(msg, "error");
    }
  }, [saveUserResponse.isSuccess, saveUserResponse.isError]);

  useEffect(() => {
    if (updateUserResponse.isSuccess) {
      alertMessage("User updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateUserResponse.isError) {
      let response: any = updateUserResponse?.error;
      let msg: any = response.data?.serviceErrors[0]?.msg;
      alertMessage(msg, "error");
    }
  }, [updateUserResponse.isSuccess, updateUserResponse.isError]);

  // save dataRecord
  useEffect(() => {
    if (getResultByTermsResponse.isSuccess) {
      alertMessage("Search Successfull", "success");
      props.setCall("Y");
      handleClose();
    } else if (getResultByTermsResponse.isError) {
      alertMessage("Error while searching", "error");
    }
  }, [getResultByTermsResponse.isSuccess, getResultByTermsResponse.isError]);

  //update dataRecord
  useEffect(() => {
    if (updateDataRecordResponse.isSuccess) {
      // alertMessage("Data Record updated successfully", "success");
      props.setCall("Y");
      handleClose();
    } else if (updateDataRecordResponse.isError) {
      alertMessage("Error while updating Data Record", "error");
      handleClose();
    }
  }, [updateDataRecordResponse.isSuccess, updateDataRecordResponse.isError]);

  useEffect(() => {
    if (allValueChainCategories && props.reportNameCode === "valueChain") {
      const allValueChainCategoriesAsc = [...allValueChainCategories].sort((a, b) => a.id - b.id);
      setSelectData(allValueChainCategoriesAsc);
    } else if (allTermSets && props.reportNameCode === "term") {
      const allTermSetsAsc = [...allTermSets].sort((a, b) => a.id - b.id);
      setSelectData(allTermSetsAsc);
    } else if (allDataPipelineCategories && props.reportNameCode === "dataPipeline") {
      const allDataPipelineCategoriesAsc = [...allDataPipelineCategories].sort((a, b) => a.id - b.id);
      setSelectData(allDataPipelineCategoriesAsc);
    }
  }, [allValueChainCategories, allTermSets, allDataPipelineCategories]);

  const handleChangeValue = (values: any) => {
    // console.log(values)
    setSelectedPermissions(values);
  };

  // useEffect(() => {
  //   if(allPermission) {
  //     let permissionDesc = allPermission.map((permission: any) => permission.desc);
  //     let filteredOptions = permissionDesc.filter((o: any) => !selectedPermissions.includes(o.id));
  //     setFilteredOptions(filteredOptions);
  //   }
  // },[allPermission]);


  return (
    <div>
      {/* <!-- Modal --> */}
      <Modal
        show={props.show}
        onHide={handleClose}
        className=""
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          {props.reportNameCode === "dataRecord" ? (
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              Web Search
            </Modal.Title>
          ) : (
            <Modal.Title
              className="fw-bold text-primary"
              style={{ fontSize: "1rem" }}
            >
              {props.actionType === ACTION_TYPE.ADD_ACTION ? "Add" : "Update"}{" "}
              {props.reportName}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {getResultByTermsResponse.isLoading ||
            updateUserResponse.isLoading ||
            saveUserResponse.isLoading ||
            getUserByIdResponse.isLoading ||
            getUserRoleByIdResponse.isLoading ? (
            <Loading />
          ) : (
            ""
          )}
          <Formik
            // validationSchema={formSchema}
            enableReinitialize={true}
            initialValues={
              props.reportNameCode === "user"
                ? {
                  firstName:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.firstName
                      : "",
                  lastName:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.lastName
                      : "",
                  phoneNumber:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.phoneNumber
                      : "",
                  email:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.email
                      : "",
                  address:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.address
                      : "",
                  countryId:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.countryId
                      : "",
                  stateId:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.stateId
                      : "",
                  cityId:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.city
                      : "",
                  pinCode:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.postalCode
                      : "",
                  committeeName:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.committeeName
                      : "",
                  organizationName:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.organizationName
                      : "",
                  domain:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.domain
                      : "",
                  briefProfile:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.briefProfile
                      : "",
                  linkedinRef:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.linkedinRef
                      : "",
                  skills:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.skills
                      : "",
                  userRoleId:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.userRole.id
                      : "",
                  userGroupId:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.userGroup.id
                      : "",
                  photo:
                    props.actionType === ACTION_TYPE.UPDATE_ACTION
                      ? getByIdData && getByIdData.photo
                      : "",
                }
                : props.reportNameCode === "dataRecord"
                  ? {
                    termSetId: "",
                    valueChainId: "",
                    valueChainCatId: "",
                    countryId: "",
                    stateId: "",
                    city: "",
                    district: "",
                    pinCode: "",
                  }
                  : {
                    name:
                      props.actionType === ACTION_TYPE.UPDATE_ACTION
                        ? getByIdData && getByIdData.name
                        : "",
                    select:
                      props.actionType === ACTION_TYPE.UPDATE_ACTION &&
                        props.reportNameCode === "valueChain"
                        ? getByIdData && getByIdData.categoryId
                        : props.reportNameCode === "term"
                          ? getByIdData && getByIdData.termsetid
                          : props.reportNameCode === "dataPipline"
                            ? getByIdData && getByIdData.pipelinecategoryid
                            : "",
                  }
            }
            onSubmit={handleSubmit}
          >
            {({ values, handleChange }) => (
              <Form>
                {props.reportNameCode === "user" ? (
                  <div className="col-md-12 d-flex flex-col">
                    <div className="col-md-6 p-2">
                      {" "}
                      <p>
                        <div className="d-flex">
                          <div> First Name</div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <input
                          className="form-control form-control-sm w-100"
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.firstName}
                          name="firstName"
                          required
                        />
                      </p>
                      <p>
                        <div className="d-flex">
                          <div>Email</div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <input
                          className="form-control form-control-sm w-100"
                          type="email"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.email}
                          name="email"
                          required
                          disabled={
                            props.actionType === ACTION_TYPE.UPDATE_ACTION
                              ? true
                              : false
                          }
                        />
                      </p>{" "}
                      <p>
                        Organization Name
                        <input
                          className="form-control form-control-sm w-100"
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.organizationName}
                          name="organizationName"
                        // required
                        />
                      </p>
                      <p>
                        Committee Name
                        <input
                          className="form-control form-control-sm w-100"
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.committeeName}
                          name="committeeName"
                        // required
                        />
                      </p>
                      <p>
                        <div className="d-flex">
                          <div> User Group</div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleSelectGroup}
                            name="userGroupId"
                            value={selectedGroup}
                            required
                          >
                            <option value="">Select User Group</option>
                            {group &&
                              group.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.id}
                                  >
                                    {itemType.id} - {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </p>
                      <p>
                        Address
                        <textarea
                          className="form-control form-control-sm w-100"
                          rows={4}
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.address}
                          name="address"
                        // required
                        />
                      </p>
                      <p>
                        <div className="d-flex">
                          <div> Country</div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleSelectCountry}
                            name="countryId"
                            value={selectedCountryId}
                            required
                          >
                            <option value="">Select Country</option>
                            {country &&
                              country.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.id}
                                  >
                                    {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </p>
                      <p>
                        <div className="d-flex">
                          <div> State</div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleSelectState}
                            name="stateId"
                            value={selectedStateId}
                            required
                          >
                            <option value="">Select State</option>
                            {state &&
                              state.map((itemType: any, index: any) => {
                                // setSelectedStateId(itemType.id);
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.id}
                                  >
                                    {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </p>
                      <p>
                        Domain
                        <input
                          className="form-control form-control-sm w-100"
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.domain}
                          name="domain"
                        // required
                        />
                      </p>
                    </div>
                    <div className="col-md-6 p-2">
                      <p>
                        <div className="d-flex">
                          <div> Last Name</div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <input
                          className="form-control form-control-sm w-100"
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.lastName}
                          name="lastName"
                          required
                        />
                      </p>
                      <p>
                        Mobile Number
                        <input
                          className="form-control form-control-sm w-100"
                          type="number"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.phoneNumber}
                          name="phoneNumber"
                        // required
                        />
                      </p>
                      <p>
                        Linkedin Ref
                        <input
                          className="form-control form-control-sm w-100"
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.linkedinRef}
                          name="linkedinRef"
                        // required
                        />
                      </p>
                      <p>
                        Skills
                        <input
                          className="form-control form-control-sm w-100"
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.skills}
                          name="skills"
                        // required
                        />
                      </p>
                      <p>
                        <div className="d-flex">
                          <div>Subscriber</div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleSelectRole}
                            name="userRoleId"
                            value={selectedRole}
                            required
                          >
                            <option value="">Select Subscriber</option>
                            {role &&
                              role.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.id}
                                  >
                                    {itemType.id} - {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </p>
                      <p>
                        Brief Profile
                        <textarea
                          className="form-control form-control-sm w-100"
                          rows={4}
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.briefProfile}
                          name="briefProfile"
                        // required
                        />
                      </p>
                      <p>
                        City
                        <input
                          className="form-control form-control-sm w-100"
                          type="text"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.cityId}
                          name="cityId"
                        // required
                        />
                      </p>
                      <p>
                        Pincode
                        <input
                          className="form-control form-control-sm w-100"
                          type="number"
                          aria-label=".form-control-sm example"
                          onChange={handleChange}
                          value={values.pinCode}
                          name="pinCode"
                        // required
                        />
                      </p>
                      {/* <p>
                        Upload Photo
                        <input
                          aria-label=".form-control-sm example"
                          className="form-control form-control-sm w-100"
                          type="file"
                          id="avatar"
                          name="photo"
                          accept="image/png, image/jpeg"
                          onChange={handleChange}
                          value={values.photo}
                          // required
                        />
                      </p> */}
                    </div>
                  </div>
                ) : props.reportNameCode === "dataRecord" ? (
                  // <div className="col-md-12 d-flex flex-col">
                  <div className=" p-2">
                    {" "}
                    <p>
                      <div className="d-flex">
                        <div>Value Chain Category</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <div className="w-100">
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleSelectValueChainCat}
                            name="valueChainCatId"
                            value={selectedValueChainCat}
                            required
                          >
                            <option value="">
                              Select Value Chain Category
                            </option>
                            {valueChainCat &&
                              valueChainCat.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.id}
                                  >
                                    {itemType.id} - {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </p>
                    <p>
                      <div className="d-flex">
                        <div> Value Chain</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <div className="w-100">
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleSelectValueChain}
                            name="valueChainId"
                            value={selectedValueChain}
                            required
                          >
                            <option value="">Select Value Chain</option>
                            {valueChain &&
                              valueChain.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.id}
                                  >
                                    {itemType.id} - {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </p>
                    <p>
                      <div className="d-flex">
                        <div>Term Set</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <div className="w-100">
                        <div className="pe-2">
                          <select
                            className="form-select form-select-sm w-100"
                            aria-label=".form-select-sm example"
                            onChange={handleSelectTermSet}
                            name="termSetId"
                            value={selectedTermSet}
                            required
                          >
                            <option value="">Select Term Set</option>
                            {termSet &&
                              termSet.map((itemType: any, index: any) => {
                                return (
                                  <option
                                    key={index.toString()}
                                    value={itemType.id}
                                  >
                                    {itemType.id} - {itemType.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </p>
                    <p>
                      Country
                      <div className="pe-2">
                        <select
                          className="form-select form-select-sm w-100"
                          aria-label=".form-select-sm example"
                          onChange={handleSelectCountry}
                          name="countryId"
                          value={selectedCountryId}
                        >
                          <option value="">Select Country</option>
                          {country &&
                            country.map((itemType: any, index: any) => {
                              return (
                                <option
                                  key={index.toString()}
                                  value={itemType.id}
                                >
                                  {itemType.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </p>
                    <p>
                      State{" "}
                      <div className="pe-2">
                        <select
                          className="form-select form-select-sm w-100"
                          aria-label=".form-select-sm example"
                          onChange={handleSelectState}
                          name="stateId"
                          value={selectedStateId}
                        >
                          <option value="">Select State</option>
                          {state &&
                            state.map((itemType: any, index: any) => {
                              // setSelectedStateId(itemType.id);
                              return (
                                <option
                                  key={index.toString()}
                                  value={itemType.id}
                                >
                                  {itemType.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </p>
                    {/* <p>
                      District
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.district}
                        name="district"
                        required
                      />
                    </p> */}
                    <p>
                      City
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.city}
                        name="city"
                      // required
                      />
                    </p>
                    <p>
                      Remark
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.pinCode}
                        name="pinCode"
                      // required
                      />
                    </p>
                  </div>
                ) : (
                  // </div>
                  <>
                    {!props.isCategory && (
                      <p>
                        <div className="w-100">
                          <div className="d-flex">
                            <div>
                              {" "}
                              Select{" "}
                              {props.reportNameCode === "valueChain" ||
                                props.reportNameCode === "dataPipeline"
                                ? "Category"
                                : "Term Set"}
                            </div>
                            <div style={{ color: "red" }}>*</div>
                          </div>
                          <div className="pe-2">
                            <select
                              className="form-select form-select-sm w-100"
                              aria-label=".form-select-sm example"
                              onChange={handleSelectChange}
                              name="select"
                              value={selectedValue}
                              required
                            >
                              <option value="">
                                Select{" "}
                                {props.reportNameCode === "valueChain" ||
                                  props.reportNameCode === "dataPipeline"
                                  ? "Category"
                                  : "Term Set"}
                              </option>
                              {selectData &&
                                selectData.map((itemType: any, index: any) => {
                                  return (
                                    <option
                                      key={index.toString()}
                                      value={itemType.id}
                                    >
                                      {itemType.id} - {itemType.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      </p>
                    )}
                    <p>
                      <div className="d-flex">
                        <div> {props.reportName} Name</div>
                        <div style={{ color: "red" }}>*</div>
                      </div>
                      <input
                        className="form-control form-control-sm w-100"
                        type="text"
                        aria-label=".form-control-sm example"
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                        required
                      />
                    </p>
                    {props.reportNameCode === "userRole" ? (
                      <p>
                        <div className="d-flex">
                          <div>Assign Permissions</div>
                          <div style={{ color: "red" }}>*</div>
                        </div>
                        <Select
                          mode="multiple"
                          placeholder="Select permissions"
                          value={selectedPermissions}
                          onChange={handleChangeValue}
                          style={{ width: '100%' }}
                          showSearch={false}
                        // options={filteredOptions.map((item: any) => ({
                        // value: item,
                        // label: item,
                        // }))}
                        >
                          {/* Render options with checkboxes */}
                          {allPermission && allPermission.map((permission: any) => {
                            return (
                              <Option key={permission.id} value={permission.id}>
                                <Checkbox checked={selectedPermissions.includes(permission.id)} />
                                {" "}{permission.desc}
                              </Option>
                            )
                          })}
                        </Select>
                      </p>
                    ) : null}

                  </>
                )}

                <div className="d-flex flex-row justify-content-center">
                  {props.reportNameCode === "dataRecord" ? (
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                    >
                      Search
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm btn-block mt-3 mx-2 btn-width"
                    >
                      {props.actionType === ACTION_TYPE.ADD_ACTION
                        ? "Save"
                        : "Update"}
                    </button>
                  )}

                  <button
                    type="button"
                    className="btn btn-secondary btn-sm btn-block mt-3 mx-2 btn-width"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default ScheduleReport;
